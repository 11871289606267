import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import EditorModal from "../../components/Common/EditorModal"
import SelectMultipleJurisdictions from '../../components/Filter/SelectMultipleJurisdictions';
import SelectMultipleCountry from '../../components/Filter/SelectMultipleCountry';
import SelectCountry from '../../components/Filter/SelectCountry';
import SearchName from '../../components/Filter/SearchName';
import PeriodBtn from '../../components/Filter/PeriodBtn';
import ContactsDetails from '../Contacts/ViewDetail';
// import UserSignatureSavePopup from '../../components/Common/UserSignatureSavePopup';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getMadridSettings as onGetMadridSettings,
  saveMadridSettings as onSaveMadridSettings,
  setMadridSuccess as onSetSuccess,
  setMadridResult as onSetResult,
  getMadridNotifier as onGetMadridNotifier,
  getMadridNotifierInfo as onGetMadridNotifierInfo,
  changeMadridNotifier as onChangeMadridNotifier,
  multipleHideMadrid as onMultipleHideMadrid,
  multipleUnhideMadrid as onMultipleUnhideMadrid,
  multipleStarMadrid as onMultipleStarMadrid,
  multipleUnstarMadrid as onMultipleUnstarMadrid,
  downMadridExcel as onDownMadridExcel,
  downMultipleMadridExcel as onDownMultipleMadridExcel,
  downMadridPdf as onDownMadridPdf,
  downMultipleMadridPdf as onDownMultipleMadridPdf,
  sendMadridEmail as onSendMadridEmail,
  previewMadridEmail as onPreviewMadridEmail,
  setPreviewEmail as onSetPreviewEmail,
  clearMadridNotifier as onClearMadridNotifier,
  unlockMadrid as onUnlockMadrid,
  setMadridLoading as onSetMadridLoading,
  starTarget as onStarTarget,
  unStarTarget as onUnStarTarget,
  hideTarget as onHideTarget,
  unHideTarget as onUnHideTarget,
  setMadridSettings as onSetMadridSettings,
  setUpgradeAlertMessage as onSetUpgradeAlertMessage,
  setMadridSettingsDru as onSetMadridSettingsDru,
  setIsDruSearch as onSetIsDruSearch,
  getSearchTargetGroupList as onGetSearchTargetGroupList,
  setMadridSentSuccess as onSetSentSuccess,
  changeSearchNameList as onChangeSearchNameList,
  changeSearchNameSelectedList as onChangeSearchNameSelectedList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import styled from "styled-components";

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const MadridNotifier = props => {
  // console.log('pdfjs.version : ' + pdfjs.version);
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);

  const { madridNotifier, searchSettings, loading, success, result, noticeStr, totalPage, previewEmail, previewPdf, unlockCredit, unlockCnt, isSearched, freeMemberAutosearch, userGrade, upgradeAlertMessage, isDruSearch, sentCnt, unsubsEmails, disableEmails, sentIn24Emails, sentSuccess } = useSelector(state => ({
    madridNotifier: state.MadridNotifier.madridNotifier,
    searchSettings: state.MadridNotifier.searchSettings,
    loading: state.MadridNotifier.loading,
    success: state.MadridNotifier.success,
    result: state.MadridNotifier.result,
    noticeStr: state.MadridNotifier.noticeStr,
    totalPage: state.MadridNotifier.totalPage,
    previewEmail: state.MadridNotifier.previewEmail,
    previewPdf: state.MadridNotifier.previewPdf,
    unlockCredit: state.Unlock.unlockCredit,
    unlockCnt: state.Unlock.unlockCnt,
    isSearched: state.MadridNotifier.isSearched,
    freeMemberAutosearch: state.MadridNotifier.freeMemberAutosearch,
    userGrade: state.MadridNotifier.userGrade,
    upgradeAlertMessage: state.MadridNotifier.upgradeAlertMessage,
    isDruSearch: state.MadridNotifier.isDruSearch,
    sentCnt: state.MadridNotifier.sentCnt,
    unsubsEmails: state.MadridNotifier.unsubsEmails,
    disableEmails: state.MadridNotifier.disableEmails,
    sentIn24Emails: state.MadridNotifier.sentIn24Emails,
    sentSuccess: state.MadridNotifier.sentSuccess,
  }));

  const [isOpen, setIsOpen] = useState(true);
  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [starOrUnstar, setStarOrUnstar] = useState("Star");
  const [hideOrUnhide, setHideOrUnhide] = useState("Hide");
  const [columns, setColumns] = useState([
    {label: "Email", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Representative", asc:"RIA", desc:"RID", ascActive:"", descActive:""},
    {label: "Holder", asc:"HIA", desc:"HID", ascActive:"", descActive:""},
    {label: "IR no", asc:"IRA", desc:"IRD", ascActive:"", descActive:""},
    {label: "Jur", asc:"JRA", desc:"JRD", ascActive:"", descActive:""},
    {label: "Inscription\nUploaded", asc:"RDA", desc:"RDD", ascActive:"", descActive:"text-danger"},
    {label: "Trademark", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "WeCrest send count", asc:null, desc:null, ascActive:null, descActive:null},
  ]);
  const [submitType, setSubmitType] = useState("");
  const [sendTarget, setSendTarget] = useState("");
  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState("");
  const [curPageInput, setCurPageInput] = useState(1);
  const [alertMsg, setAlertMsg] = useState("");
  const [numPages, setNumPages] = useState(null);
  // const [pdfNumPages, setPdfNumPages] = useState(null);


  function onDocumentLoadSuccess({ numPages }) {
    // console.log('onDocumentLoadSuccess : ' + numPages)
    setNumPages(numPages);
  }
    
  /**
   * errMsg[0] : smtp server 정보 없음.
   * errMsg[1] : 선택된 case 없음.
   * errMsg[2] : 이메일 제목 혹은 내용이 비어있음.
   */
  const errMsg = [
      <span>You are one small step away from being able to use the Madrid Notifier. Please complete the setup process by entering your email settings <a href='/settings#subscriptionSettings'>here</a>. Once you have completed this step, you will be able to send emails using the Madrid Notifier.</span>
    , "Please select a case."
    , <span>Your email is blank. Please edit your email or select a template in your <a href='/settings#emailTemplateSetting'>Settings.</a></span>
    , "No remaining credits"
    , "No slots remaining"
    , "Unlocking is not possible for this case. The sending limit of 10 emails has been reached already."
    , "Please select cases to unlock"
    , "Unlock Fail!"
    , "Select up to 50 cases to download a report in PDF format"
    , "Email sending is not possible for this case. The sending limit of 10 emails has been reached already."
    , "There is no contacts information for the selected case."
  ];

  let emailSentBgColor = "#ffa50069";
  let hiddenBgColor = "#ff000040";

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const setMadridLoading = () => {
    dispatch(onSetMadridLoading(!loading));
  }

  const [modalContactsScreen, setModalContactsScreen] = useState(false);
  const [contactsTargetGroupNo, setContactsTargetGroupNo] = useState(-1);
  function togContactsScreen(targetGroupNo) {
    if (targetGroupNo) {
      setContactsTargetGroupNo(targetGroupNo);
    } else {
      setContactsTargetGroupNo(-1);

      const _madridNotifier = [];
      madridNotifier.map((row, index) =>{
        if(row.representativeGroupNo == contactsTargetGroupNo || row.holderGroupNo == contactsTargetGroupNo){
          _madridNotifier.push(row);
        }
      });
    
      dispatch(onGetMadridNotifierInfo(_madridNotifier, madridValidation.values.inOutType));
    }
    setModalContactsScreen(!modalContactsScreen);
  }

  const madridValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      inOutType: searchSettings && searchSettings.inOutType? searchSettings.inOutType : "IN",
      jurType: searchSettings && searchSettings.jurType? searchSettings.jurType : "",
      jrCdList: searchSettings && searchSettings.jurList? searchSettings.jurList : [],
      holderType: searchSettings && searchSettings.holderType? searchSettings.holderType : "",
      holderCdList: searchSettings && searchSettings.holderCdList? searchSettings.holderCdList : [],
      repType: searchSettings && searchSettings.repType? searchSettings.repType : "",
      repCdList: searchSettings && searchSettings.repCdList? searchSettings.repCdList : [],
      searchNoList: searchSettings && searchSettings.groupNoList? searchSettings.groupNoList : [],
      searchKeyword: searchSettings && searchSettings.searchKeyword? searchSettings.searchKeyword : "",
      searchNameList: [],
      irNumber: searchSettings && searchSettings.irNumber? searchSettings.irNumber : "",
      datePeriod: searchSettings && searchSettings.datePeriod? searchSettings.datePeriod : "",
      fromDate: searchSettings && searchSettings.startDate? searchSettings.startDate : undefined,
      toDate: searchSettings && searchSettings.endDate? searchSettings.endDate : undefined,
      //showHiddenCase: searchSettings && searchSettings.showHiddenCase? searchSettings.showHiddenCase : "ONH",
      entityStatus: searchSettings ? searchSettings.entityStatus : "onlyNonHidden",
      hasRep: searchSettings && searchSettings.hasRep? searchSettings.hasRep : "BOTH",
      emailSent: searchSettings && searchSettings.emailSent? searchSettings.emailSent : "N",
      dateType: searchSettings && searchSettings.dateType? searchSettings.dateType : "Inscribed",
      screenGrade: "Gold",
      sortType: "RDD",
      perPage: 50,
      curPage: 1,
      screenName: "MadridNotifier",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date()
        .max(Yup.ref('toDate'), "From date must be earlier than to date")
        .required("Please Select From date"),
      toDate: Yup.date()
        // .max(new Date(), "Date cannot be in the future")
        .required("Please Select to date"),
    }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      
      let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      let _toDateStr = values["toDate"].replace(/-/gi,"/");
      let newEndDate = new Date(values["toDate"]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
      let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      period = Math.ceil(period / (1000 * 3600 * 24));
      
      let _periodstr = period +" day(s)";

      // TODO unavailablePeriod 체크 추가 필요 
      let _noticeStr = "Madrid Notifier for period: "+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      let _timePeriod = _fromDateStr + " to " +_toDateStr + " [" + _periodstr + "]";

      if (values["jurType"] === 'Individual' && (values["jrCdList"] === '' || values["jrCdList"].length === 0)) {
        if (values["inOutType"] === 'IN') {
          values["jurType"] = 'All-subs';
        } else {
          values["jurType"] = 'All';
        }
        
        // console.log('values.jurType Individual to >> ' + values["jurType"]);
      }

      if (values["holderType"] === 'Individual' && (values["holderCdList"] === '' || values["holderCdList"].length === 0)) {
        if (values["inOutType"] === 'OUT') {
          values["holderType"] = 'All-subs';
        } else {
          values["holderType"] = 'All';
        }
        
        // console.log('values.holderType Individual to >> ' + values["holderType"]);
      }

      if (values["repType"] === 'Individual' && (values["repCdList"] === '' || values["repCdList"].length === 0)) {
        values["repType"] = 'All';
        
        // console.log('values.repType Individual to >> ' + values["repType"]);
      }

      const filterParam = {
        inOutType: values["inOutType"],
        jurType: values["jurType"],
        jurList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
        holderType: values["holderType"],
        holderCdList: values["holderType"] === "All" || values["holderType"] === "All-subs"? [] : values["holderCdList"],
        repType: values["repType"],
        repCdList: values["repType"] === "All" || values["repType"] === "All-subs"? [] : values["repCdList"],
        groupNoList: values["searchNoList"],
        searchNameList: values["searchNameList"],
        irNumber: values["irNumber"].trim(),
        startDate: values["fromDate"],
        endDate: values["toDate"],
        //showHiddenCase: values["showHiddenCase"],
        entityStatus: values["entityStatus"],
        hasRep: values["hasRep"],
        emailSent: values["emailSent"],
        dateType: values["dateType"],
        sortType: values["sortType"],
        perPage: values["perPage"],
        curPage: values["curPage"],
        noticeStr: _noticeStr,
        timePeriod: _timePeriod,
      };

      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Madrid Notifier (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 
      
      let submitType = values["submitType"];

      if(submitType === "view"){
        setChkAllFlag(false);
        // console.log('view madridnotifier>>>')
        dispatch(onGetMadridNotifier(filterParam));
        
      }else if(submitType === "excel"){
        dispatch(onDownMadridExcel(filterParam, fileName));

      }else if(submitType === "pdf"){

        // console.log('filterParam >> ' + JSON.stringify(filterParam, null, 2));

        dispatch(onDownMadridPdf(filterParam, fileName));
      }else if(submitType === "saveSettings"){
        const settingsParam = {
          inOutType: values["inOutType"],
          jurType: values["jurType"],
          jurListToStr: values["jurType"] === "Individual"? values["jrCdList"].join(", ") : "",
          holderType: values["holderType"],
          holderCdListToStr: values["holderType"] === "Individual" ? values["holderCdList"].join(", ") : "",
          repType: values["repType"],
          repCdListToStr: values["repType"] === "Individual" ? values["repCdList"].join(", ") : "",
          // groupNoType: values["groupNoType"],
          // groupNoListToStr: values["groupNoType"] === "Individual" ? values["searchNoList"].join(", ") : "",
          groupNoListToStr : (values["searchNoList"] === '' ? "" : values["searchNoList"].join(", ")),
          irNumber: values["irNumber"],
          datePeriod: values["datePeriod"],
          //showHiddenCase: values["showHiddenCase"],
          entityStatus: values["entityStatus"],
          hasRep: values["hasRep"],
          emailSent: values["emailSent"],
          dateType: values["dateType"],
          searchKeyword: values["searchKeyword"],
        };

        dispatch(onSaveMadridSettings(settingsParam));

      }else if(submitType === "Hide"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if(row.checked === true && row.irHideYn === 'N'){
            _madridNotifier.push(row);
          }
        }); 
    
        setChkAllFlag(false);
        dispatch(onMultipleHideMadrid(_madridNotifier, filterParam));

      }else if(submitType === "Unhide"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if(row.checked === true && row.irHideYn === 'Y'){
            _madridNotifier.push(row);
          }
        }); 

        setChkAllFlag(false);
        dispatch(onMultipleUnhideMadrid(_madridNotifier, filterParam));

      }else if(submitType === "Star"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) => {
          if(row.checked === true && row.irStarYn === 'N'){
            _madridNotifier.push(row);
          }
        }); 
    
        setChkAllFlag(false);
        dispatch(onMultipleStarMadrid(_madridNotifier, filterParam));

      }else if(submitType === "Unstar"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) => {
          if(row.checked === true && row.irStarYn === 'Y'){
            _madridNotifier.push(row);
          }
        }); 

        setChkAllFlag(false);
        dispatch(onMultipleUnstarMadrid(_madridNotifier, filterParam));
      }else if(submitType === "multipleExcel"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if(row.checked === true){
            _madridNotifier.push(row);
          }
        });
        
        dispatch(onDownMultipleMadridExcel(_madridNotifier, noticeStr, fileName));
      }else if(submitType === "multiplePdf"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if(row.checked === true){
            _madridNotifier.push(row);
          }
        });

        if (_madridNotifier.length > 50) {
          // Select up to 50 cases to download a report in PDF format
          dispatch(onSetResult(8));
        } else {
          const param = {
            madridNotifierList: _madridNotifier,
            inOutType: values["inOutType"],
            jurType: values["jurType"],
            jurList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
            holderType: values["holderType"],
            holderCdList: values["holderType"] === "All" || values["holderType"] === "All-subs"? [] : values["holderCdList"],
            repType: values["repType"],
            repCdList: values["repType"] === "All" || values["repType"] === "All-subs"? [] : values["repCdList"],
            searchNameList: values["searchNameList"],
            irNumber: values["irNumber"],
            dateType: values["dateType"],
            timePeriod: _timePeriod,
          }
  
          // console.log('param >> ' + JSON.stringify(param, null, 2));
          
          dispatch(onDownMultipleMadridPdf(param, fileName));
        }

      }else if(submitType === "multipleSend"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if(row.checked === true){
            _madridNotifier.push(row);
          }
        });

        let period = getPeriod();
        dispatch(onSendMadridEmail(_madridNotifier, period, values["inOutType"], sendTarget, filterParam));

      }else if(submitType.indexOf("send_") === 0){
        let idx = submitType.replace("send_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);

        let period = getPeriod();
        dispatch(onSendMadridEmail(_madridNotifier, period, values["inOutType"], sendTarget, filterParam));
        // dispatch(changeSentResult({'sentCnt':10, 'unsubsEmails':['bjjeong@wecrest.com', 'bjjeong2@wecrest.com'], 'disableEmails':['bjjeong82@gmail.com']}))
      }else if(submitType.indexOf("preview_") === 0){
        let idx = submitType.replace("preview_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);

        // console.log('idx : ' + idx);
        // console.log('preview _madridNotifier : ' + JSON.stringify(_madridNotifier));

        let period = getPeriod();
        dispatch(onPreviewMadridEmail(_madridNotifier, period, values["inOutType"]));
      }else if(submitType === "multipleUnlock"){
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if(row.checked && row.unlockYn === 'N'){
            _madridNotifier.push(row);
          }
        });

        if (_madridNotifier.length > 0) {
          let period = getPeriod();
          dispatch(onUnlockMadrid(_madridNotifier, period, values["inOutType"], filterParam));
        } else {
          dispatch(onSetResult(6));
        }
      } else if (submitType.indexOf("unlock_") === 0) {
        let idx = submitType.replace("unlock_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);

        let period = getPeriod();
        dispatch(onUnlockMadrid(_madridNotifier, period, values["inOutType"], filterParam));
      } else if (submitType.indexOf("star_target_") === 0) {
        let targetGroupNo = submitType.replace("star_target_", "");

        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if((row.representativeGroupNo == targetGroupNo || row.holderGroupNo == targetGroupNo) && (row.premSubsYn === 'Y' || row.unlockYn === 'Y')){
            _madridNotifier.push(row);
          }
        });

        dispatch(onStarTarget(targetGroupNo, filterParam, _madridNotifier));
      } else if (submitType.indexOf("unStar_target_") === 0) {
        let targetGroupNo = submitType.replace("unStar_target_", "");

        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if((row.representativeGroupNo == targetGroupNo || row.holderGroupNo == targetGroupNo) && (row.premSubsYn === 'Y' || row.unlockYn === 'Y')){
            _madridNotifier.push(row);
          }
        });
        
        dispatch(onUnStarTarget(targetGroupNo, filterParam, _madridNotifier));
      } else if (submitType.indexOf("hide_target_") === 0) {
        let targetGroupNo = submitType.replace("hide_target_", "");
        
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if((row.representativeGroupNo == targetGroupNo || row.holderGroupNo == targetGroupNo) && (row.premSubsYn === 'Y' || row.unlockYn === 'Y')){
            _madridNotifier.push(row);
          }
        });
        
        dispatch(onHideTarget(targetGroupNo, filterParam, _madridNotifier));
      } else if (submitType.indexOf("unHide_target_") === 0) {
        let targetGroupNo = submitType.replace("unHide_target_", "");
        
        const _madridNotifier = [];
        madridNotifier.map((row, index) =>{
          if((row.representativeGroupNo == targetGroupNo || row.holderGroupNo == targetGroupNo) && (row.premSubsYn === 'Y' || row.unlockYn === 'Y')){
            _madridNotifier.push(row);
          }
        });

        dispatch(onUnHideTarget(targetGroupNo, filterParam, _madridNotifier));
      } else if (submitType.indexOf("star_case_") === 0) {
        let idx = submitType.replace("star_case_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);
    
        dispatch(onMultipleStarMadrid(_madridNotifier, filterParam));
      } else if (submitType.indexOf("unStar_case_") === 0) {
        let idx = submitType.replace("unStar_case_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);
        
        dispatch(onMultipleUnstarMadrid(_madridNotifier, filterParam));
      } else if (submitType.indexOf("hide_case_") === 0) {
        let idx = submitType.replace("hide_case_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);
        
        dispatch(onMultipleHideMadrid(_madridNotifier, filterParam));
      } else if (submitType.indexOf("unHide_case_") === 0) {
        let idx = submitType.replace("unHide_case_", "");
        const _madridNotifier = [];
        _madridNotifier.push(madridNotifier[idx]);
        
        dispatch(onMultipleUnhideMadrid(_madridNotifier, filterParam));
      // }else if(submitType === "reload_checked"){
      //   const _madridNotifier = [];
      //   madridNotifier.map((row, index) => {
      //     if(row.checked === true && row.irStarYn === 'Y'){
      //       _madridNotifier.push(row);
      //     }
      //   }); 

      //   dispatch(onGetMadridNotifierInfo(_madridNotifier, values["inOutType"]));

      // }else if(submitType.indexOf("reload_case_") === 0){
      //   let idx = submitType.replace("reload_case_", "");
      //   const _madridNotifier = [];
      //   _madridNotifier.push(madridNotifier[idx]);

      //   dispatch(onGetMadridNotifierInfo(_madridNotifier, values["inOutType"]));
      }
    },
  });

  useEffect(() => { 
    window.addEventListener('click', handleCloseMadridModal);
    if (localStorage.getItem("madridFilterParam") && localStorage.getItem("searchNameList")) {

      // console.log('2.' + localStorage.getItem("madridFilterParam"));
      // console.log('1.' + JSON.parse(localStorage.getItem("searchNameList")));
      // console.log('2.' + JSON.parse(localStorage.getItem("searchNameList"))[0].no);

      dispatch(onGetSearchTargetGroupList({targetGroupNo: JSON.parse(localStorage.getItem("searchNameList"))[0].no}));
      dispatch(onSetMadridSettings(JSON.parse(localStorage.getItem("madridFilterParam"))));
      // dispatch(onSetSearchNameList(JSON.parse(localStorage.getItem("searchNameList"))));

      localStorage.removeItem("madridFilterParam");
      localStorage.removeItem("searchNameList");
    } else if (params.get('druStartDate') || params.get('druEndDate') || params.get('druIsStared') || params.get('druIrnumber') || params.get('druTargetGroupNo') || params.get('druCrCode')) {
      if (params.get('druTargetGroupNo')) {
        dispatch(onGetSearchTargetGroupList({targetGroupNo: params.get('druTargetGroupNo')}));
      }
      
      let madridFilter = {
        inOutType: 'IN',
        jurType: params.get('druCrCode') ? "" : 'All',
        jurList: params.get('druCrCode') ? [params.get('druCrCode')] : [],
        groupNoList: params.get('druTargetGroupNo') ? [params.get('druTargetGroupNo')] : [],
        irNumber: params.get('druIrnumber') ? params.get('druIrnumber') : "",
        datePeriod: "",
        startDate: params.get('druStartDate') ? params.get('druStartDate') : "",
        endDate: params.get('druEndDate') ? params.get('druEndDate') : "",
        entityStatus: params.get('druIsStared') ? "onlyStarred" : "",
        emailSent: "BOTH",
        dateType: "Uploaded",
      };

      // console.log('DRU Param : ' + JSON.stringify(madridFilter, null, 2));

      dispatch(onSetMadridSettingsDru(madridFilter));
    } else if (params.get('warFromDate') || params.get('warToDate') || params.get('warCrCode')) {
      
      let madridFilter = {
        inOutType: 'IN',
        jurType: params.get('warCrCode') ? "" : 'All',
        jurList: params.get('warCrCode') ? [params.get('warCrCode')] : [],
        groupNoList: [],
        irNumber: "",
        datePeriod: "",
        startDate: params.get('warFromDate') ? params.get('warFromDate') : "",
        endDate: params.get('warToDate') ? params.get('warToDate') : "",
        entityStatus: "",
        emailSent: "N",
        dateType: "Inscribed",
      };

      // console.log('DRU Param : ' + JSON.stringify(madridFilter, null, 2));

      dispatch(onSetMadridSettingsDru(madridFilter));
    } else if (localStorage.getItem("ocmnMadridFilterParam")) {
      dispatch(onSetMadridSettingsDru(JSON.parse(localStorage.getItem("ocmnMadridFilterParam"))));
      localStorage.removeItem("ocmnMadridFilterParam");
    } else {
      dispatch(onGetMadridSettings());
    }

    return () => {
      window.removeEventListener('click', handleCloseMadridModal);
      dispatch(onChangeSearchNameList([], madridValidation.values.screenName));
      dispatch(onChangeSearchNameSelectedList([], madridValidation.values.screenName));
    };
  }, []);

  useEffect(() => {
    if (isDruSearch && madridValidation.values.fromDate != '') {
      // console.log('DRU Search >> ');
      dispatch(onSetIsDruSearch(false));

      madridValidation.setFieldValue("submitType", "view");
      madridValidation.handleSubmit(madridValidation.values); 
    }
    
  },[madridValidation.values.fromDate])

  useEffect(() => {
    if (isSearched && madridValidation.values.searchNoList.length > 0) {
      madridValidation.setFieldValue("submitType", "view");
      madridValidation.handleSubmit(madridValidation.values); 
    }
    
  },[madridValidation.values.searchNoList])

  useEffect(() => {
    if (freeMemberAutosearch == 'Y') {
      setTimeout(function(){ 
        toggle();
        madridValidation.setFieldValue("submitType", "view");
        madridValidation.handleSubmit(madridValidation.values);
      }, 100);  
    }
    
  },[madridValidation.values.toDate])

  useEffect(() => {
    if (madridValidation.values.curPage > totalPage) {
      // console.log('curPage 재설정');
      madridValidation.setFieldValue("curPage", totalPage);
      setCurPageInput(totalPage);
    }
  },[totalPage])

  const viewMadridNotifier = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    madridValidation.setFieldValue("submitType", "view");
    if(sortType){
      madridValidation.setFieldValue("sortType", sortType);
    }
    if(curPage){
      madridValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      madridValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      madridValidation.setFieldValue("perPage", perPage);
    }
    
    madridValidation.handleSubmit(madridValidation.values);
  }

  const rowCheck = (str, checked) => {
    let chkCnt = 0;
    var hiddenCaseCnt = 0;
    var starCaseCnt = 0;

    const _madridNotifier = [];
    madridNotifier.map((row, index) =>{
        let _row = cloneDeep(row);

        if(str === "chkAll" || str === index){
            _row.checked = checked;
        }

        if(_row.checked === true){
          chkCnt++;
          if(row.irHideYn === 'Y'){
            hiddenCaseCnt++;
          }
          if(row.irStarYn === 'Y'){
            starCaseCnt++;
          }
        }

        _madridNotifier.push(_row);
    }); 

    if(chkCnt !== 0 && chkCnt === hiddenCaseCnt){
      setHideOrUnhide("Unhide");
    }else{
      setHideOrUnhide("Hide");
    }

    if(chkCnt !== 0 && chkCnt === starCaseCnt){
      setStarOrUnstar("Unstar");
    }else{
      setStarOrUnstar("Star");
    }

    if(madridNotifier.length === chkCnt){
        setChkAllFlag(true);
    }else{
        setChkAllFlag(false);
    }

    dispatch(onChangeMadridNotifier(_madridNotifier));
  }

  const caseChk = () => {
    const _madridNotifier = [];
    madridNotifier.map((row, index) =>{
      if(row.checked === true){
        _madridNotifier.push(row);
      }
    }); 

    if(_madridNotifier.length === 0){
      dispatch(onSetResult(1));
      return false;
    }

    return true;
  }

  const getPeriod = () => {
    let searchStr = "ranking for period: ";
    let searchIdx = noticeStr.toLowerCase().indexOf(searchStr) + searchStr.length
    let period = noticeStr.substr(searchIdx).replace("\n", "");

    return period;
  }

  const onImgLoad = ({ target: img }) => {
    const { offsetHeight, offsetWidth } = img;
    // console.log(offsetHeight, offsetWidth);
    if (offsetWidth > 100) {
      img.width = 100;
      // console.log("resize!!");
    }
  };

  // [S] Editor
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [irNumber, setIrNumber] = useState(-1);
  const [ftpDate, setFtpDate] = useState('');
  const [crCode, setCrCode] = useState('');
  const [editedTemplateYn, setEditedTemplateYn] = useState('N');
  
  function openEditor(irNumber, ftpDate, crCode, holderCountry, irEmlTmpSetYn) {
    if (!isInitEditor) {
      // console.log('editor init start')
      setIsInitEditor(true)
    // } else {
      // console.log('editor inited')
    }
    
    // console.log('irNumber : ' + irNumber + ', ftpDate : ' + ftpDate + ', crCode : ' + crCode + ', holderCountry : ' + holderCountry + ', inOutType : ' + madridValidation.values.inOutType);
    localStorage.setItem("madridReloadParam", JSON.stringify({irNumber:irNumber, ftpDate:ftpDate, crCode:crCode, inOutType:madridValidation.values.inOutType}))
    // dispatch(onChangeMadridReloadParam({'irNumber':irNumber, 'ftpDate':ftpDate, 'crCode':crCode, 'inOutType':madridValidation.values.inOutType}));

    setIrNumber(irNumber);
    setFtpDate(ftpDate);
    setCrCode(madridValidation.values.inOutType === 'IN' ? crCode : holderCountry);
    setEditedTemplateYn(irEmlTmpSetYn);
    setIsOpenEditor(true);
    addBodyCss();
  }

  function closeEditor() {
    const reloadParam = JSON.parse(localStorage.getItem("madridReloadParam"));
    // console.log('Madrid closeEditor >> reloadParam : ' + JSON.stringify(reloadParam));
    dispatch(onGetMadridNotifierInfo([{irNumber:reloadParam.irNumber, ftpDate:reloadParam.ftpDate, crCode:reloadParam.crCode}], reloadParam.inOutType));
    localStorage.removeItem("madridReloadParam");

    setIsOpenEditor(false);
    setIrNumber(-1);

    if (editorModaRef.current) {
      editorModaRef.current.clearEditorContent();
    }
    
    removeBodyCss();
    
    /*
    madridValidation.setFieldValue("submitType", "view");
    madridValidation.handleSubmit(madridValidation.values);
    */
  }

  function removeBodyCss() {
    document.body.classList.remove("no_padding");
    document.body.classList.remove("modal-open");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';

    // console.log('removeBodyCss>>');
  }

  function addBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = 'hidden';
  }

  const editorModaRef = useRef(null);
  const editorModal = useRef(null);
  const handleCloseMadridModal = (e) => {
    if (e.target.id === 'EditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }
  }
  // [E] Editor

  function showSuccessCopyToast() {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    let message = "Copy clipboard!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title)
  }

  const unsecuredCopyToClipboard = (text, elmentId) => {
    const textArea = document.createElement("textarea");
    textArea.setAttribute("type", "hidden");
    textArea.value = text;

    // const node = document.getElementById(elmentId).lastElementChild;
    document.getElementById(elmentId).appendChild(textArea);
    // document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.getElementById(elmentId).removeChild(textArea);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <React.Fragment>
      <Form onSubmit={madridValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <Link onClick={toggle} className="text-dark" to="#">
                <div className="p-4">
                  <Media className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <i className="uil uil-envelopes text-primary h2"></i>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="font-size-16 mb-1">Madrid Notifier</h5>
                      <p className="text-muted mb-0">Use this tool to notify representatives or holders of international (Madrid) trademark
                      applications that have been provisional refused in your jurisdictions.</p>
                    </div>
                    <i className="uil uil-filter font-size-24 text-muted"></i>
                    {/* 
                    <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                    */}
                  </Media>
                </div>
              </Link>
              <Collapse isOpen={isOpen}>
                <div className="p-4 border-top">
                    <div>
                      <Row className="mb-3">
                        <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inOutType"
                            id="showInbound"
                            onClick={()=>{
                              madridValidation.setFieldValue("inOutType", "IN");
                              if (searchSettings && searchSettings.inOutType && searchSettings.inOutType === "IN") {
                                madridValidation.setFieldValue("jrCdList", searchSettings && searchSettings.jurList? searchSettings.jurList : []);
                                madridValidation.setFieldValue("holderCdList", searchSettings && searchSettings.holderCdList? searchSettings.holderCdList : []);
                                madridValidation.setFieldValue("repCdList", searchSettings && searchSettings.repCdList? searchSettings.repCdList : []);
                              } else {
                                madridValidation.setFieldValue("jrCdList", []);
                                madridValidation.setFieldValue("holderCdList", []);
                                madridValidation.setFieldValue("repCdList", []);
                              }
                            }}
                            value="IN"
                            checked={madridValidation.values.inOutType === 'IN'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showInbound"
                          >
                            Inbound
                          </label>
                        </div>
                        <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inOutType"
                            id="showOutbound"
                            onClick={()=>{
                              madridValidation.setFieldValue("inOutType", "OUT");
                              if (searchSettings && searchSettings.inOutType && searchSettings.inOutType === "OUT") {
                                madridValidation.setFieldValue("jrCdList", searchSettings && searchSettings.jurList? searchSettings.jurList : []);
                                madridValidation.setFieldValue("holderCdList", searchSettings && searchSettings.holderCdList? searchSettings.holderCdList : []);
                                madridValidation.setFieldValue("repCdList", searchSettings && searchSettings.repCdList? searchSettings.repCdList : []);
                              } else {
                                madridValidation.setFieldValue("jrCdList", []);
                                madridValidation.setFieldValue("holderCdList", []);
                                madridValidation.setFieldValue("repCdList", []);
                              }
                            }}
                            value="OUT"
                            checked={madridValidation.values.inOutType === 'OUT'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showOutbound"
                          >
                            Outbound
                          </label>
                        </div>
                      </Row>
                      
                      <SelectMultipleJurisdictions
                        formik={madridValidation}
                        subscriptionType="JN"
                      />

                      <SelectMultipleCountry
                        formik={madridValidation}
                        componentName='Holder'
                        subscriptionType="JN"
                      />
                      
                      <SelectCountry
                        formik={madridValidation}
                        componentName='Representative'
                      />

                      <SearchName
                        formik={madridValidation}
                      />

                      <Row className="mb-3">
                        <label
                        htmlFor="irNumber"
                        className="col-md-3 col-form-label"
                        >
                        IR number
                        </label>
                        <div className="col-md-3">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter IR number"
                            id="irNumber"
                            name="irNumber"
                            onChange={madridValidation.handleChange}
                            value={madridValidation.values.irNumber}
                            onKeyPress={(e)=>{
                              if (e.key === 'Enter') {
                                madridValidation.setFieldValue("submitType", "view");
                                madridValidation.handleSubmit(madridValidation.values);
                              }
                            }}
                        />
                        </div>
                      </Row>
                      
                      {/*
                      <Row className="mb-3">
                        <label
                        htmlFor="addressContain"
                        className="col-md-3 col-form-label"
                        >
                        Address contains (e.g. name of city)
                        </label>
                        <div className="col-md-3">
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Enter exact text"
                            id="addressContain"
                            name="addressContain"
                            onChange={madridValidation.handleChange}
                            value={madridValidation.values.addressContain}
                            onKeyPress={(e)=>{
                              if (e.key === 'Enter') {
                                madridValidation.setFieldValue("submitType", "view");
                                madridValidation.handleSubmit(madridValidation.values);
                              }
                            }}
                        />
                        </div>
                      </Row>
                      */}
                      
                      <Row>
                        <label htmlFor="inscribedDate" className="col-md-3 col-form-label" style={{paddingTop : '0px'}}>
                          <select 
                            className="form-select" 
                            id="dateType"
                            name="dateType"
                            onChange={madridValidation.handleChange}
                            value={madridValidation.values.dateType}
                            style={{width : '50%'}}
                          >
                            <option value="Inscribed">Inscription date</option>
                            <option value="Uploaded">Uploaded date</option>
                          </select>
                        </label>
                        <div className="col-md-9">
                          <PeriodBtn
                            formik={madridValidation}
                          />
                        </div>
                      </Row>
                      
                      <Row className="mb-3">
                        <label htmlFor="entityStatus" className="col-md-3 col-form-label">
                        Labels
                        </label>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="onlyNonHidden"
                            name="entityStatus"
                            onChange={madridValidation.handleChange}
                            value="onlyNonHidden"
                            checked={madridValidation.values.entityStatus === "onlyNonHidden"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="onlyNonHidden"
                          >
                            Only non-hidden
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="onlyStarred"
                            name="entityStatus"
                            onChange={madridValidation.handleChange}
                            value="onlyStarred"
                            checked={madridValidation.values.entityStatus === "onlyStarred"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="onlyStarred"
                          >
                            Only starred
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="onlyHidden"
                            name="entityStatus"
                            onChange={madridValidation.handleChange}
                            value="onlyHidden"
                            checked={madridValidation.values.entityStatus === "onlyHidden"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="onlyHidden"
                          >
                            Only hidden
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input type="radio" className="form-check-input"
                            id="entityStatusAll"
                            name="entityStatus"
                            onChange={madridValidation.handleChange}
                            value=""
                            checked={madridValidation.values.entityStatus === ""}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="entityStatusAll"
                          >
                            All
                          </label>
                        </div>
                      </Row>
                      {/*
                      <Row className="mb-3">
                        <label
                          htmlFor="showHiddenCase"
                          className="col-md-3 col-form-label"
                          >
                          Show hidden entities?
                        </label>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="showHiddenCase"
                            id="OnlyNonhidden"
                            onChange={madridValidation.handleChange}
                            value="ONH"
                            checked={madridValidation.values.showHiddenCase === 'ONH'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="OnlyNonhidden"
                          >
                            Only Non-hidden
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="showHiddenCase"
                            id="Onlyhidden"
                            onChange={madridValidation.handleChange}
                            value="OH"
                            checked={madridValidation.values.showHiddenCase === 'OH'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Onlyhidden"
                          >
                            Only hidden
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="showHiddenCase"
                            id="showHiddenCaseBoth"
                            onChange={madridValidation.handleChange}
                            value="BOTH"
                            checked={madridValidation.values.showHiddenCase === 'BOTH'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showHiddenCaseBoth"
                          >
                            Both
                          </label>
                        </div>
                      </Row>
                      */}
                      <Row className="mb-3">
                        <label
                          htmlFor="caseHasRepresentative"
                          className="col-md-3 col-form-label"
                          >
                          Representative
                        </label>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasRep"
                            id="hasRepBoth"
                            onChange={madridValidation.handleChange}
                            value="BOTH"
                            checked={madridValidation.values.hasRep === 'BOTH'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="hasRepBoth"
                          >
                            All
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasRep"
                            id="hasRepY"
                            onChange={madridValidation.handleChange}
                            value="Y"
                            checked={madridValidation.values.hasRep === 'Y'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="hasRepY"
                          >
                            Only with representative
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasRep"
                            id="hasRepN"
                            onChange={madridValidation.handleChange}
                            value="N"
                            checked={madridValidation.values.hasRep === 'N'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="hasRepN"
                          >
                            Only without representative
                          </label>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="emailSent"
                          className="col-md-3 col-form-label"
                          >
                          Email sending
                        </label>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="emailSent"
                            id="emailSentN"
                            onChange={madridValidation.handleChange}
                            value="N"
                            checked={madridValidation.values.emailSent === 'N'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emailSentN"
                          >
                            Only not sent 
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="emailSent"
                            id="emailSentY"
                            onChange={madridValidation.handleChange}
                            value="Y"
                            checked={madridValidation.values.emailSent === 'Y'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emailSentY"
                          >
                            Only sent
                          </label>
                        </div>
                        <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="emailSent"
                            id="emailSentBoth"
                            onChange={madridValidation.handleChange}
                            value="BOTH"
                            checked={madridValidation.values.emailSent === 'BOTH'}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emailSentBoth"
                          >
                            All
                          </label>
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <span className="col-md-6 button-items">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={()=>{
                              madridValidation.setFieldValue("submitType", "saveSettings");
                              madridValidation.handleSubmit(madridValidation.values);
                            }}
                          >
                            Save settings
                          </Button>
                          {userGrade === "02" ?
                          <>
                            <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={()=>{
                              madridValidation.setFieldValue("submitType", "excel");
                              madridValidation.handleSubmit(madridValidation.values);
                            }}
                            id="btn_down_excel_1_1"
                          >
                            Download Excel
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_down_excel_1_1">
                          Download details of all cases based on the filter as an Excel file limit 1,000 rows
                          </UncontrolledTooltip>
                          {/*
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={()=>{
                              madridValidation.setFieldValue("submitType", "pdf");
                              madridValidation.handleSubmit(madridValidation.values);
                            }}
                          >
                            Download PDF
                          </Button>
                          */}
                          </>
                          :
                          <>
                            <Button
                            type="button"
                            color="disable"
                            outline
                            className="waves-effect waves-light"
                            id="btn_down_excel_1"
                          >
                            Download Excel
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_down_excel_1">
                          Contact us at global@wecrest.com to access all features.
                          </UncontrolledTooltip>
                          {/*
                          <Button
                            type="button"
                            color="disable"
                            outline
                            className="waves-effect waves-light"
                            id="btn_down_pdf_1"
                          >
                            Download PDF
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_down_pdf_1">
                          Contact us at global@wecrest.com to access all features.
                          </UncontrolledTooltip>
                          */}
                          </>
                          }
                          
                        </span>
                        <Col md={3} style={{paddingLeft : '1.3em'}}>
                          <button
                            type="button"
                            className="waves-effect waves-light btn btn-primary"
                            style={{width : '148.58px'}}
                            onClick={()=>{
                              setCurPageInput(1);
                              madridValidation.setFieldValue("curPage", 1);
                              madridValidation.setFieldValue("submitType", "view");
                              madridValidation.handleSubmit(madridValidation.values);
                            }}
                          >
                            View cases
                          </button>
                        </Col>
                      </Row>

                      
                    </div>
                </div>
              </Collapse>
            </Card>
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setMadridLoading}></LoadingModal>
              : null
            }

            {sentSuccess? (
              <SweetAlert
                title={success}
                showCloseButton={false}
                success
                onConfirm={() => {
                  dispatch(onSetSentSuccess(""));
                }}
              >
                <div style={{textAlign: 'left'}}>
                  <div style={{marginBottom:'1rem'}}>Emails have only been sent for entities with enabled contacts.</div>
                  <div><label className='text-success' style={{marginBottom:'1rem'}}>Sent: {numberWithCommas(sentCnt)} {sentCnt > 1 ? "emails" : "email"}</label></div>
                  {disableEmails.length > 0 || unsubsEmails.length > 0 || sentIn24Emails.length > 0 ?
                  <>
                  <div className='text-danger'>
                  <label>Not sent</label><br/>
                  <ul>
                  {disableEmails.length > 0 ? <li>{numberWithCommas(disableEmails.length)} disabled {disableEmails.length > 1 ? "emails" : "email"}</li> : null}
                  {unsubsEmails.length > 0 ? <li>{numberWithCommas(unsubsEmails.length)} unsubscribed {unsubsEmails.length > 1 ? "emails" : "email"}</li> : null}
                  {sentIn24Emails.length > 0 ? <li>{numberWithCommas(sentIn24Emails.length)} {sentIn24Emails.length > 1 ? "emails" : "email"} sent within 24 hours</li> : null}
                  </ul>
                  </div>
                  </>
                  : null}
                  {disableEmails.length?
                  <>
                  <div>
                  <label>Disabled</label><br/>
                  <ul>
                  {disableEmails.map((emailAddress, index) => {
                    return (
                      <li>{emailAddress}</li>
                    )
                  })}
                  </ul>
                  </div>
                  </>
                  : null}
                  {unsubsEmails.length?
                  <>
                  <div>
                  <label>Unsubscribed</label><br/>
                  <ul>
                  {unsubsEmails.map((emailAddress, index) => {
                    return (
                      <li>{emailAddress}</li>
                    )
                  })}
                  </ul>
                  </div>
                  </>
                  : null}
                </div>
              </SweetAlert>
            ) : null}

            {success? (
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(onSetSuccess(""));
                }}
              ></SweetAlert>
            ) : null}

            {result !== -1 ? (
            <SweetAlert
              title={errMsg[result]}
              onConfirm={() => {
                dispatch(onSetResult(-1));
              }}
            >{result === 3 ? "Credits will be topped up next month." : null}
            </SweetAlert>
            ) : null}

            {alertMsg !== '' ? (
            <SweetAlert
              onConfirm={() => {
                setAlertMsg('');
              }}
              onCancel={() => {
                setAlertMsg('');
              }}
            >{alertMsg}
            </SweetAlert>
            ) : null}

            {confirmAlert ? (
              <SweetAlert
                showCancel
                confirmBtnText={confirmBtnText}
                cancelBtnText={cancelBtnText}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  madridValidation.setFieldValue("submitType", submitType);
                  madridValidation.handleSubmit(madridValidation.values);
                  setConfirmAlert(false);
                }}
                onCancel={() => setConfirmAlert(false)}
              >
                {confirmMsg}
                {
                  submitType === 'multipleSend' ?
                    <div className="d-flex gap-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoSendTarget"
                          id="rdoSendTargetRep"
                          onClick={()=>{
                            setSendTarget("REP");
                          }}
                          value="REP"
                          checked={sendTarget === 'REP'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rdoSendTargetRep"
                        >
                          Representatives
                        </label>
                      </div>
                      <div className="form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoSendTarget"
                          id="rdoSendTargetHol"
                          onClick={()=>{
                            setSendTarget("HOL");
                          }}
                          value="HOL"
                          checked={sendTarget === 'HOL'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rdoSendTargetHol"
                        >
                          Holders
                        </label>
                      </div>
                    </div>
                  :null
                }
              </SweetAlert>
            ) : null}

            {upgradeAlertMessage.title != "" ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Upgrade"
                cancelBtnText="No thanks"
                confirmBtnBsStyle="wcdark"
                cancelBtnBsStyle="secondary"
                onConfirm={() => {
                  window.location.href = "/membershipPlan";
                }}
                onCancel={() => {
                  dispatch(onSetUpgradeAlertMessage({title:"",text:""}))
                  // madridValidation.setFieldValue("submitType", "view");
                  // madridValidation.handleSubmit(madridValidation.values);
                }}
                title={upgradeAlertMessage.title}
              >
                {upgradeAlertMessage.text}
              </SweetAlert>
            ) : null}

            <Modal
              size="xl"
              style={{maxWidth : "1700px"}}
              isOpen={modalContactsScreen}
              toggle={() => {
                togContactsScreen()
              }}
            >
              <div className="modal-body">
                <button
                  onClick={() => {
                    togContactsScreen()
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <ContactsDetails targetGroupNo={contactsTargetGroupNo}/>
              </div>
            </Modal>

            {previewEmail && Object.keys(previewEmail).length !== 0? (
              <Modal
                size="xl"
                isOpen={true}
                toggle={() => {
                  dispatch(onSetPreviewEmail({}));
                }}
                onOpened={() => {
                  // console.log('open preview!');
                  dispatch(onSetMadridLoading(false));
                }}
              >
                <div className="modal-header">
                  <h5
                    className="modal-title mt-0"
                    id="myExtraLargeModalLabel"
                  >
                    To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.toEmail? previewEmail.toEmail : null}<br/>
                    CC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.emailCC? previewEmail.emailCC : null}<br/>
                    BCC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.emailBCC? previewEmail.emailBCC : null}<br/>
                    Subject: {previewEmail.subject? previewEmail.subject : null}
                  </h5>
                  <button
                    onClick={() => {
                      dispatch(onSetPreviewEmail({}));
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Card className="mb-2">
                    <CardBody style={{overflow:"scroll", height:"500px"}} dangerouslySetInnerHTML={{__html:previewEmail.emailForm? previewEmail.emailForm : null}}></CardBody>
                  </Card>
                  <Card className={previewPdf != "" ? "mb-2" : "mb-0"}>
                    <CardBody className="p-1">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>
                            {previewPdf != "" ?
                            <>
                            <a href={previewEmail.pdfStorageUrl} target="_blank" rel="noreferrer"><i className="uil uil-google-drive text-primary font-size-22"></i>Google drive</a>&nbsp;
                            <a href={previewPdf} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i>WeCrest drive</a>
                            </>
                            :
                            <a href={previewEmail.pdfStorageUrl} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i>{previewEmail.irNumber}</a>
                            }
                            </td>
                          </tr>
                          {
                            previewEmail.attachFiles && previewEmail.attachFiles.length?
                              previewEmail.attachFiles.map((attach, index)=>{
                                return (
                                  <tr><td>{attach.fileName}</td></tr>
                                )
                              })
                            :
                              null
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>

                  {previewPdf != "" ?
                  <Card className="mb-0">
                    <CardBody className="p-1 d-flex horizon-align-center" style={{overflow:"scroll", height:"500px"}}>
                      <PDFDocumentWrapper>
                        <Document file={previewPdf} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.apply(null, Array(numPages))
                          .map((x, i)=>i+1)
                          .map(page => <Page pageNumber={page}/>)}
                        </Document>
                      </PDFDocumentWrapper>
                    </CardBody>
                  </Card>
                  : null
                  }

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(onSetPreviewEmail({}));
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            ) : null}

            <EditorModal isOpenEditor={isOpenEditor} isInitEditor={isInitEditor} closeEditor={closeEditor} loadedEditor={loadedEditor} setLoadedEditor={setLoadedEditor} editorModal={editorModal} ref={editorModaRef}
              emailType='MN' irNumber={irNumber} ftpDate={ftpDate} crCode={crCode} editedTemplateYn={editedTemplateYn} inOutType={madridValidation.values.inOutType} />
            
            {noticeStr && noticeStr !== '' ?
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="text-muted mb-0">
                      <p style={{whiteSpace : "pre"}}>
                        {noticeStr}
                      </p>
                        <Row className="mb-3">
                        <span className="col-md-8 button-items">
                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={() => {
                              if(caseChk()){
                                setSubmitType("multipleUnlock");
                                setConfirmMsg(<>
                                Unlock to view the details and send emails for this case.<br/>
                                You cannot unlock a case, if sending limit of 10 emails per case has been reached already.
                                </>);
                                setConfirmBtnText("Confirm");
                                setCancelBtnText("Cancel");
                                setConfirmAlert(true);
                              }
                            }}
                            id="btn_unlock"
                          >
                            Unlock
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_unlock">
                          Unlock selected cases
                          </UncontrolledTooltip>

                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={() => {
                              if(caseChk()){
                                setSubmitType("multipleSend");
                                setConfirmMsg(<>
                                <div className="mb-3" style={{textAlign: "left"}}>
                                  Emails will only be sent for entities, for which
                                  <ul>
                                    <li>email address has been entered</li>
                                    <li>email subject and body are not empty</li>
                                    <li>sending limit of 10 emails per case has not been reached</li>
                                    <li>at least one saved email address has not been contacted within the past 24 hours</li>
                                  </ul>
                                </div>
                                <div className="mb-3" style={{textAlign: "left"}}>Send emails to the selected</div>
                                </>);
                                setConfirmBtnText("Yes, send!");
                                setCancelBtnText("Cancel");
                                setSendTarget("REP");
                                setConfirmAlert(true);
                              }
                            }}
                            id="btn_send"
                          >
                            Send
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_send">
                          Send emails for selected cases
                          </UncontrolledTooltip>

                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={() => {
                              if(caseChk()){
                                setSubmitType(starOrUnstar);
                                setConfirmMsg("Are you sure you want to "+starOrUnstar.toLowerCase()+" all checked cases?");
                                setConfirmBtnText("Yes, " + starOrUnstar.toLowerCase());
                                setCancelBtnText("Cancel");
                                setConfirmAlert(true);
                              }
                            }}
                            id="btn_starOrUnstar"
                          >
                            {starOrUnstar}
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_starOrUnstar">
                          {starOrUnstar} selected cases
                          </UncontrolledTooltip>

                          <Button
                            type="button"
                            color="primary"
                            outline
                            className="waves-effect waves-light"
                            onClick={() => {
                              if(caseChk()){
                                setSubmitType(hideOrUnhide);
                                setConfirmMsg("Are you sure you want to "+hideOrUnhide.toLowerCase()+" all checked cases?");
                                setConfirmBtnText("Yes, " + hideOrUnhide.toLowerCase());
                                setCancelBtnText("Cancel");
                                setConfirmAlert(true);
                              }
                            }}
                            id="btn_hideOrUnhide"
                          >
                            {hideOrUnhide}
                          </Button>
                          <UncontrolledTooltip placement="top" target="btn_hideOrUnhide">
                          {hideOrUnhide} selected cases
                          </UncontrolledTooltip>

                          {userGrade === "02" ?
                          <>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  madridValidation.setFieldValue("submitType", "multipleExcel");
                                  madridValidation.handleSubmit(madridValidation.values);
                                }
                              }}
                              id="btn_down_excel_2_1"
                            >
                              Download Excel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_2_1">
                            Download details of selected cases as an Excel file
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                if(caseChk()){
                                  madridValidation.setFieldValue("submitType", "multiplePdf");
                                  madridValidation.handleSubmit(madridValidation.values);
                                }
                              }}
                              id="btn_down_pdf_2_1"
                            >
                              Download PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_2_1">
                            Download details of selected cases as a PDF file
                            </UncontrolledTooltip>
                          </>
                          :
                          <>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_excel_2_2"
                            >
                              Download Excel
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_2_2">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="disable"
                              outline
                              className="waves-effect waves-light"
                              id="btn_down_pdf_2_2"
                            >
                              Download PDF
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_2_2">
                            Contact us at global@wecrest.com to access all features.
                            </UncontrolledTooltip>
                          </>
                          }
                          {/*
                          <button
                            type="button"
                            onClick={() => {
                              window.location.href = "/membershipPlan";
                            }}
                            className="btn btn-primary btn-wcdark waves-effect"
                            style={{width: "150px"}}
                          >
                            See all case details
                          </button>
                          */}
                        </span>
                      </Row>
                    </CardTitle>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          <th style={{verticalAlign: "middle"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>rowCheck("chkAll", e.target.checked)} /></th>
                          {
                            columns && columns.length ?
                            columns.map((col, index) => {
                                return (
                                  <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                                    {col.label == "WeCrest send count" ? 
                                    <>
                                      WeCrest send count <i className="uil uil-question-circle font-size-18" id={"WeCrest_send_count"}></i>
                                      <UncontrolledTooltip placement="top" target={"WeCrest_send_count"}>
                                      Number of WeCrest members who sent emails from WeCrest for this case. No more than ten members can send an email for the same case. 
                                      <br/>Green: 0 email sent,
                                      <br/>Blue: 1-4 emails sent,
                                      <br/>Orange: 5-9 emails sent,
                                      <br/>Red: 10 emails sent
                                      </UncontrolledTooltip>
                                    </> 
                                    : col.label}
                                    {col.asc && col.desc? 
                                    <>
                                    <Link to='#' onClick={()=> {viewMadridNotifier(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                    <Link to='#' onClick={()=> {viewMadridNotifier(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                                    </>
                                    :null}
                                  </th>
                                )
                            })
                            :null
                          }
                          </tr>
                        </thead>
                        <tbody>
                        {
                          madridNotifier && madridNotifier.length ?
                            madridNotifier.map((madrid, index) => {
                                return (
                                    <tr key={index} style={{backgroundColor: madrid.lastContactDate !== '0000-00-00' ? emailSentBgColor : null}}>
                                      <td style={{verticalAlign: "middle"}}>
                                        <input type="checkbox" 
                                          checked={madrid.checked}
                                          onClick={(e)=>rowCheck(index, e.target.checked)}
                                          />
                                      </td>
                                      <td style={{verticalAlign: "middle"}} align="center">
                                        <Row>
                                          <div className="d-flex vertical-align-center horizon-align-center gap-2">
                                            {
                                            /*
                                              1. premSubsYn = 'Y' or unlockYn = 'Y' => 이메일 에디터 노출
                                                1-1. wecrestSendCount < 10 || ((premSubsYn = 'Y' && lastContactDate !== '0000-00-00') or unlockYn = 'Y') 이면 이메일 에디터 기능 가능
                                                1-2. 1-1이 아니면 이메일 에디터 기능 사용 불가 
                                              2. 1이 아니면 unlock버튼 노출
                                                2-1.  unlock 크레딧이 있으면 
                                                  2-1-1. wecrestSendCount < 4면 파란색 => "Unlock to view the details and send emails for this case"
                                                  2-1-2. wecrestSendCount < 10 면 주황색 => "Heads up! 4 or more WeCrest members already sent an email for this case. You can go ahead and unlock or click cancel and find a green case.<br/>Green: 0-3 emails sent,<br/>Orange: 4-9 emails sent,<br/>Red: 10 emails sent" 
                                                  2-1-3. wecrestSendCount >= 10 면 빨간색 => "Unlocking is not possible for this case. The sending limit of 10 emails has been reached already."
                                                2-2. No remaining credits
                                            */
                                            madrid.premSubsYn === 'Y' || madrid.unlockYn === 'Y' ?
                                              <>
                                              <Link to="#"
                                                onClick={()=>{
                                                  if (madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y')) {
                                                    openEditor(madrid.irNumber, madrid.ftpDate, madrid.crCode, madrid.holderCountry, madrid.irEmlTmpSetYn)
                                                  }
                                                }}>
                                                <i className={
                                                  madrid.wecrestSendCount >= 10 && madrid.premSubsYn == 'Y' && madrid.lastContactDate == '0000-00-00' ? 
                                                  "uil uil-envelope-edit font-size-22 text-danger" 
                                                  : madrid.irEmlTmpSetYn == 'Y' ?
                                                    "uil uil-envelope-edit font-size-22 text-success"
                                                    : "uil uil-envelope-edit font-size-22 text-warning"
                                                  } id={"editEmail_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"editEmail_"+index}>
                                                  {madrid.irEmlTmpSetYn == 'Y' ? "Edited template" : "Default template"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link to="#"
                                                onClick={()=>{
                                                  if (madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y')) {
                                                    if ((madrid.exceptJurSetYn == 'Y' && (madrid.irEmlTmpSetYn == 'Y' || madrid.exceptJurEmlTmpSetYn == 'Y') && madrid.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                          || (madrid.exceptJurSetYn == 'N' && (madrid.irEmlTmpSetYn == 'Y' || madrid.defaultJurEmlTmpSetYn == 'Y') && madrid.defaultJurSendEmailConfirmStatus == 'Confirmed')) {
                                                      madridValidation.setFieldValue("submitType", "preview_"+index);
                                                      madridValidation.handleSubmit(madridValidation.values);
                                                    } else {
                                                      setAlertMsg(<span>Preview is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>);
                                                    }
                                                  }
                                                }}>
                                                <i className={madrid.wecrestSendCount < 10 || (madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y' ? "uil uil-file-search-alt font-size-22" : "uil uil-file-search-alt font-size-22 text-danger"} id={"preview_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"preview_"+index}>
                                                  Preview
                                                </UncontrolledTooltip>
                                              </Link>
                                              </>
                                            : 
                                              <Link to="#" className={unlockCredit - unlockCnt > 0 ? madrid.wecrestSendCount == 0 ? "text-success" : madrid.wecrestSendCount < 5 ? "text-primary" : madrid.wecrestSendCount < 10 ? "text-warning" : "text-danger" : "text-danger"}
                                                onClick={()=>{
                                                  if (unlockCredit - unlockCnt > 0) {
                                                    if (madrid.wecrestSendCount < 10) {
                                                      setSubmitType("unlock_"+index);
                                                      setConfirmMsg(<>
                                                        Unlock to view the details and send emails for this case.<br/>
                                                        You cannot unlock a case, if sending limit of 10 emails per case has been reached already.
                                                        </>);
                                                      setConfirmBtnText("Confirm");
                                                      setCancelBtnText("Cancel");
                                                      setConfirmAlert(true);
                                                    }
                                                  } else {
                                                    dispatch(onSetUpgradeAlertMessage({title:"Sorry, unfortunately you do not have any credits remaining.",text:"Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday."}))
                                                  }
                                                }}>
                                                <i className={madrid.wecrestSendCount >= 10 ? "uil uil-lock-alt font-size-22" : "uil uil-unlock-alt font-size-22"} id={"unlock_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"unlock_"+index}>
                                                  {unlockCredit - unlockCnt > 0 ? 
                                                    madrid.wecrestSendCount >= 10 ? 
                                                      errMsg[5] 
                                                    : 
                                                      madrid.wecrestSendCount >= 5 ? 
                                                        <>Heads up! 5 or more WeCrest members already sent an email for this case. You can go ahead and unlock or click cancel and find a green or blue case.<br/>Green: 0 email sent,<br/>Blue: 1-4 emails sent,<br/>Orange: 5-9 emails sent,<br/>Red: 10 emails sent</> 
                                                      :
                                                        "Unlock to view the details and send emails for this case." 
                                                  : errMsg[3]}
                                                </UncontrolledTooltip>
                                              </Link>
                                            }
                                          </div>
                                        </Row>
                                      </td>
                                      
                                      <td style={{verticalAlign: "middle"}}>
                                        {
                                        madrid.representativeInfo && madrid.representativeInfo !== '' ?
                                        <>
                                        <Row>
                                        <div className="d-flex vertical-align-center gap-2">
                                        <p className='multiline' id={"rep_info_"+index}>
                                          {madrid.representativeInfo}
                                        </p>
                                        <UncontrolledTooltip placement="top" target={"rep_info_"+index}>
                                          {madrid.representativeInfo}
                                        </UncontrolledTooltip>
                                        <Link to="#" id={"rep_copy_"+index}
                                          onClick={()=>{
                                            if (window.isSecureContext && navigator.clipboard) {
                                              navigator.clipboard.writeText(madrid.representativeInfo);
                                            } else {
                                              // console.log('old copy clipboard!');
                                              unsecuredCopyToClipboard(madrid.representativeInfo, "rep_copy_"+index);
                                            }
                                            showSuccessCopyToast();
                                          }}>
                                          <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                        </Link>
                                        <UncontrolledTooltip placement="top" target={"rep_copy_"+index}>
                                          Copy Representative's information to clipboard
                                        </UncontrolledTooltip>
                                        </div>
                                        </Row>
                                        {
                                        /*
                                          1. premSubsYn = 'Y' or unlockYn = 'Y' => 이메일 발송 기능 노출
                                            1-1. wecrestSendCount < 10 || ((premSubsYn = 'Y' && lastContactDate !== '0000-00-00') or unlockYn = 'Y') 이면 이메일 발송 기능 가능
                                            1-2. 1-1이 아니면 이메일 발송 기능 사용 불가 
                                        */
                                        madrid.premSubsYn === 'Y' || madrid.unlockYn === 'Y' ?
                                          <Row>
                                          <div className="d-flex vertical-align-center gap-2">
                                            <Link to="#" onClick={() => { togContactsScreen(madrid.representativeGroupNo) }} data-toggle="modal" 
                                              className={madrid.repDbStatus === "upToDate"? "text-success" 
                                                      : madrid.repMemberConfirmEmlYn === 'Y' ?  "text-primary" 
                                                      : madrid.repSuggestEmlYn === 'Y' ? "text-warning" 
                                                      : "text-danger"}
                                              >
                                              <i className="uil uil-at font-size-22" id={"contacts_rep_"+index}></i>
                                              <UncontrolledTooltip placement="top" target={"contacts_rep_"+index}>
                                                {madrid.dbStatus === "notUpToDate" ? "Representative's email address(es) haven't been updated in a while and may be out of date!" 
                                                : madrid.repMemberConfirmEmlYn === 'Y' ?  "Contacts of representative with member confirmations" 
                                                : madrid.repSuggestEmlYn === 'Y' ? "Contacts of representative with WeCrest suggestions" 
                                                : "Contacts of representative"}
                                              </UncontrolledTooltip>
                                            </Link>
                                            <Link to="#" className={madrid.repStarYn === 'Y' ? "text-warning": "text-black-50"}
                                              onClick={(e)=> {
                                                madridValidation.setFieldValue("submitType", (madrid.repStarYn === 'Y' ? "unStar_target_" : "star_target_") + madrid.representativeGroupNo)
                                                madridValidation.handleSubmit(madridValidation.values);
                                              }}>
                                              <i className="uil uil-star font-size-22" id={"Starred_rep_"+index}></i>
                                              <UncontrolledTooltip placement="top" target={"Starred_rep_"+index}>
                                                {madrid.repStarYn === 'Y' ? "Remove from starred": "Star representative"}
                                              </UncontrolledTooltip>
                                            </Link>
                                            <Link to="#" className={madrid.repHideYn === 'Y' ? "text-danger": "text-black-50"}
                                              onClick={(e)=> {
                                                madridValidation.setFieldValue("submitType", (madrid.repHideYn === 'Y' ? "unHide_target_" : "hide_target_") + madrid.representativeGroupNo)
                                                madridValidation.handleSubmit(madridValidation.values);
                                              }}>
                                              <i className="uil uil-eye-slash font-size-22" id={"Hidden_rep_"+index}></i>
                                              <UncontrolledTooltip placement="top" target={"Hidden_rep_"+index}>
                                                {madrid.repHideYn === 'Y' ? "Unhide representative": "Hide representative"}
                                              </UncontrolledTooltip>
                                            </Link>
                                            <Link to="#" className="text-black-50"
                                              onClick={()=>{
                                                if (madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y'))  {
                                                  if (madrid.repDbStatus === "upToDate") {
                                                    if (madrid.senderEmailConfirmStatus === '' || madrid.senderEmailConfirmStatus === 'pending') {
                                                      setAlertMsg(<span>
                                                        You are one small step away from being able to use the Madrid Notifier. 
                                                        Please complete the setup process by entering your email settings <a href='/settings#subscriptionSettings' className='a-href-underline'>here</a>. 
                                                        Once you have completed this step, you will be able to send emails using the Madrid Notifier.
                                                        </span>);
                                                    } else {
                                                      /*
                                                      exceptJurSetYn : 이메일 발송시 예외 국가 설정 여부                                                                
                                                        Y >> Email template : (irEmlTmpSetYn == 'Y' or exceptJurEmlTmpSetYn == 'Y') 
                                                          && Sender email : exceptJurSendEmailConfirmStatus == 'Confirmed'
                                                        N >> Email template : (irEmlTmpSetYn == 'Y' or defaultJurEmlTmpSetYn == 'Y') 
                                                          && Sender email : defaultJurSendEmailConfirmStatus == 'Confirmed' 
                                                      */
                                                      if ((madrid.exceptJurSetYn == 'Y' && (madrid.irEmlTmpSetYn == 'Y' || madrid.exceptJurEmlTmpSetYn == 'Y') && madrid.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                            || (madrid.exceptJurSetYn == 'N' && (madrid.irEmlTmpSetYn == 'Y' || madrid.defaultJurEmlTmpSetYn == 'Y') && madrid.defaultJurSendEmailConfirmStatus == 'Confirmed')) {
                                                        setSubmitType("send_"+index);
                                                        setSendTarget("REP");
                                                        setConfirmMsg("Are you sure you want to send this email?");
                                                        setConfirmBtnText("Yes, send!");
                                                        setCancelBtnText("Cancel");
                                                        setConfirmAlert(true);
                                                      } else {
                                                        setAlertMsg(<span>Send is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>settings</a>.</span>);
                                                      }
                                                    }
                                                  } else {
                                                    togContactsScreen(madrid.representativeGroupNo)
                                                  }
                                                }
                                              }}>
                                              <i className={madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y') ? "uil uil-message font-size-22" : "uil uil-message font-size-22 text-danger"} id={"send_rep_"+index}></i>
                                              <UncontrolledTooltip placement="top" target={"send_rep_"+index}>
                                                {
                                                madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y') ? 
                                                  madrid.repDbStatus === "new"? 
                                                  "To send an email to this entity, please save a contact with an email address."
                                                  : 
                                                  "Send to representative"
                                                :
                                                errMsg[9]
                                                }
                                              </UncontrolledTooltip>
                                            </Link>
                                          </div>
                                          </Row>
                                        : null     
                                        }
                                        </>
                                        :
                                        <p className='multiline'></p>
                                        }
                                      </td>
                                      <td style={{verticalAlign: "middle"}}>
                                        {
                                        madrid.holderInfo && madrid.holderInfo !== '' ?
                                        <>
                                        <Row>
                                        <div className="d-flex vertical-align-center gap-2">
                                          <p className='multiline' id={"hol_info_"+index}>{madrid.holderInfo}</p>
                                          <UncontrolledTooltip placement="top" target={"hol_info_"+index}>
                                            {madrid.holderInfo}
                                          </UncontrolledTooltip>
                                          <Link to="#" id={"hol_copy_"+index}
                                            onClick={()=>{
                                              if (window.isSecureContext && navigator.clipboard) {
                                                navigator.clipboard.writeText(madrid.holderInfo);
                                              } else {
                                                // console.log('old copy clipboard!');
                                                unsecuredCopyToClipboard(madrid.holderInfo, "hol_copy_"+index);
                                              }
                                              showSuccessCopyToast();
                                            }}>
                                            <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                          </Link>
                                          <UncontrolledTooltip placement="top" target={"hol_copy_"+index}>
                                            Copy Holder's information to clipboard
                                          </UncontrolledTooltip>
                                        </div>
                                        </Row>
                                        {
                                        madrid.premSubsYn === 'Y' || madrid.unlockYn === 'Y' ?
                                        <Row>
                                        <div className="d-flex vertical-align-center gap-2">
                                          <Link to="#" onClick={() => { togContactsScreen(madrid.holderGroupNo) }} data-toggle="modal" 
                                            className={madrid.holDbStatus === "upToDate"? "text-success" 
                                                    : madrid.holMemberConfirmEmlYn === 'Y' ?  "text-primary" 
                                                    : madrid.holSuggestEmlYn === 'Y' ? "text-warning" 
                                                    : "text-danger"}
                                            >
                                            <i className="uil uil-at font-size-22" id={"contacts_hol_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"contacts_hol_"+index}>
                                              {madrid.holDbStatus === "notUpToDate" ? "Holder's email address(es) haven't been updated in a while and may be out of date!" 
                                              : madrid.holMemberConfirmEmlYn === 'Y' ?  "Contacts of holder with member confirmations" 
                                              : madrid.holSuggestEmlYn === 'Y' ? "Contacts of holder with WeCrest Suggestions" 
                                              : "Contacts of holder"}
                                            </UncontrolledTooltip>
                                          </Link>
                                          <Link to="#" className={madrid.holStarYn === 'Y' ? "text-warning": "text-black-50"}
                                            onClick={(e)=> {
                                              madridValidation.setFieldValue("submitType", (madrid.holStarYn === 'Y' ? "unStar_target_" : "star_target_") + madrid.holderGroupNo)
                                              madridValidation.handleSubmit(madridValidation.values);
                                            }}>
                                            <i className="uil uil-star font-size-22" id={"Starred_hol_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"Starred_hol_"+index}>
                                              {madrid.holStarYn === 'Y' ? "Remove from starred": "Star holder"}
                                            </UncontrolledTooltip>
                                          </Link>

                                          <Link to="#" className={madrid.holHideYn === 'Y'? "text-danger": "text-black-50"}
                                            onClick={(e)=> {
                                              madridValidation.setFieldValue("submitType", (madrid.holHideYn === 'Y' ? "unHide_target_" : "hide_target_") + madrid.holderGroupNo)
                                              madridValidation.handleSubmit(madridValidation.values);
                                            }}>
                                            <i className="uil uil-eye-slash font-size-22" id={"Hidden_hol_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"Hidden_hol_"+index}>
                                              {madrid.holHideYn === 'Y' ? "Unhide holder": "Hide holder"}
                                            </UncontrolledTooltip>
                                          </Link>

                                          <Link to="#" className="text-black-50"
                                            onClick={()=>{
                                              if (madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y')) {
                                                if (madrid.holDbStatus === "upToDate") {
                                                  if (madrid.senderEmailConfirmStatus === '' || madrid.senderEmailConfirmStatus === 'pending') {
                                                    setAlertMsg(<span>
                                                      You are one small step away from being able to use the Madrid Notifier. 
                                                      Please complete the setup process by entering your email settings <a href='/settings#subscriptionSettings' className='a-href-underline'>here</a>. 
                                                      Once you have completed this step, you will be able to send emails using the Madrid Notifier.
                                                    </span>);
                                                  } else {
                                                    if ((madrid.exceptJurSetYn == 'Y' && (madrid.irEmlTmpSetYn == 'Y' || madrid.exceptJurEmlTmpSetYn == 'Y') && madrid.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                          || (madrid.exceptJurSetYn == 'N' && (madrid.irEmlTmpSetYn == 'Y' || madrid.defaultJurEmlTmpSetYn == 'Y') && madrid.defaultJurSendEmailConfirmStatus == 'Confirmed')) {
                                                      setSubmitType("send_"+index);
                                                      setSendTarget("HOL");
                                                      setConfirmMsg("Are you sure you want to send this email?");
                                                      setConfirmBtnText("Yes, send!");
                                                      setCancelBtnText("Cancel");
                                                      setConfirmAlert(true);
                                                    } else {
                                                      setAlertMsg(<span>Send is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>);
                                                    }
                                                  }
                                                } else {
                                                  togContactsScreen(madrid.holderGroupNo)
                                                }
                                              }
                                            }}>
                                            <i className={madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y') ? "uil uil-message font-size-22" : "uil uil-message font-size-22 text-danger"} id={"send_hol_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"send_hol_"+index}>
                                              {
                                              madrid.wecrestSendCount < 10 || ((madrid.premSubsYn == 'Y' && madrid.lastContactDate !== '0000-00-00') || madrid.unlockYn == 'Y') ? 
                                                madrid.holDbStatus === "new"? 
                                                "To send an email to this entity, please save a contact with an email address."
                                                : 
                                                "Send to holder"
                                              :
                                              errMsg[9]
                                              }
                                            </UncontrolledTooltip>
                                          </Link>
                                        </div>
                                        </Row>
                                        : null
                                        }
                                        </>
                                        :
                                        <p className='multiline'></p>
                                        }
                                      </td>
                                      <td style={{verticalAlign: "middle"}} align="center">
                                        <Row className="d-flex horizon-align-center">
                                          {madrid.irNumber}
                                        </Row>
                                        {
                                        madrid.premSubsYn === 'Y' || madrid.unlockYn === 'Y' ?
                                        <Row>
                                          <div className="d-flex horizon-align-center vertical-align-center gap-2">
                                          <Link to="#" className={madrid.irStarYn === 'Y' ? "text-warning": null}
                                            onClick={(e)=> {
                                              madridValidation.setFieldValue("submitType", (madrid.irStarYn === 'Y' ? "unStar_case_" : "star_case_") + index)
                                              madridValidation.handleSubmit(madridValidation.values);
                                            }}>
                                            <i className="uil uil-star font-size-22" id={"Starred_case_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"Starred_case_"+index}>
                                              {madrid.irStarYn === 'Y' ? "Remove from starred": "Star case"}
                                            </UncontrolledTooltip>
                                          </Link>
                                          <Link to="#" className={madrid.irHideYn === 'Y'? "text-danger": null}
                                            onClick={(e)=> {
                                              madridValidation.setFieldValue("submitType", (madrid.irHideYn === 'Y' ? "unHide_case_" : "hide_case_") + index)
                                              madridValidation.handleSubmit(madridValidation.values);
                                            }}>
                                            <i className="uil uil-eye-slash font-size-22" id={"Hidden_case_"+index}></i>
                                            <UncontrolledTooltip placement="top" target={"Hidden_case_"+index}>
                                              {madrid.irHideYn === 'Y' ? "Unhide case": "Hide case"}
                                            </UncontrolledTooltip>
                                          </Link>
                                          <a href={madrid.pdfStorageUrl} target="_blank" rel="noreferrer"><i className="mdi mdi-file-pdf text-danger font-size-22"></i></a> 
                                          </div>
                                        </Row>
                                        : 
                                        null
                                        }
                                      </td>
                                      <td style={{verticalAlign: "middle"}} align="center">{madrid.crCode}</td>
                                      <td style={{verticalAlign: "middle"}} align="center">{madrid.recordedDate}<br/>{madrid.druSendDate}</td>
                                      <td style={{verticalAlign: "middle"}} align="center" width='10%'>
                                        {madrid.tmStorageUrl && madrid.tmStorageUrl.startsWith('https:') ? 
                                          <img
                                            id={"tm_img_"+index}
                                            src={madrid.tmStorageUrl}
                                            alt=""
                                            className="img-fluid mx-auto d-block tab-img rounded"
                                            referrerpolicy="no-referrer"
                                            onLoad={onImgLoad}
                                          />
                                          : madrid.tmTxt.replace('&#36;', '$')}
                                      </td>
                                      <td style={{verticalAlign: "middle"}} align="center">
                                        {
                                          madrid.wecrestSendCount == 0 ?
                                          <i className="bx bxs-circle font-size-22 text-success"></i>
                                          :
                                            madrid.wecrestSendCount < 5 ?
                                            <i className="bx bxs-circle font-size-22 text-primary"></i>
                                            :
                                              madrid.wecrestSendCount < 10 ?
                                              <i className="bx bxs-circle font-size-22 text-warning"></i>
                                              :
                                              <i className="bx bxs-circle font-size-22 text-danger"></i>
                                        }
                                      </td>
                                    </tr>
                                )
                              })
                          : null
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="d-flex align-items-center gap-2">
                        <label
                          htmlFor="perPage"
                          >
                          Entries per page
                        </label>
                        <div style={{width: "100px"}}>
                          <select 
                            className="form-select" 
                            name="perPage"
                            onChange={e => {viewMadridNotifier(null, 1, e.target.value);}}
                            value={madridValidation.values.perPage}
                          >
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                        </div>
                        {/*
                        <button
                          type="button"
                          onClick={() => {
                            window.location.href = "/membershipPlan";
                          }}
                          className="btn btn-primary btn-wcdark waves-effect"
                          style={{width: "150px"}}
                        >
                          See all case details
                        </button>
                        */}
                      </span>
                      <span className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-1">
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewMadridNotifier(null, 1);}}
                            disabled={(madridValidation.values.curPage == 1)}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewMadridNotifier(null, Number(madridValidation.values.curPage)-1);}}
                            disabled={(madridValidation.values.curPage == 1)}
                          >
                            {"<"}
                          </Button>
                        </div>
                        Page{" "}
                        <strong>
                          {madridValidation.values.curPage} of {totalPage}
                        </strong>
                        
                        <Input
                          type="number"
                          min={1}
                          style={{ width: 70 }}
                          max={totalPage}
                          name="curPage"
                          defaultValue={madridValidation.values.curPage}
                          value={curPageInput}
                          onChange={(e) => { setCurPageInput(e.target.value)}}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              viewMadridNotifier(null, e.target.value);
                            }                      
                          }}
                        />
                        <div className="d-flex gap-1">
                          <Button 
                            type="button"
                            color="primary"
                            onClick={()=>{viewMadridNotifier(null, Number(madridValidation.values.curPage)+1);}}
                            disabled={(madridValidation.values.curPage == totalPage)}
                          >
                            {">"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewMadridNotifier(null, totalPage);}}
                            disabled={(madridValidation.values.curPage == totalPage)}
                          >
                            {">>"}
                          </Button>
                        </div>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : null}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

/*
UserList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  onGetUserList: PropTypes.func,
};
*/

export default MadridNotifier;