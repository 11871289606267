import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Form,
  Input,
  Media,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import _, { cloneDeep, set } from "lodash";
import ContactsDetails from "../Contacts/ViewDetail";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import * as Yup from "yup";
import {
  getHagueInfo,
  gethagueNotifierSettings,
  setHagueLoading as onSetHagueLoading,
  saveHagueNotifierSettings,
  setHagueSuccess,
  multipleStarHague,
  multipleUnstarHague,
  multipleHideHague,
  multipleUnhideHague,
  downHagueExcel,
  starHagueTarget,
  unstarHagueTarget,
  hideHagueTarget,
  unhideHagueTarget,
  downMultipleHagueExcel,
  downMultipleHaguePdf,
  previewHagueEmail,
  setPreviewHagueEmail,
  sendHagueEmail,
  changeHagueNotifier,
  downHaguePdf,
  setUpgradeAlertMessage,
  unlockHague,
  getHagueNotifierInfoList,
  setHagueResults,
  setHagueSentSuccess,
  setHagueSettings
} from "../../store/haguenotifier/actions";
import WeCrestPagination from "../../components/WeCrestPagination";
import LoadingModal from "../../components/Common/LoadingModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import EditorModal from "../../components/Common/EditorModal";
import styled from "styled-components";
import { useFormik } from "formik";
import SelectMultipleJurisdictions from "../../components/Filter/SelectMultipleJurisdictions";
import SelectMultipleCountry from "../../components/Filter/SelectMultipleCountry";
import SelectCountry from "../../components/Filter/SelectCountry";
import SearchName from "../../components/Filter/SearchName";
import PeriodBtn from "../../components/Filter/PeriodBtn";
import dateFormat from 'dateformat';
import { changeSearchNameList, changeSearchNameSelectedList, getHagueSearchTargetGroupList, getSearchTargetGroupList } from "../../store/actions";

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const HagueNotifer = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const dispatch = useDispatch();

  const {
    hagueSettings,
    hagueInfoResult,
    hagueInfoTotalPages,
    loading,
    success,
    noticeStr,
    previewEmail,
    previewPdfList,
    freeMemberAutosearch,
    userGrade,
    upgradeAlertMessage,
    unlockCredit, 
    unlockCnt,
    result, 
    sentCnt,
    unsubsEmails,
    disableEmails,
    sentIn24Emails,
    sentSuccess,
    isHagueSearched
  } = useSelector((state) => ({
    hagueSettings: state.hagueNotifier.hagueSearchSettings,
    hagueInfoResult: state.hagueNotifier.hagueInfo,
    hagueInfoTotalPages: state.hagueNotifier.totalPages,
    loading: state.hagueNotifier.loading,
    success: state.hagueNotifier.success,
    noticeStr: state.hagueNotifier.noticeStr,
    previewEmail: state.hagueNotifier.previewEmail,
    previewPdfList: state.hagueNotifier.previewPdfList,
    freeMemberAutosearch: state.hagueNotifier.freeMemberAutosearch,
    userGrade: state.hagueNotifier.userGrade,
    upgradeAlertMessage: state.hagueNotifier.upgradeAlertMessage,
    unlockCredit: state.Unlock.unlockCredit,
    unlockCnt: state.Unlock.unlockCnt,
    result: state.hagueNotifier.result,
    sentCnt: state.hagueNotifier.sentCnt,
    unsubsEmails: state.hagueNotifier.unsubsEmails,
    disableEmails: state.hagueNotifier.disableEmails,
    sentIn24Emails: state.hagueNotifier.sentIn24Emails,
    sentSuccess: state.hagueNotifier.sentSuccess,
    isHagueSearched: state.hagueNotifier.isHagueSearched
  }));

  const [chkAllFlag, setChkAllFlag] = useState(false);
  const [starOrUnstar, setStarOrUnstar] = useState("Star");
  const [hideOrUnhide, setHideOrUnhide] = useState("Hide");

  const hagueValidation = useFormik({

    enableReinitialize: true,
    initialValues:{
      inOutType:  hagueSettings && hagueSettings.inOutType ? hagueSettings.inOutType : 'IN',
      jurType: hagueSettings && hagueSettings.jurType? hagueSettings.jurType : "",
      jrCdList: hagueSettings && hagueSettings.jurList ? hagueSettings.jurList : [],
      holderCdList: hagueSettings && hagueSettings.holderCdList ? hagueSettings.holderCdList : [],
      repCdList: hagueSettings && hagueSettings.repCdList ? hagueSettings.repCdList : [],
      searchNoList: hagueSettings && hagueSettings.groupNoList? hagueSettings.groupNoList : [],
      searchKeyword: hagueSettings && hagueSettings.searchKeyword? hagueSettings.searchKeyword : "",
      searchNameList: [],
      fromDate: hagueSettings && hagueSettings.startDate ? hagueSettings.startDate : "",
      toDate: hagueSettings && hagueSettings.endDate ? hagueSettings.endDate : "",
      irNumber: hagueSettings && hagueSettings.irNumber ? hagueSettings.irNumber : "",
      showHiddenCase: hagueSettings && hagueSettings.entityStatus ? hagueSettings.entityStatus : "ALL",
      hasRepresentative: hagueSettings && hagueSettings.hasRep ? hagueSettings.hasRep : "BOTH",
      emailSent: hagueSettings && hagueSettings.emailSent ? hagueSettings.emailSent : "N",
      dateType: hagueSettings && hagueSettings.dateType ? hagueSettings.dateType : "Inscribed",
      datePeriod: hagueSettings && hagueSettings.datePeriod ? hagueSettings.datePeriod : "",
      screenGrade: "Gold",
      sortType: "RDD",
      pageSize: 50,
      curPage: 1,
      screenName: "HagueNotifier",
    },

  validationSchema: Yup.object().shape({
    fromDate: Yup.date()
      .max(Yup.ref('toDate'), "From date must be earlier than to date")
      .required("Please Select From date"),
    toDate: Yup.date()
      .required("Please Select to date"),
  }),
  validateOnChange: false, 
  validateOnBlur: false, 

  onSubmit: (values) =>{
    if (values["jurType"] === 'Individual' && (values["jrCdList"] === '' || values["jrCdList"].length === 0)) {
      if (values["inOutType"] === 'IN') {
        values["jurType"] = 'All-subs';
      } else {
        values["jurType"] = 'All';
      }
    }

    if (values["holderType"] === 'Individual' && (values["holderCdList"] === '' || values["holderCdList"].length === 0)) {
      if (values["inOutType"] === 'OUT') {
        values["holderType"] = 'All-subs';
      } else {
        values["holderType"] = 'All';
      }
    }

    if (values["repType"] === 'Individual' && (values["repCdList"] === '' || values["repCdList"].length === 0)) {
      values["repType"] = 'All';
    }

      const filterParam = {
        inOutType: values["inOutType"],
        jurType: values["jurType"],
        jurisdictionsList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
        holderType: values["holderType"],
        holderCountryList:  values["holderType"] === "All" || values["holderType"] === "All-subs"? [] : values["holderCdList"],
        repType: values["repType"],
        representativeCountryList: values["repType"] === "All" || values["repType"] === "All-subs"? [] : values["repCdList"],
        groupNoList: values["searchNoList"],
        searchNameList: values["searchNameList"],
        irNumber: values["irNumber"].trim(),
        startDate: values["fromDate"],
        endDate: values["toDate"],
        showHiddenCase: values["showHiddenCase"],
        hasRepresentative: values["hasRepresentative"],
        emailSent: values["emailSent"],
        dateType: values["dateType"],
        datePeriod: values["datePeriod"],
        searchKeyword: values["searchKeyword"],
        pageSize: values["pageSize"],
        curPage: values["curPage"],
        sortType: values["sortType"]
      };

      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Hague Notifier (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 

      let submitType = values["submitType"];

      if(submitType=== "view"){
        // console.log(filterParam)
        dispatch(getHagueInfo(filterParam));
      }else if(submitType === "saveSettings"){
        const settingsParam = {
          inOutType: values["inOutType"],
          jurType: values["jurType"],
          jurList: values["jrCdList"],
          holderType: values["holderType"],
          holderCdList: values["holderCdList"],
          repType: values["repType"],
          repCdList: values["repCdList"],
          searchNameList: values["searchNameList"],
          groupNoList: values["searchNoList"],
          irNumber: values["irNumber"].trim(),
          entityStatus: values["showHiddenCase"],
          hasRep: values["hasRepresentative"],
          emailSent: values["emailSent"],
          datePeriod: values["datePeriod"],
          dateType: values["dateType"],
          searchKeyword: values["searchKeyword"],
        }
        dispatch(saveHagueNotifierSettings(settingsParam))

      }else if(submitType === 'Hide'){
        const _hagueNotifier = [];
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true && row.hagueHideYn === 'N'){
            _hagueNotifier.push(row);
          }
        })
        dispatch(multipleHideHague(_hagueNotifier, values.inOutType));
      }else if(submitType === 'Unhide'){
        const _hagueNotifier = [];
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true && row.hagueHideYn === 'Y'){
            _hagueNotifier.push(row);
          }
        })
        dispatch(multipleUnhideHague(_hagueNotifier, values.inOutType));
      }else if(submitType === 'Star'){
        const _hagueNotifier = [];
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true && row.hagueStarYn === 'N'){
            _hagueNotifier.push(row);
          }
        })
        dispatch(multipleStarHague(_hagueNotifier, values.inOutType));

      }else if(submitType === 'Unstar'){
        const _hagueNotifier = [];
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true && row.hagueStarYn === 'Y'){
            _hagueNotifier.push(row);
          }
        })
        dispatch(multipleUnstarHague(_hagueNotifier, values.inOutType));
      }else if(submitType === 'Excel'){
        dispatch(downHagueExcel(filterParam, fileName));
      }else if(submitType === 'Pdf'){
        dispatch(downHaguePdf(filterParam));
        
      }else if(submitType === 'multipleExcel'){
        const _hagueNotifier = [];
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true){
            _hagueNotifier.push(row);
          }
        })
        dispatch(downMultipleHagueExcel({hagueNotifierList: _hagueNotifier}, fileName));
      }else if(submitType === 'multiplePdf'){
        const _hagueNotifier = [];
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true){
            _hagueNotifier.push(row);
          }
        })

        if(_hagueNotifier.length>50){
          dispatch(setHagueResults(8))
        }else{
          const param = {
            inOutType: values["inOutType"],
            jurList: values["jrCdList"],
            jurType: values["jurType"] === "All" || values["jurType"] === "All-subs"? values["jurType"] : "Individual",
            holderType: values["holderCdList"].length > 0 ? "Individual" : "All",
            holderCdList: values["holderCdList"],
            repType: values["repCdList"].length > 0 ? "Individual" : "All",
            repCdList: values["repCdList"],
            searchNameList: values["searchNameList"],
            irNumber: values["irNumber"].trim(),
            timePeriod: values["datePeriod"],
            hagueNotifierList: _hagueNotifier,
          }
          dispatch(downMultipleHaguePdf(param, fileName));
        }
      }else if(submitType === 'multipleSend'){
        const _hagueNotifier = []
        hagueInfoResult.map((row, index) =>{
          if(row.checked === true){
            _hagueNotifier.push(row);
          }
        });

        dispatch(sendHagueEmail({inOutType: values.inOutType, hagueNotifierList: _hagueNotifier, sendTarget: sendTarget})) 
      }else if(submitType === 'multipleUnlock'){
        const _hagueNotifier = []
        hagueInfoResult.map((row,index)=>{
          if(row.checked && row.unlockYn === 'N'){
            _hagueNotifier.push(row)
          }
        })  
        if(_hagueNotifier.length>0){
          dispatch(unlockHague({inOutType: values.inOutType, hagueNotifierList: _hagueNotifier}))   
        }else{
          dispatch(setHagueResults(6))
        }
      }else if(submitType.indexOf("unlock_") === 0){
        let idx = submitType.replace("unlock_", "");
        const _hagueNotifier = [];
        _hagueNotifier.push(hagueInfoResult[idx]);
        
        dispatch(unlockHague({inOutType: values.inOutType, hagueNotifierList:  _hagueNotifier}))
      }else if(submitType.indexOf("send_") === 0){
        if(sendTarget === 'REP'){
          let idx = submitType.replace("send_", "");
          const _hagueNotifier = [];
          _hagueNotifier.push(hagueInfoResult[idx]);
          dispatch(sendHagueEmail({inOutType: values.inOutType, hagueNotifierList:  _hagueNotifier, sendTarget:sendTarget}))
        }else if(sendTarget === 'HOL'){
          // console.log("HOL"+submitType)
          let idx = submitType.replace("send_", "");
          const _hagueNotifier = [];
          _hagueNotifier.push(hagueInfoResult[idx]);
          // console.log("send target" + idx)
          dispatch(sendHagueEmail({inOutType: values.inOutType, hagueNotifierList:  _hagueNotifier, sendTarget:sendTarget, sendTargetGroupNo: sendTargetGroupNo}))
        }else{
          console.log("error")
        }       
      }

    }
  })

  const viewHagueNotifier = (sortType, curPage, perPage) => {
    if(curPage){
      if(curPage > hagueInfoTotalPages) {
        curPage = hagueInfoTotalPages;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    hagueValidation.setFieldValue("submitType", "view");
    if(sortType){
      hagueValidation.setFieldValue("sortType", sortType);
    }
    if(curPage){
      hagueValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      hagueValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      hagueValidation.setFieldValue("pageSize", perPage);
    }
    
    hagueValidation.handleSubmit(hagueValidation.values);
  }

  const rowCheck = (str, checked) => {
    let chkCnt = 0;
    var hiddenCaseCnt = 0;
    var starCaseCnt = 0;

    const _hagueNotifier = [];
    hagueInfoResult.map((row, index) =>{
        let _row = cloneDeep(row);

        if(str === "chkAll" || str === index){
            _row.checked = checked;
        }

        if(_row.checked === true){
          chkCnt++;
          if(row.hagueHideYn === 'Y'){
            hiddenCaseCnt++;
          }
          if(row.hagueStarYn === 'Y'){
            starCaseCnt++;
          }
        }

        _hagueNotifier.push(_row);
    }); 

    if(chkCnt !== 0 && chkCnt === hiddenCaseCnt){
      setHideOrUnhide("Unhide");
    }else{
      setHideOrUnhide("Hide");
    }

    if(chkCnt !== 0 && chkCnt === starCaseCnt){
      setStarOrUnstar("Unstar");
    }else{
      setStarOrUnstar("Star");
    }

    if(hagueInfoResult.length === chkCnt){
      setChkAllFlag(true);
    }else{
      setChkAllFlag(false);
    }

    dispatch(changeHagueNotifier(_hagueNotifier));
  }

  const caseChk = () => {
    const _hagueNotifier = [];
    hagueInfoResult.map((row, index) =>{
      if(row.checked === true){
        _hagueNotifier.push(row);
      }
    }); 

    if(_hagueNotifier.length === 0){
      
      dispatch(setHagueResults(1));
      return false;
    }

    return true;
  }

  const getPeriod = () => {
    let searchStr = "ranking for period: ";
    let searchIdx = noticeStr.toLowerCase().indexOf(searchStr) + searchStr.length
    let period = noticeStr.substr(searchIdx).replace("\n", "");

    return period;
  }


  useEffect(() => {
    window.addEventListener('click', handleCloseHagueModal);
    if (localStorage.getItem("hagueFilterParam") && localStorage.getItem("searchHagueNameList")) {
      const hagueFilterParam = JSON.parse(localStorage.getItem("hagueFilterParam"));
      dispatch(getHagueSearchTargetGroupList({targetGroupNo: JSON.parse(localStorage.getItem("searchHagueNameList"))[0].no}));
      dispatch(setHagueSettings(hagueFilterParam));
      localStorage.removeItem("hagueFilterParam");
      localStorage.removeItem("searchHagueNameList");
    } else{
      dispatch(gethagueNotifierSettings());
    } 

    return () => {
      window.removeEventListener('click', handleCloseHagueModal);
      dispatch(changeSearchNameList([], hagueValidation.values.screenName));
      dispatch(changeSearchNameSelectedList([], hagueValidation.values.screenName));
    };
  }, [dispatch, success]);

  useEffect(() => {
    if (isHagueSearched && hagueValidation.values.searchNoList.length > 0) {
      hagueValidation.setFieldValue("submitType", "view");
      hagueValidation.handleSubmit(hagueValidation.values); 
    }
  },[hagueValidation.values.searchNoList])

  useEffect(() => {
      if (freeMemberAutosearch == 'Y') {
        setTimeout(function(){ 
          toggleModal("hagueNotifierIsOpen");
          hagueValidation.setFieldValue("submitType", "view");
          hagueValidation.handleSubmit(hagueValidation.values);
        }, 100);  
      }
      
    },[hagueValidation.values.toDate])

  

  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("");
  const [cancelBtnText, setCancelBtnText] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [confirmType, setConfirmType] = useState("");
  const [sendTarget, setSendTarget] = useState("");
  const [sendTargetGroupNo, setSendTargetGroupNo] = useState("");
  const [pdfActiveTab, setPdfActiveTab] = useState(0);
  const [columns, setColumns] = useState([
    {
      label: "Email",
      asc: null,
      desc: null,
      ascActive: null,
      descActive: null,
    },
    {
      label: "Representative",
      asc: "RIA",
      desc: "RID",
      ascActive: "",
      descActive: "",
    },
    { label: "Holder", asc: "HIA", desc: "HID", ascActive: "", descActive: "" },
    { label: "IR no", asc: "IRA", desc: "IRD", ascActive: "", descActive: "" },
    { label: "Jur", asc: "JRA", desc: "JRD", ascActive: "", descActive: "" },
    {
      //label: "Inscription\nUploaded",
      label: "Refusal\nUploaded",
      asc: "RDA",
      desc: "RDD",
      ascActive: "",
      descActive: "text-danger",
    },
    {
      label: "Design",
      asc: null,
      desc: null,
      ascActive: null,
      descActive: null,
    },
    {
      label: "WeCrest send count",
      asc: null,
      desc: null,
      ascActive: null,
      descActive: null,
    },
  ]);

  const errMsg = [
    <span>You are one small step away from being able to use the Hague Notifier. Please complete the setup process by entering your email settings <a href='/settings#subscriptionSettings'>here</a>. Once you have completed this step, you will be able to send emails using the Hague Notifier.</span>
  , "Please select a case."
  , <span>Your email is blank. Please edit your email or select a template in your <a href='/settings#emailTemplateSetting'>Settings.</a></span>
  , "Sorry, unfortunately you do not have any credits remaining."
  , "No slots remaining"
  , "Unlocking is not possible for this case. The sending limit of 10 emails has been reached already."
  , "Please select cases to unlock"
  , "Unlock Fail!"
  , "Select up to 50 cases to download a report in PDF format"
  , "Email sending is not possible for this case. The sending limit of 10 emails has been reached already."
  , "There is no contacts information for the selected case."
];
  let emailSentBgColor = "#ffa50069";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const setHagueLoading = () => {
    dispatch(onSetHagueLoading(!loading));
  };

//pagination
const [curPageInput, setCurPageInput] = useState(1);

const viewList = (sortType, curPage, pageSize) => {

  if(curPage){
    if(curPage > hagueInfoTotalPages) {
      curPage = hagueInfoTotalPages;
    } else if (curPage <= 0) {
      curPage = 1;
    }
  }

  hagueValidation.setFieldValue("submitType", "view");
 
  if(curPage){
    hagueValidation.setFieldValue("curPage", curPage);
    setCurPageInput(curPage);
  }else{
    hagueValidation.setFieldValue("curPage", 1);
    setCurPageInput(1);
  }
  if(pageSize){
    hagueValidation.setFieldValue("pageSize", pageSize);
  }
  if(sortType){
    hagueValidation.setFieldValue("sortType", sortType);
  }
  hagueValidation.handleSubmit(hagueValidation.values);
}

  // Editor
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [irNumber, setIrNumber] = useState(-1);
  const [ftpDate, setFtpDate] = useState("");
  const [crCode, setCrCode] = useState("");
  const [editedTemplateYn, setEditedTemplateYn] = useState("N");

  function openEditor(irNumber, refusalDate, crCode, holderCountry, hagueEmlTmpSetYn) {
    if (!isInitEditor) {
      setIsInitEditor(true);
    }

    localStorage.setItem("hagueReloadParam", JSON.stringify({irNumber:irNumber, refusalDate:refusalDate, crCode:crCode, inOutType:hagueValidation.values.inOutType}))

    setIrNumber(irNumber);
    setFtpDate(refusalDate);
    setCrCode(hagueValidation.values.inOutType === "IN" ? crCode : holderCountry);
    setEditedTemplateYn(hagueEmlTmpSetYn);
    setIsOpenEditor(true);
    addBodyCss();
  }

  function closeEditor() {

    const reloadParam = JSON.parse(localStorage.getItem("hagueReloadParam"));
    dispatch(getHagueNotifierInfoList([{irNumber:reloadParam.irNumber, refusalDate: reloadParam.refusalDate, jurisdiction:reloadParam.crCode}], reloadParam.inOutType));
    localStorage.removeItem("hagueReloadParam");

    setIsOpenEditor(false);
    setIrNumber(-1);

    if (editorModaRef.current) {
      editorModaRef.current.clearEditorContent();
    }

    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.remove("no_padding");
    document.body.classList.remove("modal-open");
    document.body.style.overflow = "visible";
    document.body.style.paddingRight = "";

    // console.log("removeBodyCss>>");
  }

  function addBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = "hidden";
  }

  const editorModaRef = useRef(null);
  const editorModal = useRef(null);
  const handleCloseHagueModal = (e) => {
    if (e.target.id === 'EditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }
  }

  //contact screen
  const [modalContactsScreen, setModalContactsScreen] = useState(false);
  const [contactsTargetGroupNo, setContactsTargetGroupNo] = useState(-1);
  function togContactsScreen(targetGroupNo) {
    // console.log("targetGroupNo", targetGroupNo);
    if (targetGroupNo) {
      setContactsTargetGroupNo(targetGroupNo);
      // console.log("targetNo TRUE")
    } else {
      setContactsTargetGroupNo(-1);
      
      // console.log("contactsTargetGroupNo", contactsTargetGroupNo);
      const _hagueNotifier = [];
      hagueInfoResult.map((row, index) => {
        row.holderList.map((holder, holIndex) => {
          if (
            row.representativeGroupNo == contactsTargetGroupNo ||
            holder.holderGroupNo == contactsTargetGroupNo
          ) {
            _hagueNotifier.push(row);
          }
        });
        
      });
      dispatch(getHagueNotifierInfoList(_hagueNotifier, hagueValidation.values.inOutType));
    }
    setModalContactsScreen(!modalContactsScreen);
  }
  
  const [modals, setModals] = useState({
    hagueNotifierIsOpen: true,
    jurisdictionsModal: false,
    countryModal: false,
    repCountryModal: false,
    searchNameModal: false,
  });

  const toggleModal = (modalKey) => {
    setModals((prevState) => ({
      ...prevState,
      [modalKey]: !prevState[modalKey],
    }));
  };

  function showSuccessCopyToast() {
    const ele = "success";
    const position = "toast-bottom-left";
    const title = "";
    let message = "Copy clipboard!";

    //Close Button
    const closeButton = false;

    //Debug
    const debug = false;

    //Progressbar
    const progressBar = false;

    //Duplicates
    const preventDuplicates = false;

    //Newest on Top
    const newestOnTop = false;

    //position class
    let positionClass = "toast-bottom-left";

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title);
  }

  const unsecuredCopyToClipboard = (text, elmentId) => {
    const textArea = document.createElement("textarea");
    textArea.setAttribute("type", "hidden");
    textArea.value = text;

    // const node = document.getElementById(elmentId).lastElementChild;
    document.getElementById(elmentId).appendChild(textArea);
    // document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.getElementById(elmentId).removeChild(textArea);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <React.Fragment>
      <Form onSubmit={hagueValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Card>
              <Link
                onClick={() => toggleModal("hagueNotifierIsOpen")}
                className="text-dark"
                to="#"
              >
                <div className="p-4">
                  <Media className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <i className="uil uil-pen text-primary h2"></i>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="font-size-16 mb-1">Hague Notifier</h5>
                      <p className="text-muted mb-0">
                        Use this tool to notify representatives or holders of
                        international (Hague) design applications that have been
                        provisional refused in your jurisdictions.
                      </p>
                    </div>
                    <i className="uil uil-filter fonts-size-24 text-muted"></i>
                  </Media>
                </div>
              </Link>
              <Collapse isOpen={modals.hagueNotifierIsOpen}>
                <div className="p-4 border-top">
                  <div>
                    <Row className="mb-3">
                      <div
                        className="col-md-3 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inOutType"
                          id="showInboud"
                          value="IN"
                          checked={hagueValidation.values.inOutType === 'IN'}
                          onChange={()=>{
                            hagueValidation.setFieldValue("inOutType","IN")
                            if (hagueSettings && hagueSettings.inOutType && hagueSettings.inOutType === "IN") {
                              hagueValidation.setFieldValue("jrCdList", hagueSettings && hagueSettings.jurList? hagueSettings.jurList : []);
                              hagueValidation.setFieldValue("holderCdList", hagueSettings && hagueSettings.holderCdList? hagueSettings.holderCdList : []);
                              hagueValidation.setFieldValue("repCdList", hagueSettings && hagueSettings.repCdList? hagueSettings.repCdList : []);
                            } else {
                              hagueValidation.setFieldValue("jrCdList", []);
                              hagueValidation.setFieldValue("holderCdList", []);
                              hagueValidation.setFieldValue("repCdList", []);
                            }
                          }}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="showInboud"
                        >
                          Inbound
                        </label>
                      </div>
                      <div
                        className="col-md-3 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inOutType"
                          id="showOutbound"
                          value="OUT"
                          checked={hagueValidation.values.inOutType === 'OUT'}
                          onChange={()=>{
                            hagueValidation.setFieldValue("inOutType","OUT")
                            if (hagueSettings && hagueSettings.inOutType && hagueSettings.inOutType === "OUT") {
                              console.log("IF")
                              hagueValidation.setFieldValue("jrCdList", hagueSettings && hagueSettings.jurList? hagueSettings.jurList : []);
                              hagueValidation.setFieldValue("holderCdList", hagueSettings && hagueSettings.holderCdList? hagueSettings.holderCdList : []);
                              hagueValidation.setFieldValue("repCdList", hagueSettings && hagueSettings.repCdList? hagueSettings.repCdList : []);
                            } else {
                              hagueValidation.setFieldValue("jrCdList", []);
                              hagueValidation.setFieldValue("holderCdList", []);
                              hagueValidation.setFieldValue("repCdList", []);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showOutbound"
                        >
                          {" "}
                          Outbound
                        </label>
                      </div>
                    </Row>
                    <SelectMultipleJurisdictions
                        formik={hagueValidation}
                        subscriptionType="HN"
                      />
                      <SelectMultipleCountry
                        formik={hagueValidation}
                        componentName='Holder'
                        subscriptionType="HN"
                      />
                    <SelectCountry
                        formik={hagueValidation}
                        componentName='Representative'
                      />
                    <SearchName
                        formik={hagueValidation}
                      />
                    <Row className="mb-3">
                      <label
                        htmlFor="irNumber"
                        className="col-md-3 col-form-label"
                      >
                        IR number
                      </label>
                      <div className="col-md-3">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Enter IR number"
                          id="irNumber"
                          name="irNumber"
                          value={hagueValidation.values.irNumber}
                          onChange={hagueValidation.handleChange}
                          onKeyPress={(e) => {
                            if(e.key === "Enter"){
                              hagueValidation.setFieldValue("submitType","view")
                              hagueValidation.handleSubmit(hagueValidation.values)
                            }
                          }}
                        />
                      </div>
                    </Row>
                    <Row>
                      <label
                        htmlFor="inscribedDate"
                        className="col-md-3 col-form-label"
                        style={{ paddingTop: "0px" }}
                      >
                        <select
                          className="form-select"
                          id="dateType"
                          name="dateType"
                          style={{ width: "50%" }}
                          onChange={hagueValidation.handleChange}
                          value={hagueValidation.values.dateType}
                        >
                          <option value="Inscribed">Refusal date</option>
                          <option value="Uploaded">Uploaded date</option>
                        </select>
                      </label>
                      <div className="col-md-9">
                          <PeriodBtn
                            formik={hagueValidation}
                          />
                        </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="entityStatus"
                        className="col-md-3 col-form-label"
                      >
                        Labels
                      </label>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="onlyNonHidden"
                          name="showHiddenCase"
                          checked={hagueValidation.values.showHiddenCase === "ONH"}
                          value="ONH"
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="onlyNonHidden"
                        >
                          Only non-hidden
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="onlyStarred"
                          name="showHiddenCase"
                          checked={hagueValidation.values.showHiddenCase === "OS"}
                          value="OS"
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="onlyStarred"
                        >
                          Only starred
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="onlyHidden"
                          name="showHiddenCase"
                          value="OH"
                          checked={hagueValidation.values.showHiddenCase === "OH"}
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="onlyHidden"
                        >
                          Only hidden
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="entityStatusAll"
                          name="showHiddenCase"
                          checked={hagueValidation.values.showHiddenCase === "ALL"}
                          value="ALL"
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="entityStatusAll"
                        >
                          All
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="caseHasRepresentative"
                        className="col-md-3 col-form-label"
                      >
                        Representative
                      </label>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasRepresentative"
                          id="hasRepBoth"
                          value="BOTH"
                          onChange={hagueValidation.handleChange}
                          checked={
                            hagueValidation.values.hasRepresentative === "BOTH"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="hasRepBoth"
                        >
                          All
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasRepresentative"
                          id="hasRepY"
                          value="Y"
                          onChange={hagueValidation.handleChange}
                          checked={
                            hagueValidation.values.hasRepresentative === 'Y'
                          }
                        />
                        <label className="form-check-label" htmlFor="hasRepY">
                          Only with representative
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasRepresentative"
                          id="hasRepN"
                          value="N"
                          onChange={hagueValidation.handleChange}
                          checked={
                            hagueValidation.values.hasRepresentative === 'N'
                          }
                        />
                        <label className="form-check-label" htmlFor="hasRepN">
                          Only without representative
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="emailSent"
                        className="col-md-3 col-form-label"
                      >
                        Email sending
                      </label>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="emailSent"
                          id="emailSentN"
                          checked={hagueValidation.values.emailSent === 'N'}
                          value="N"
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="emailSentN"
                        >
                          Only not sent
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="emailSent"
                          id="emailSentY"
                          checked={hagueValidation.values.emailSent === 'Y'}
                          value="Y"
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="emailSentY"
                        >
                          Only sent
                        </label>
                      </div>
                      <div
                        className="col-md-2 form-check"
                        style={{ paddingLeft: "2em" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="emailSent"
                          id="emailSentBoth"
                          checked={hagueValidation.values.emailSent === 'ALL'}
                          value="ALL"
                          onChange={hagueValidation.handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="emailSentBoth"
                        >
                          All
                        </label>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <span className="col-md-6 button-items">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          onClick={()=>{
                            hagueValidation.setFieldValue("submitType", "saveSettings")
                            hagueValidation.handleSubmit(hagueValidation.values)
                          }}
                        >
                          Save settings
                        </Button>
                        {userGrade === "02" ?
                        <>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          id="btn_down_excel_1_1"
                          onClick={() => {
                            hagueValidation.setFieldValue("submitType", "Excel")
                            hagueValidation.handleSubmit(hagueValidation.values)
                          }}
                        >
                          Download Excel
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target="btn_down_excel_1_1"
                        >
                          Download details of all cases based on the filter as an Excel file limit 1,000 rows
                        </UncontrolledTooltip>
                        </>
                        :      
                        <>
                        <Button
                        type="button"
                        color="disable"
                        outline
                        className="waves-effect waves-light"
                        id="btn_down_excel_1">
                          Download Excel
                        </Button>
                        <UncontrolledTooltip placement="top" target="btn_down_excel_1">
                        Contact us at global@wecrest.com to access all features.
                        </UncontrolledTooltip>
                        </>
                        }
                      </span>
                      <Col md={3} style={{ paddingLeft: "1.3em" }}>
                        <button
                          type="button"
                          className="waves-effect waves-light btn btn-primary"
                          style={{ width: "148.58px" }}
                          onClick={
                            ()=>{
                              hagueValidation.setFieldValue("submitType", "view")
                              hagueValidation.setFieldValue("curPage", 1)
                              setCurPageInput(1)
                              hagueValidation.handleSubmit(hagueValidation.values)
                            }
                          }
                        >
                          View cases
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Collapse>
            </Card>
            {loading ? (
              <LoadingModal
                loading={loading}
                setLoading={setHagueLoading}
              ></LoadingModal>
            ) : null}

            {sentSuccess? (
              <SweetAlert
                title={success}
                showCloseButton={false}
                success
                onConfirm={() => {
                  dispatch(setHagueSentSuccess(""));
                }}
              >
                <div style={{textAlign: 'left'}}>
                  <div style={{marginBottom:'1rem'}}>Emails have only been sent for entities with enabled contacts.</div>
                  <div><label className='text-success' style={{marginBottom:'1rem'}}>Sent: {numberWithCommas(sentCnt)} {sentCnt > 1 ? "emails" : "email"}</label></div>
                  {disableEmails.length > 0 || unsubsEmails.length > 0 || sentIn24Emails.length > 0 ?
                  <>
                  <div className='text-danger'>
                  <label>Not sent</label><br/>
                  <ul>
                  {disableEmails.length > 0 ? <li>{numberWithCommas(disableEmails.length)} disabled {disableEmails.length > 1 ? "emails" : "email"}</li> : null}
                  {unsubsEmails.length > 0 ? <li>{numberWithCommas(unsubsEmails.length)} unsubscribed {unsubsEmails.length > 1 ? "emails" : "email"}</li> : null}
                  {sentIn24Emails.length > 0 ? <li>{numberWithCommas(sentIn24Emails.length)} {sentIn24Emails.length > 1 ? "emails" : "email"} sent within 24 hours </li> : null}
                  </ul>
                  </div>
                  </>
                  : null}
                  {disableEmails.length?
                  <>
                  <div>
                  <label>Disabled</label><br/>
                  <ul>
                  {disableEmails.map((emailAddress, index) => {
                    return (
                      <li>{emailAddress}</li>
                    )
                  })}
                  </ul>
                  </div>
                  </>
                  : null}
                  {unsubsEmails.length?
                  <>
                  <div>
                  <label>Unsubscribed</label><br/>
                  <ul>
                  {unsubsEmails.map((emailAddress, index) => {
                    return (
                      <li>{emailAddress}</li>
                    )
                  })}
                  </ul>
                  </div>
                  </>
                  : null}
                </div>
              </SweetAlert>
            ) : null}

            {success ? (
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(setHagueSuccess(""));
                }}
              ></SweetAlert>
            ) : null}

            {result !== -1 ? (
              <SweetAlert
                onConfirm={() => {
                  dispatch(setHagueResults(-1));
                }}
                title={errMsg[result]}
              >
                {result === 3 ? "Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday." : null}
              </SweetAlert>
            ) : null}

            {alertMsg !== "" ? (
              <SweetAlert
                onConfirm={() => {
                  setAlertMsg("");
                }}
                onCancel={() => {
                  setAlertMsg("");
                }}
              >
                {alertMsg}
              </SweetAlert>
            ) : null}

            {confirmAlert ? (
              <SweetAlert
                showCancel
                confirmBtnText={confirmBtnText}
                cancelBtnText={cancelBtnText}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  // console.log("confirm type: " + confirmType)
                  hagueValidation.setFieldValue("submitType", confirmType);
                  hagueValidation.handleSubmit(hagueValidation.values);
                  setConfirmAlert(false);
                }}
                onCancel={() => setConfirmAlert(false)}
              >
                {confirmMsg}
                {
                  confirmType === 'multipleSend'?
                    <div className="d-flex gap-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoSendTarget"
                          id="rdoSendTargetRep"
                          onClick={()=>{
                            setSendTarget("REP");
                            setSendTargetGroupNo(0);
                          }}
                          value="REP"
                          checked={sendTarget === 'REP'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rdoSendTargetRep"
                        >
                          Representatives
                        </label>
                      </div>
                      <div className="form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoSendTarget"
                          id="rdoSendTargetHol"
                          onClick={()=>{
                            setSendTarget("HOL");
                            setSendTargetGroupNo(0);
                          }}
                          value="HOL"
                          checked={sendTarget === 'HOL'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rdoSendTargetHol"
                        >
                          Holders
                        </label>
                      </div>
                    </div>
                  :null
                }
              </SweetAlert>
            ) : null}

            {upgradeAlertMessage.title != "" ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Upgrade"
                cancelBtnText="No thanks"
                confirmBtnBsStyle="wcdark"
                cancelBtnBsStyle="secondary"
                onConfirm={() => {
                  window.location.href = "/membershipPlan";
                }}
                onCancel={() => {
                  dispatch(setUpgradeAlertMessage({title:"",text:""}))
                }}
                title={upgradeAlertMessage.title}
              >
                {upgradeAlertMessage.text}
              </SweetAlert>
            ) : null}

            <Modal
              size="xl"
              style={{ maxWidth: "1700px" }}
              isOpen={modalContactsScreen}
              toggle={() => {
                togContactsScreen();
              }}
            >
              <div className="modal-body">
                <button
                  onClick={() => {
                    togContactsScreen();
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <ContactsDetails targetGroupNo={contactsTargetGroupNo} />
              </div>
            </Modal>

            {previewEmail && Object.keys(previewEmail).length !== 0 ? (
              <Modal
                size="xl"
                isOpen={true}
                toggle={() => {
                  dispatch(setPreviewHagueEmail({}));
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                    {previewEmail.toEmail ? previewEmail.toEmail : null}
                    <br />
                    CC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                    {previewEmail.emailCC ? previewEmail.emailCC : null}
                    <br />
                    BCC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                    {previewEmail.emailBCC ? previewEmail.emailBCC : null}
                    <br />
                    Subject:{" "}
                    {previewEmail.subject ? previewEmail.subject : null}
                  </h5>
                  <button
                    onClick={() => {
                      // console.log("close preview!");
                      dispatch(setPreviewHagueEmail({}));
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Card className="mb-2">
                    <CardBody
                      style={{ overflow: "scroll", height: "500px" }}
                      dangerouslySetInnerHTML={{
                        __html: previewEmail.emailForm
                          ? previewEmail.emailForm
                          : null,
                      }}
                    ></CardBody>
                  </Card>
                  <Card className={previewPdfList && previewPdfList.length>0 ? "mb-2" : "mb-0"}>
                    <CardBody className="p-1">
                      <Table className="table mb-0">
                        <tbody>
                          <tr>
                            <td>
                              {previewPdfList.length >0 ? (
                                <>
                                  {/* <a
                                    href={previewEmail.pdfStorageUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="uil uil-google-drive text-primary font-size-22"></i>
                                    Google drive
                                  </a> */}
                                  &nbsp;
                                  {previewEmail.pdfStorageUrl.split(',').map((pdf, index) => {
                                    return (
                                      <a
                                        href={pdf}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i className="mdi mdi-file-pdf text-danger font-size-22"></i>
                                      </a>
                                    );
                                  })}
                                </>
                              ) : (
                                <a
                                  href={previewEmail.pdfStorageUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="mdi mdi-file-pdf text-danger font-size-22"></i>
                                  {previewEmail.irNumber}
                                </a>
                              )}
                            </td>
                          </tr>
                          {previewEmail.attachFiles &&
                          previewEmail.attachFiles.length
                            ? previewEmail.attachFiles.map((attach, index) => {
                                return (
                                  <tr>
                                    <td>{attach.fileName}</td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>

                  {previewPdfList.length>0 ? (
                    <Card className="mb-0">
                      <CardBody
                        className="p-1 d-flex horizon-align-center"
                      >
                        <Col>
                        <Row>
                        <Nav tabs className="d-flex flex-row">
                          {previewPdfList.map((pdf, index) => {
                            return (
                              <NavItem key={index}>
                                <NavLink className={pdfActiveTab === index ? "nav-link active" : "nav-link"} style={{cursor: "pointer"}}
                                  onClick={() => {
                                    setPdfActiveTab(index);
                                    // console.log("pdfActiveTab: " + pdfActiveTab);
                                  }}
                                >
                                  Design No. {index + 1}
                                </NavLink>
                              </NavItem>
                                  );
                                })}
                              </Nav>
                              </Row>
                              <TabContent 
                                className="p-1 d-flex horizon-align-center"
                                style={{ overflow: "scroll", height: "500px" }}
                                activeTab={pdfActiveTab}
                              >
                                {previewPdfList.map((pdf, index) => {
                                  return (
                                    <TabPane key={index} tabId={index}>
                                      {pdfActiveTab === index && ( 
                                        <PDFDocumentWrapper>
                                          <Document
                                            file={pdf}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                          >
                                            {Array.from({ length: numPages }, (_, i) => (
                                              <Page key={i} pageNumber={i + 1} />
                                            ))}
                                          </Document>
                                        </PDFDocumentWrapper>
                                      )}
                                    </TabPane>
                                  );
                                })}
                              </TabContent>
                              </Col>
                      </CardBody>
                    </Card>
                  ) : null}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      // console.log("close preview!");
                      dispatch(setPreviewHagueEmail({}));
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            ) : null}

            <EditorModal
              isOpenEditor={isOpenEditor}
              isInitEditor={isInitEditor}
              closeEditor={closeEditor}
              loadedEditor={loadedEditor}
              setLoadedEditor={setLoadedEditor}
              editorModal={editorModal}
              ref={editorModaRef}
              emailType="HN"
              irNumber={irNumber}
              ftpDate={ftpDate}
              crCode={crCode}
              editedTemplateYn={editedTemplateYn}
              inOutType={hagueValidation.values.inOutType}
            />

            {noticeStr && noticeStr !== '' ? 
            <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="text-muted mb-0">
                    <p style={{ whiteSpace: "pre" }}>{noticeStr}</p>
                    <Row className="mb-3">
                      <span className="col-md-8 button-items">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          id="btn_unlock"
                          onClick={() => {
                            if(caseChk()){
                              setConfirmType("multipleUnlock");
                              setConfirmMsg(<>
                                Unlock to view the details and send emails for this case.<br/>
                                You cannot unlock a case, if sending limit of 10 emails per case has been reached already.
                                </>);
                              setConfirmBtnText("Confirm");
                              setCancelBtnText("Cancel");
                              setConfirmAlert(true);
                            }
                          }}                          
                        >
                          Unlock
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target="btn_unlock"
                        >
                          Unlock selected cases
                        </UncontrolledTooltip>

                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          id="btn_send"
                          onClick={() => {
                            if(caseChk()){
                              setConfirmType("multipleSend")
                              setConfirmMsg(
                                <>
                                <div className="mb-3" style={{textAlign: "left"}}>
                                  Emails will only be sent for entities, for which
                                  <ul>
                                    <li>email address has been entered</li>
                                    <li>email subject and body are not empty</li>
                                    <li>sending limit of 10 emails per case has not been reached</li>
                                    <li>at least one saved email address has not been contacted within the past 24 hours</li>
                                  </ul>
                                  If "Holders" is selected and multiple holders exist for a case, then emails will be sent to ALL holders, for which an email address has been entered. If this is not your intention, please cancel and process emails one by one.
                                </div>
                                <div className="mb-3" style={{textAlign: "left"}}>Send emails to the selected</div>
                                </>
                              );
                              setConfirmBtnText("Yes, send!");
                              setCancelBtnText("Cancel");
                              setSendTarget("REP");
                              setSendTargetGroupNo(0);
                              setConfirmAlert(true);
                            }
                          }}
                        >
                          Send
                        </Button>
                        <UncontrolledTooltip placement="top" target="btn_send">
                          Send emails for selected cases
                        </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              id="btn_starOrUnstar"
                              onClick={() => {
                                if(caseChk()){
                                  setConfirmType(starOrUnstar);
                                  setConfirmMsg(
                                    "Are you sure you want to " + starOrUnstar.toLowerCase()+ " all checked cases?"
                                  );
                                  setConfirmBtnText("Yes, " + starOrUnstar.toLowerCase());
                                  setCancelBtnText("Cancel");
                                  setConfirmAlert(true);
                                }
                              }}
                            >
                              {starOrUnstar}
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target="btn_starOrUnstar"
                            >
                              {starOrUnstar} selected cases
                            </UncontrolledTooltip>
                          
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              id="btn_hideOrUnhide"
                              onClick={() => {
                                if(caseChk()){
                                  setConfirmType(hideOrUnhide);
                                  setConfirmMsg(
                                    "Are you sure you want to " + hideOrUnhide.toLowerCase()+ " all checked cases?"
                                  );
                                  setConfirmBtnText("Yes, " + hideOrUnhide.toLowerCase());
                                  setCancelBtnText("Cancel");
                                  setConfirmAlert(true);
                                }
                              }}
                            >
                             {hideOrUnhide}
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target="btn_hideOrUnhide"
                            >
                              {hideOrUnhide} selected cases
                            </UncontrolledTooltip>
                        {userGrade === "02" ?
                        <>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          id="btn_down_excel_2_1"
                          onClick={() => {
                            if(caseChk()){
                              hagueValidation.setFieldValue("submitType", "multipleExcel")
                              hagueValidation.handleSubmit(hagueValidation.values)
                            }
                          }}
                        >
                          Download Excel
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target="btn_down_excel_2_1"
                        >
                          Download details of selected cases as an Excel file
                        </UncontrolledTooltip>
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="waves-effect waves-light"
                          id="btn_down_pdf_2_1"
                          onClick={() => {
                            // console.log("pdf working")
                           if(caseChk()){
                              hagueValidation.setFieldValue("submitType", "multiplePdf")
                              hagueValidation.handleSubmit(hagueValidation.values)
                           }
                          }}
                        >
                          Download PDF
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target="btn_down_pdf_2_1"
                        >
                          Download details of selected cases as a PDF file
                        </UncontrolledTooltip>
                        </>
                        :
                        <>
                        <Button
                        type="button"
                        color="disable"
                        outline
                        className="waves-effect waves-light"
                        id="btn_down_excel_2_2" >
                        Download Excel
                        </Button>
                        <UncontrolledTooltip placement="top" target="btn_down_excel_2_2">
                        Contact us at global@wecrest.com to access all features.
                        </UncontrolledTooltip>
                        <Button
                          type="button"
                          color="disable"
                          outline
                          className="waves-effect waves-light"
                          id="btn_down_pdf_2_2"
                        >
                          Download PDF
                        </Button>
                        <UncontrolledTooltip placement="top" target="btn_down_pdf_2_2">
                        Contact us at global@wecrest.com to access all features.
                        </UncontrolledTooltip>
                        </>
                      }                 
                      </span>
                    </Row>
                  </CardTitle>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th style={{ verticalAlign: "middle" }}>
                            <input type="checkbox" checked={chkAllFlag} onClick={(e)=>rowCheck("chkAll", e.target.checked)}
                            />
                          </th>
                          {columns && columns.length
                            ? columns.map((col, index) => {
                                return (
                                  <th
                                    style={{
                                      position: "relative",
                                      whiteSpace: "pre-line",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {col.label == "WeCrest send count" ? (
                                      <>
                                        WeCrest send count{" "}
                                        <i
                                          className="uil uil-question-circle font-size-18"
                                          id={"WeCrest_send_count"}
                                        ></i>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={"WeCrest_send_count"}
                                        >
                                          Number of WeCrest members who sent
                                          emails from WeCrest for this case. No
                                          more than ten members can send an
                                          email for the same case.
                                          <br />
                                          Green: 0 email sent,
                                          <br />
                                          Blue: 1-4 emails sent,
                                          <br />
                                          Orange: 5-9 emails sent,
                                          <br />
                                          Red: 10 emails sent
                                        </UncontrolledTooltip>
                                      </>
                                    ) 
                                    :
                                    col.label =="IR no" ?
                                      <>
                                      {col.label}{""}
                                      <br/>
                                      <i
                                        className="uil uil-question-circle font-size-18"
                                        id={"IR_no"}
                                      ></i>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"IR_no"}
                                      >
                                        If there are more than three refusal files, only the first refusal file will be shown as a sample.
                                        To view all refusal files, please navigate to Preview.
                                      </UncontrolledTooltip>
                                      </>
                                    : (
                                      col.label
                                    )}
                                    {col.asc && col.desc ? (
                                      col.label == "IR no" ?
                                      <>
                                      <div style={{marginLeft: '2.5rem'}}>
                                        <Link to='#' onClick={()=> {viewHagueNotifier(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                        <Link to='#' onClick={()=> {viewHagueNotifier(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link> 
                                      </div>
                                      </>
                                      :
                                      <>
                                        <Link to='#' onClick={()=> {viewHagueNotifier(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                        <Link to='#' onClick={()=> {viewHagueNotifier(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link> 
                                      </>
                                    ) : null}
                                  </th>
                                );
                              })
                            : null}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(hagueInfoResult) &&
                        hagueInfoResult.length > 0 ? (
                          hagueInfoResult.map((hagueInfo, index) => {
                            return hagueInfo.holderList.map(
                              (holderInfo, holderIdx) => {
                                return holderIdx === 0 ? (
                                  <tr
                                  key={index}
                                  style={{backgroundColor:
                                    hagueInfo.lastContactDate !== "0000-00-00" ? emailSentBgColor : null}}
                                  >
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      <input
                                        type="checkbox"
                                        onClick={(e)=>rowCheck(index, e.target.checked)}
                                        checked={hagueInfo.checked}
                                      />
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      align="center"
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      <Row>
                                        <div className="d-flex vertical-align-center horizon-align-center gap-2">
                                          {
                                            hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y' ?
                                            <>
                                            <Link
                                            to="#"
                                            onClick={() => {
                                              if(hagueInfo.wecrestSendCount<10 || ((hagueInfo.premSubsYn=='Y' && hagueInfo.lastContactDate!== '0000-00-00') || hagueInfo.unlockYn=='Y')){
                                                openEditor(hagueInfo.irNumber, hagueInfo.refusalDate, hagueInfo.jurisdiction,holderInfo.holderCountry, hagueInfo.hagueEmlTmpSetYn)
                                              }
                                            }}
                                          >
                                            <i className={
                                                  hagueInfo.wecrestSendCount >= 10 && hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate == '0000-00-00' ? 
                                                  "uil uil-envelope-edit font-size-22 text-danger" 
                                                  : hagueInfo.hagueEmlTmpSetYn == 'Y' ?
                                                    "uil uil-envelope-edit font-size-22 text-success"
                                                    : "uil uil-envelope-edit font-size-22 text-warning"
                                                  } id={"editEmail_"+index}></i>
                                          <UncontrolledTooltip placement="top" target={"editEmail_"+index}>
                                            {hagueInfo.hagueEmlTmpSetYn == 'Y' ? "Edited template" : "Default template"}
                                          </UncontrolledTooltip>
                                          </Link>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                             if(hagueInfo.wecrestSendCount<10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y')){
                                              if((hagueInfo.exceptJurSetYn == 'Y' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.exceptJurEmlTmpSetYn == 'Y')&& hagueInfo.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                || (hagueInfo.exceptJurSetYn == 'N' && (hagueInfo.hagueEmlTmpSetYn == 'Y' ||hagueInfo.defaultJurEmlTmpSetYn == 'Y') && hagueInfo.defaultJurSendEmailConfirmStatus =='Confirmed')){
                                                  // console.log("preview email");
                                                  let period = getPeriod();
                                                  dispatch(previewHagueEmail({hagueNotifierList:[hagueInfo], period:period, inOutType:hagueValidation.values.inOutType}))
                                                }else{
                                                  setAlertMsg(<span>Preview is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>);
                                                }
                                             }
                                            }}
                                          >
                                            <i
                                              className={
                                                hagueInfo.wecrestSendCount <
                                                  10 ||
                                                (hagueInfo.premSubsYn == "Y" &&
                                                  hagueInfo.lastContactDate !==
                                                    "0000-00-00") ||
                                                hagueInfo.unlockYn == "Y"
                                                  ? "uil uil-file-search-alt font-size-22"
                                                  : "uil uil-file-search-alt font-size-22 text-danger"
                                              }
                                              id={"preview_" + index}
                                            ></i>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={"preview_" + index}
                                            >
                                              Preview
                                            </UncontrolledTooltip>
                                          </Link>
                                            </>
                                            :
                                            <Link to="#" className={unlockCredit - unlockCnt > 0 ? hagueInfo.wecrestSendCount == 0 ? "text-success" : hagueInfo.wecrestSendCount < 5 ? "text-primary" : hagueInfo.wecrestSendCount < 10 ? "text-warning" : "text-danger" : "text-danger"}
                                                onClick={()=>{
                                                  if (unlockCredit - unlockCnt > 0) {
                                                    if (hagueInfo.wecrestSendCount < 10) {
                                                      setConfirmType("unlock_"+index);
                                                      setConfirmMsg(<>
                                                        Unlock to view the details and send emails for this case.<br/>
                                                        You cannot unlock a case, if sending limit of 10 emails per case has been reached already.
                                                        </>);
                                                      setConfirmBtnText("Confirm");
                                                      setCancelBtnText("Cancel");
                                                      setConfirmAlert(true);
                                                    }
                                                  } else {
                                                    dispatch(setUpgradeAlertMessage({title:"Sorry, unfortunately you do not have any credits remaining.",text:"Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday."}))
                                                  }
                                                }}>
                                                <i className={"uil uil-lock-alt font-size-22"} id={"unlock_"+index}></i>
                                                <UncontrolledTooltip placement="top" target={"unlock_"+index}>
                                                  {unlockCredit - unlockCnt > 0 ? 
                                                    hagueInfo.wecrestSendCount >= 10 ? 
                                                      errMsg[5] 
                                                    : 
                                                      hagueInfo.wecrestSendCount >= 5 ? 
                                                        <>Heads up! 5 or more WeCrest members already sent an email for this case. You can go ahead and unlock or click cancel and find a green or blue case.<br/>Green: 0 email sent,<br/>Blue: 1-4 emails sent,<br/>Orange: 5-9 emails sent,<br/>Red: 10 emails sent</> 
                                                      :
                                                        "Unlock to view the details and send emails for this case." 
                                                  : errMsg[3]}
                                                </UncontrolledTooltip>
                                              </Link>
                                          
                                          }
                                        </div>
                                      </Row>
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      {hagueInfo.representativeName ? (
                                        <>
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                              <p
                                                className="multiline"
                                                id={"rep_info_" + index}
                                              >
                                                {hagueInfo.representativeCountry
                                                  ? `[${hagueInfo.representativeCountry}]`
                                                  : null}{" "}
                                                {hagueInfo.representativeName}{" "}
                                                {
                                                  hagueInfo.representativeAddress
                                                }
                                              </p>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={"rep_info_" + index}
                                              >
                                                {hagueInfo.representativeCountry
                                                  ? `[${hagueInfo.representativeCountry}]`
                                                  : null}{" "}
                                                {hagueInfo.representativeName}{" "}
                                                {
                                                  hagueInfo.representativeAddress
                                                }
                                              </UncontrolledTooltip>
                                              <Link
                                                to="#"
                                                id={"rep_copy_" + index}
                                                onClick={() => {
                                                  const repInfo = `${
                                                    hagueInfo.representativeCountry
                                                      ? `[${hagueInfo.representativeCountry}]`
                                                      : ""
                                                  } ${
                                                    hagueInfo.representativeName
                                                  } ${
                                                    hagueInfo.representativeAddress
                                                  }`;

                                                  if (
                                                    window.isSecureContext &&
                                                    navigator.clipboard
                                                  ) {
                                                    navigator.clipboard.writeText(
                                                      repInfo
                                                    );
                                                  } else {
                                                    unsecuredCopyToClipboard(
                                                      repInfo,
                                                      "rep_copy_" + index
                                                    );
                                                  }
                                                  showSuccessCopyToast();
                                                }}
                                              >
                                                <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                              </Link>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={"rep_copy_" + index}
                                              >
                                                Copy Representative's
                                                information to clipboard
                                              </UncontrolledTooltip>
                                            </div>
                                          </Row>
                                          {hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y' ?
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                              <Link
                                                to="#"
                                                onClick={() => {
                                                  togContactsScreen(
                                                    hagueInfo.representativeGroupNo
                                                  );
                                                }}
                                                data-toggle="modal"
                                                className={
                                                  hagueInfo.repDbStatus ===
                                                  "upToDate"
                                                    ? "text-success"
                                                    : hagueInfo.repMemberConfirmEmlYn ===
                                                      "Y"
                                                    ? "text-primary"
                                                    : hagueInfo.repSuggestEmlYn ===
                                                      "Y"
                                                    ? "text-warning"
                                                    : "text-danger"
                                                }
                                              >
                                                <i
                                                  className="uil uil-at font-size-22"
                                                  id={"contacts_rep_" + index}
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "contacts_rep_" + index
                                                  }
                                                >
                                                  {hagueInfo.repDbStatus ===
                                                  "notUpToDate"
                                                    ? "Representative's email address(es) haven't been updated in a while and may be out of date!"
                                                    : hagueInfo.repMemberConfirmEmlYn ===
                                                      "Y"
                                                    ? "Contacts of representative with member confirmations"
                                                    : hagueInfo.repSuggestEmlYn ===
                                                      "Y"
                                                    ? "Contacts of representative with WeCrest suggestions"
                                                    : "Contacts of representative"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link
                                                to="#"
                                                className={
                                                  hagueInfo.repStarYn === "Y"
                                                    ? "text-warning"
                                                    : "text-black-50"
                                                }
                                                onClick={() => {
                                                  const _hagueNotifier = []

                                                  if(hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y'){
                                                    _hagueNotifier.push(hagueInfo)
                                                  }
                                                  
                                                  if (
                                                    hagueInfo.repStarYn === "Y"
                                                  ) {
                                                    dispatch(
                                                      unstarHagueTarget(hagueInfo.representativeGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  } else {
                                                    dispatch(
                                                      starHagueTarget(hagueInfo.representativeGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className="uil uil-star font-size-22"
                                                  id={"Starred_rep_" + index}
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "Starred_rep_" + index
                                                  }
                                                >
                                                  {hagueInfo.repStarYn === "Y"
                                                    ? "Remove from starred"
                                                    : "Star representative"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link
                                                to="#"
                                                className={
                                                  hagueInfo.repHideYn === "Y"
                                                    ? "text-danger"
                                                    : "text-black-50"
                                                }
                                                onClick={() => {
                                                  const _hagueNotifier = []

                                                  if(hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y'){
                                                    _hagueNotifier.push(hagueInfo)
                                                  }
                                                  if (
                                                    hagueInfo.repHideYn === "Y"
                                                  ) {
                                                    dispatch(
                                                      unhideHagueTarget(hagueInfo.representativeGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  } else {
                                                    dispatch(
                                                      hideHagueTarget(hagueInfo.representativeGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className="uil uil-eye-slash font-size-22"
                                                  id={"Hidden_rep_" + index}
                                                ></i>
                                                <UncontrolledTooltip
                                                  position="top"
                                                  target={"Hidden_rep_" + index}
                                                >
                                                  {hagueInfo.repHideYn === "Y"
                                                    ? "Unhide representative"
                                                    : "Hide representative"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link to="#" className="text-black-50"
                                                onClick={()=>{
                                                if (hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y'))  {
                                                  if (hagueInfo.repDbStatus === "upToDate") {
                                                    if (hagueInfo.senderEmailConfirmStatus === '' || hagueInfo.senderEmailConfirmStatus === 'pending') {
                                                      setAlertMsg(errMsg[0]);
                                                    } else {
                                                      if ((hagueInfo.exceptJurSetYn == 'Y' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.exceptJurEmlTmpSetYn == 'Y') && hagueInfo.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                        || (hagueInfo.exceptJurSetYn == 'N' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.defaultJurEmlTmpSetYn == 'Y') && hagueInfo.defaultJurSendEmailConfirmStatus == 'Confirmed')) {
                                                        setConfirmType("send_"+index);
                                                        setSendTarget("REP");
                                                        setSendTargetGroupNo(0);
                                                        setConfirmMsg("Are you sure you want to send this email?");
                                                        setConfirmBtnText("Yes, send!");
                                                        setCancelBtnText("Cancel");
                                                        setConfirmAlert(true);
                                                      } else {
                                                        setAlertMsg(<span>Send is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>settings</a>.</span>);
                                                      }
                                                    }
                                                    }else {
                                                      togContactsScreen(hagueInfo.representativeGroupNo)
                                                    }
                                                }
                                                }}
                                              >
                                              <i className={hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y') ? "uil uil-message font-size-22" : "uil uil-message font-size-22 text-danger"} id={"send_rep_"+index}></i>
                                              <UncontrolledTooltip placement="top" target={"send_rep_"+index}>
                                                {
                                                hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y') ? 
                                                hagueInfo.repDbStatus === "new"? 
                                                  "To send an email to this entity, please save a contact with an email address."
                                                  :
                                                  "Send to representative"
                                                  :
                                                errMsg[9]
                                                }
                                              </UncontrolledTooltip>
                                              </Link>
                                            </div>
                                          </Row>
                                          : null}
                                        </>
                                      ) : null}
                                    </td>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {holderInfo.holderName ? (
                                        <>
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                             <p
                                                className="multiline"
                                                id={
                                                  "hol_info_" +
                                                  index +
                                                  holderIdx
                                                }
                                              >
                                                {holderInfo.holderCountry
                                                  ? `[${holderInfo.holderCountry}]`
                                                  : null}{" "}
                                                {holderInfo.holderName}{" "}
                                                {holderInfo.holderAddress}
                                              </p>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                  "hol_info_" +
                                                  index +
                                                  holderIdx
                                                }
                                              >
                                                {holderInfo.holderCountry
                                                  ? `[${holderInfo.holderCountry}]`
                                                  : null}{" "}
                                                {holderInfo.holderName}{" "}
                                                {holderInfo.holderAddress}
                                              </UncontrolledTooltip>
                                              <Link
                                                to="#"
                                                id={
                                                  "hol_copy_" +
                                                  index +
                                                  holderIdx
                                                }
                                                onClick={() => {
                                                  const holInfo = `${
                                                    holderInfo.holderCountry
                                                      ? `[${holderInfo.holderCountry}]`
                                                      : ""
                                                  } ${holderInfo.holderName} ${
                                                    holderInfo.holderAddress
                                                  }`;
                                                  if (
                                                    window.isSecureContext &&
                                                    navigator.clipboard
                                                  ) {
                                                    navigator.clipboard.writeText(
                                                      holInfo
                                                    );
                                                  } else {
                                                    // console.log('old copy clipboard!');
                                                    unsecuredCopyToClipboard(
                                                      holInfo,
                                                      "hol_copy_" +
                                                        index +
                                                        holderIdx
                                                    );
                                                  }
                                                  showSuccessCopyToast();
                                                }}
                                              >
                                                <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                              </Link>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                  "hol_copy_" +
                                                  index +
                                                  holderIdx
                                                }
                                              >
                                                Copy Holder's information to
                                                clipboard
                                              </UncontrolledTooltip>
                                            </div>
                                          </Row>
                                          {hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y' ?
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                              <Link
                                                to="#"
                                                onClick={() => {
                                                  togContactsScreen(
                                                    holderInfo.holderGroupNo
                                                  );
                                                }}
                                                data-toggle="modal"
                                                className={
                                                  holderInfo.holDbStatus ===
                                                  "upToDate"
                                                    ? "text-success"
                                                    : holderInfo.holMemberConfirmEmlYn ===
                                                      "Y"
                                                    ? "text-primary"
                                                    : holderInfo.holSuggestEmlYn ===
                                                      "Y"
                                                    ? "text-warning"
                                                    : "text-danger"
                                                }
                                              >
                                                <i
                                                  className="uil uil-at font-size-22"
                                                  id={
                                                    "contacts_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "contacts_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                >
                                                  {holderInfo.holDbStatus ===
                                                  "notUpToDate"
                                                    ? "Holder's email address(es) haven't been updated in a while and may be out of date!"
                                                    : holderInfo.holMemberConfirmEmlYn ===
                                                      "Y"
                                                    ? "Contacts of holder with member confirmations"
                                                    : holderInfo.holSuggestEmlYn ===
                                                      "Y"
                                                    ? "Contacts of holder with WeCrest Suggestions"
                                                    : "Contacts of holder"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link
                                                to="#"
                                                className={
                                                  holderInfo.holStarYn === "Y"
                                                    ? "text-warning"
                                                    : "text-black-50"
                                                }
                                                onClick={() => {
                                                  const _hagueNotifier = []

                                                  if(hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y'){
                                                    _hagueNotifier.push(hagueInfo)
                                                  }

                                                  if (
                                                    holderInfo.holStarYn === "Y"
                                                  ) {
                                                    dispatch(
                                                      unstarHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  } else {
                                                    dispatch(
                                                      starHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className="uil uil-star font-size-22"
                                                  id={
                                                    "Starred_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "Starred_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                >
                                                  {holderInfo.holStarYn === "Y"
                                                    ? "Remove from starred"
                                                    : "Star holder"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link
                                                to="#"
                                                className={
                                                  holderInfo.holHideYn === "Y"
                                                    ? "text-danger"
                                                    : "text-black-50"
                                                }
                                                onClick={() => {
                                                  const _hagueNotifier = []

                                                  if(hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y'){
                                                    _hagueNotifier.push(hagueInfo)
                                                  }
                                                  if (
                                                    holderInfo.holHideYn === "Y"
                                                  ) {
                                                    dispatch(
                                                      unhideHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  } else {
                                                    dispatch(
                                                      hideHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className="uil uil-eye-slash font-size-22"
                                                  id={
                                                    "Hidden_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "Hidden_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                >
                                                  {holderInfo.holHideYn === "Y"
                                                    ? "Unhide holder"
                                                    : "Hide holder"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link to="#" className="text-black-50"
                                                onClick={ ()=>{
                                                  if (hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y')) {
                                                    if (holderInfo.holDbStatus === "upToDate") {
                                                      if (hagueInfo.senderEmailConfirmStatus === '' || hagueInfo.senderEmailConfirmStatus === 'pending') {
                                                        setAlertMsg(errMsg[0]);
                                                      } else {
                                                        if ((hagueInfo.exceptJurSetYn == 'Y' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.exceptJurEmlTmpSetYn == 'Y') && hagueInfo.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                              || (hagueInfo.exceptJurSetYn == 'N' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.defaultJurEmlTmpSetYn == 'Y') && hagueInfo.defaultJurSendEmailConfirmStatus == 'Confirmed')) {
                                                          setConfirmType("send_"+index);
                                                          setSendTarget("HOL");
                                                          setSendTargetGroupNo(holderInfo.holderGroupNo);
                                                          setConfirmMsg("Are you sure you want to send this email?");
                                                          setConfirmBtnText("Yes, send!");
                                                          setCancelBtnText("Cancel");
                                                          setConfirmAlert(true);
                                                        } else {
                                                          setAlertMsg(<span>Send is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>);
                                                        }
                                                      }
                                                    } else {
                                                      togContactsScreen(holderInfo.holderGroupNo)
                                                    }
                                                  }
                                                }
                                                }
                                              >
                                              <i className={hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y') ? "uil uil-message font-size-22" : "uil uil-message font-size-22 text-danger"} id={"send_hol_"+index+"_"+holderIdx}></i>
                                              <UncontrolledTooltip placement="top" target={"send_hol_"+index+"_"+holderIdx}>
                                              {
                                              hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y') ? 
                                                holderInfo.holDbStatus === "new"? 
                                                "To send an email to this entity, please save a contact with an email address."
                                                : 
                                                "Send to holder"
                                              :
                                              errMsg[9]
                                              }
                                              </UncontrolledTooltip>
                                              </Link>
                                            </div>
                                          </Row>
                                          : null}
                                        </>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      align="center"
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      <Row className="d-flex horizon-align-center">
                                        {hagueInfo.irNumber}
                                      </Row>
                                      {hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y' ?
                                      <Row>
                                        <div className="d-flex horizon-align-center vertical-align-center gap-2">
                                          <Link
                                            to="#"
                                            className={
                                              hagueInfo.hagueStarYn === "Y"
                                                ? "text-warning"
                                                : null
                                            }
                                            onClick={() => {
                                              const _hagueNotifier = []
                                              _hagueNotifier.push(hagueInfo)
                                              if (
                                                hagueInfo.hagueStarYn === "Y"
                                              ) {
                                                dispatch(
                                                  multipleUnstarHague(_hagueNotifier, hagueValidation.values.inOutType)
                                                );
                                              } else {
                                                dispatch(
                                                  multipleStarHague(_hagueNotifier, hagueValidation.values.inOutType)
                                                );
                                              }
                                            }}
                                          >
                                            <i
                                              className="uil uil-star font-size-22"
                                              id={"Starred_case_" + index}
                                            ></i>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={"Starred_case_" + index}
                                            >
                                              {hagueInfo.hagueStarYn === "Y"
                                                ? "Remove from starred"
                                                : "Star case"}
                                            </UncontrolledTooltip>
                                          </Link>
                                          <Link
                                            to="#"
                                            className={
                                              hagueInfo.hagueHideYn === "Y"
                                                ? "text-danger"
                                                : null
                                            }
                                            onClick={() => {
                                              const _hagueNotifier = []
                                              _hagueNotifier.push(hagueInfo)
                                              if (
                                                hagueInfo.hagueHideYn === "Y"
                                              ) {
                                                dispatch(
                                                  multipleUnhideHague(_hagueNotifier, hagueValidation.values.inOutType)
                                                );
                                              } else {
                                                // console.log(_hagueNotifier)
                                                dispatch(
                                                  multipleHideHague(_hagueNotifier, hagueValidation.values.inOutType)
                                                );
                                              }
                                            }}
                                          >
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={"Hidden_case_" + index}
                                            >
                                              {hagueInfo.hagueHideYn === "Y"
                                                ? "Unhide case"
                                                : "Hide case"}
                                            </UncontrolledTooltip>
                                            <i
                                              className="uil uil-eye-slash font-size-22"
                                              id={"Hidden_case_" + index}
                                            ></i>
                                          </Link>
                                          {
                                            hagueInfo.pdfList.length > 3 ?
                                              <a
                                              href={hagueInfo.pdfList[0].pdfStorageUrl}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i className="mdi mdi-file-pdf text-danger font-size-22"></i>
                                            </a> 
                                            :
                                            hagueInfo.pdfList.map((haguePdf, haguePdfIdx) => {
                                              return (
                                                <>
                                                <a
                                                  href={haguePdf.pdfStorageUrl}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <i className="mdi mdi-file-pdf text-danger font-size-22"></i>
                                                </a>
                                                {haguePdfIdx < hagueInfo.pdfList.length-1? <br/> : null}
                                                </>
                                              )
                                            })
                                          }
                                        </div>
                                      </Row>
                                      :
                                      null}
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      {hagueInfo.jurisdiction}
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      {
                                        `${hagueInfo.inscriptionDate.slice(2, 4)}/${hagueInfo.inscriptionDate.slice(4,6
                                        )}/${hagueInfo.inscriptionDate.slice(6, 8)}`}
                                      <br></br>
                                      {hagueInfo.uploadedDate && 
                                        `${hagueInfo.uploadedDate.slice(2, 4)}/${hagueInfo.uploadedDate.slice(5,7
                                        )}/${hagueInfo.uploadedDate.slice(8, 10)}`
                                      }                                  
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      {hagueInfo.designName}
                                    </td>
                                    <td
                                      style={{ verticalAlign: "middle" }}
                                      align="center"
                                      rowSpan={hagueInfo.holderList.length}
                                    >
                                      {
                                        hagueInfo.wecrestSendCount == 0 ? <i className="bx bxs-circle font-size-22 text-success"></i>
                                          : hagueInfo.wecrestSendCount <5 ? <i className="bx bxs-circle font-size-22 text-primary"></i>
                                            : hagueInfo.wecrestSendCount < 10 ? <i className="bx bxs-circle font-size-22 text-warning"></i>
                                              : <i className="bx bxs-circle font-size-22 text-danger"></i>
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <tr style={{backgroundColor:
                                    hagueInfo.lastContactDate !== "0000-00-00" ? emailSentBgColor : null}}>
                                    <td style={{ verticalAlign: "middle" }}>
                                      {holderInfo.holderName ? (
                                        <>
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                              <p
                                                className="multiline"
                                                id={
                                                  "hol_info_" +
                                                  index +
                                                  holderIdx
                                                }
                                              >
                                                {holderInfo.holderCountry
                                                  ? `[${holderInfo.holderCountry}]`
                                                  : null}{" "}
                                                {holderInfo.holderName}{" "}
                                                {holderInfo.holderAddress}
                                              </p>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                  "hol_info_" +
                                                  index +
                                                  holderIdx
                                                }
                                              >
                                                {holderInfo.holderCountry
                                                  ? `[${holderInfo.holderCountry}]`
                                                  : null}{" "}
                                                {holderInfo.holderName}{" "}
                                                {holderInfo.holderAddress}
                                              </UncontrolledTooltip>
                                              <Link
                                                to="#"
                                                id={
                                                  "hol_copy_" +
                                                  index +
                                                  holderIdx
                                                }
                                                onClick={() => {
                                                  const holInfo = `${
                                                    holderInfo.holderCountry
                                                      ? `[${holderInfo.holderCountry}]`
                                                      : ""
                                                  } ${holderInfo.holderName} ${
                                                    holderInfo.holderAddress
                                                  }`;
                                                  if (
                                                    window.isSecureContext &&
                                                    navigator.clipboard
                                                  ) {
                                                    navigator.clipboard.writeText(
                                                      holInfo
                                                    );
                                                  } else {
                                                    // console.log('old copy clipboard!');
                                                    unsecuredCopyToClipboard(
                                                      holInfo,
                                                      "hol_copy_" +
                                                        index +
                                                        holderIdx
                                                    );
                                                  }
                                                  showSuccessCopyToast();
                                                }}
                                              >
                                                <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                              </Link>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                  "hol_copy_" +
                                                  index +
                                                  holderIdx
                                                }
                                              >
                                                Copy Holder's information to
                                                clipboard
                                              </UncontrolledTooltip>
                                            </div>
                                          </Row>
                                          {hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y' ?
                                          <Row>
                                            <div className="d-flex vertical-align-center gap-2">
                                              <Link
                                                to="#"
                                                onClick={() => {
                                                  togContactsScreen(
                                                    holderInfo.holderGroupNo
                                                  );
                                                }}
                                                data-toggle="modal"
                                                className={
                                                  holderInfo.holDbStatus ===
                                                  "upToDate"
                                                    ? "text-success"
                                                    : holderInfo.holMemberConfirmEmlYn ===
                                                      "Y"
                                                    ? "text-primary"
                                                    : holderInfo.holSuggestEmlYn ===
                                                      "Y"
                                                    ? "text-warning"
                                                    : "text-danger"
                                                }
                                              >
                                                <i
                                                  className="uil uil-at font-size-22"
                                                  id={
                                                    "contacts_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "contacts_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                >
                                                  {holderInfo.holDbStatus ===
                                                  "notUpToDate"
                                                    ? "Holder's email address(es) haven't been updated in a while and may be out of date!"
                                                    : holderInfo.holMemberConfirmEmlYn ===
                                                      "Y"
                                                    ? "Contacts of holder with member confirmations"
                                                    : holderInfo.holSuggestEmlYn ===
                                                      "Y"
                                                    ? "Contacts of holder with WeCrest Suggestions"
                                                    : "Contacts of holder"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link
                                                to="#"
                                                className={
                                                  holderInfo.holStarYn === "Y"
                                                    ? "text-warning"
                                                    : "text-black-50"
                                                }
                                                onClick={() => {
                                                  const _hagueNotifier = []

                                                  if(hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y'){
                                                    _hagueNotifier.push(hagueInfo)
                                                  }
                                                  if (
                                                    holderInfo.holStarYn === "Y"
                                                  ) {
                                                    dispatch(
                                                      unstarHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  } else {
                                                    dispatch(
                                                      starHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className="uil uil-star font-size-22"
                                                  id={
                                                    "Starred_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "Starred_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                >
                                                  {holderInfo.holStarYn === "Y"
                                                    ? "Remove from starred"
                                                    : "Star holder"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link
                                                to="#"
                                                className={
                                                  holderInfo.holHideYn === "Y"
                                                    ? "text-danger"
                                                    : "text-black-50"
                                                }
                                                onClick={() => {
                                                  const _hagueNotifier = []

                                                  if(hagueInfo.premSubsYn === 'Y' || hagueInfo.unlockYn === 'Y'){
                                                    _hagueNotifier.push(hagueInfo)
                                                  }
                                                  if (
                                                    holderInfo.holHideYn === "Y"
                                                  ) {
                                                    dispatch(
                                                      unhideHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  } else {
                                                    dispatch(
                                                      hideHagueTarget(holderInfo.holderGroupNo, hagueValidation.values.inOutType, _hagueNotifier)
                                                    );
                                                  }
                                                }}
                                              >
                                                <i
                                                  className="uil uil-eye-slash font-size-22"
                                                  id={
                                                    "Hidden_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                ></i>
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={
                                                    "Hidden_hol_" +
                                                    index +
                                                    holderIdx
                                                  }
                                                >
                                                  {holderInfo.holHideYn === "Y"
                                                    ? "Unhide holder"
                                                    : "Hide holder"}
                                                </UncontrolledTooltip>
                                              </Link>
                                              <Link to="#" className="text-black-50"
                                                onClick={ ()=>{
                                                  // console.log("initial "+ index+"_"+ holderIdx)
                                                  if (hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y')) {
                                                    if (holderInfo.holDbStatus === "upToDate") {
                                                      if (hagueInfo.senderEmailConfirmStatus === '' || hagueInfo.senderEmailConfirmStatus === 'pending') {
                                                        setAlertMsg(errMsg[0]);
                                                      } else {
                                                        if ((hagueInfo.exceptJurSetYn == 'Y' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.exceptJurEmlTmpSetYn == 'Y') && hagueInfo.exceptJurSendEmailConfirmStatus == 'Confirmed')
                                                              || (hagueInfo.exceptJurSetYn == 'N' && (hagueInfo.hagueEmlTmpSetYn == 'Y' || hagueInfo.defaultJurEmlTmpSetYn == 'Y') && hagueInfo.defaultJurSendEmailConfirmStatus == 'Confirmed')) {
                                                          setConfirmType("send_"+index);
                                                          setSendTarget("HOL");
                                                          setSendTargetGroupNo(holderInfo.holderGroupNo);
                                                          setConfirmMsg("Are you sure you want to send this email?");
                                                          setConfirmBtnText("Yes, send!");
                                                          setCancelBtnText("Cancel");
                                                          setConfirmAlert(true);
                                                        } else {
                                                          setAlertMsg(<span>Send is not available because the email setting is not complete.<br/> Please edit your "Jurisdiction defaults" in your <a href='/emailingSettings' className='a-href-underline'>emailing settings</a>.</span>);
                                                        }
                                                      }
                                                    } else {
                                                      togContactsScreen(holderInfo.holderGroupNo)
                                                    }
                                                  }
                                                }
                                                }
                                              >
                                              <i className={hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y') ? "uil uil-message font-size-22" : "uil uil-message font-size-22 text-danger"} id={"send_hol_"+index+"_"+holderIdx}></i>
                                              <UncontrolledTooltip placement="top" target={"send_hol_"+index+"_"+holderIdx}>
                                              {
                                              hagueInfo.wecrestSendCount < 10 || ((hagueInfo.premSubsYn == 'Y' && hagueInfo.lastContactDate !== '0000-00-00') || hagueInfo.unlockYn == 'Y') ? 
                                                holderInfo.holDbStatus === "new"? 
                                                "To send an email to this entity, please save a contact with an email address."
                                                : 
                                                "Send to holder"
                                              :
                                              errMsg[9]
                                              }
                                              </UncontrolledTooltip>
                                              </Link>
                                            </div>
                                          </Row>
                                          : null}
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              }
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <WeCrestPagination
                    viewList={viewList}
                    pageSize={hagueValidation.values.pageSize}
                    curPage={hagueValidation.values.curPage}
                    totalPage={hagueInfoTotalPages}
                    curPageInput={curPageInput}
                    setCurPageInput={setCurPageInput}
                    pageSizeList={[50,100,250,500,1000]}
                  />
                </CardBody>
              </Card>
            </Col>
            </Row>
             : null}
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default HagueNotifer;
