import React, { useState } from "react";
import { Form, Media, Card, Collapse, Row } from "reactstrap";
import SelectCountry from "../../components/Filter/SelectCountry";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import SearchName from "../../components/Filter/SearchName";

const PCTNotifier = () =>{

    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    }

    const pctValidation = useFormik({
        initialValues: {
            holderType: '',
            holderCdList: '',
            repType: '',
            repCdList: '',
            screenName: 'pctNotifier',
        },
        
    });

    return(
        <React.Fragment>
            <Form onSubmit={pctValidation.handleSubmit}>
             <div className="page-content">
                <div className="container-fluid">
                <Card>
                <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                    <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <i className="uil uil-file-alt text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-16 mb-1">PCT Notifier</h5>
                            <p className="text-muted mb-0">Use this tool to connect with agents or holders whose national phase entry deadline is coming up soon.</p>
                        </div>
                        <i className="uil uil-filter font-size-24 text-muted"></i>
                    </Media>
                    </div>
                </Link>
                <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                        <div>
                        <SelectCountry
                            componentName='Representative'
                            formik={pctValidation}
                        />
                        <SelectCountry
                            componentName='Holder'
                            formik={pctValidation}
                        />
                        <SearchName
                            formik={pctValidation}
                        />
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">Case number</label>
                            <div className="col-md-3">
                                <input
                                    name="caseNumber"
                                    id="caseNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter application or publication number (e.g. WO/2025/011234 or WO2025011234)"
                                    style={{width: '40em'}}
                                />
                            </div>
                        </Row>
                        </div>
                    </div>
                </Collapse>
                </Card>
              </div>
             </div>
            </Form>
        </React.Fragment>
    )
}

export default PCTNotifier;