export const GET_HAGUE_NOTIFIER_SETTINGS = "GET_HAGUE_NOTIFIER_SETTINGS"
export const GET_HAGUE_NOTIFIER_SETTINGS_SUCCESS = "GET_HAGUE_NOTIFIER_SETTINGS_SUCCESS"
export const UPDATE_HAGUE_NOTIFIER_SETTINGS = "UPDATE_HAGUE_NOTIFIER_SETTINGS"

export const GET_HAGUE_INFO = "GET_HAGUE_INFO"
export const GET_HAGUE_INFO_SUCCESS = "GET_HAGUE_INFO_SUCCESS"
export const CHANGE_HAGUE_NOTIFIER = "CHANGE_HAGUE_NOTIFIER"

export const GET_HAGUE_NOTIFIER_INFO_LIST = " GET_HAGUE_NOTIFIER_INFO_LIST"
export const GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS = "GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS"
export const GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS_UNLOCK = "GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS_UNLOCK"

export const GET_HAGUE_JURISDICTIONS = "GET_HAGUE_JURISDICTIONS"
export const GET_HAGUE_JURISDICTIONS_SUCCESS = "GET_HAGUE_JURISDICTIONS_SUCCESS"
export const UPDATE_HAGUE_JURISDICTIONS = "UPDATE_HAGUE_JURISDICTIONS"

export const GET_HAGUE_COUNTRIES = "GET_HAGUE_COUNTRIES"
export const GET_HAGUE_COUNTRIES_SUCCESS = "GET_HAGUE_COUNTRIES_SUCCESS"

export const SET_HAGUE_LOADING = "SET_HAGUE_LOADING"

export const GET_HAGUE_HOLDER_LIST = "GET_HAGUE_HOLDER_LIST"
export const GET_HAGUE_HOLDER_LIST_SUCCESS = "GET_HAGUE_HOLDER_LIST_SUCCESS"

export const SAVE_HAGUE_NOTIFIER_SETTINGS = "SAVE_HAGUE_NOTIFIER_SETTINGS"
export const SAVE_HAGUE_NOTIFIER_SETTINGS_SUCCESS = "SAVE_HAGUE_NOTIFIER_SETTINGS_SUCCESS"

export const MULTIPLE_STAR_HAGUE = "MULTIPLE_STAR_HAGUE"
export const MULTIPLE_STAR_HAGUE_SUCCESS = "MULTIPLE_STAR_HAGUE_SUCCESS"

export const MULTIPLE_UNSTAR_HAGUE = "MULTIPLE_UNSTAR_HAGUE"
export const MULTIPLE_UNSTAR_HAGUE_SUCCESS = "MULTIPLE_UNSTAR_HAGUE_SUCCESS"

export const MULTIPLE_HIDE_HAGUE = "MULTIPLE_HIDE_HAGUE"
export const MULTIPLE_HIDE_HAGUE_SUCCESS = "MULTIPLE_HIDE_HAGUE_SUCCESS"

export const MULTIPLE_UNHIDE_HAGUE = "MULTIPLE_UNHIDE_HAGUE"
export const MULTIPLE_UNHIDE_HAGUE_SUCCESS = "MULTIPLE_UNHIDE_HAGUE_SUCCESS"

export const SET_HAGUE_SUCCESS = "SET_HAGUE_SUCCESS"
export const SET_HAGUE_RESULT = "SET_HAGUE_RESULT"

export const DOWN_HAGUE_EXCEL = "DOWN_HAGUE_EXCEL"
export const DOWN_HAGUE_EXCEL_SUCCESS = "DOWN_HAGUE_EXCEL_SUCCESS"

export const STAR_HAGUE_TARGET = "STAR_HAGUE_TARGET"
export const UNSTAR_HAGUE_TARGET = "UNSTAR_HAGUE_TARGET"
export const HIDE_HAGUE_TARGET = "HIDE_HAGUE_TARGET"
export const UNHIDE_HAGUE_TARGET = "UNHIDE_HAGUE_TARGET"

export const DOWN_MULTIPLE_HAGUE_EXCEL = "DOWN_MULTIPLE_HAGUE_EXCEL"
export const DOWN_MULTIPLE_HAGUE_EXCEL_SUCCESS = "DOWN_MULTIPLE_HAGUE_EXCEL_SUCCESS"

export const DOWN_HAGUE_PDF = "DOWN_HAGUE_PDF"
export const DOWN_HAGUE_PDF_SUCCESS = "DOWN_HAGUE_PDF_SUCCESS"

export const DOWN_MULTIPLE_HAGUE_PDF = "DOWN_MULTIPLE_HAGUE_PDF"
export const DOWN_MULTIPLE_HAGUE_PDF_SUCCESS = "DOWN_MULTIPLE_HAGUE_PDF_SUCCESS"

export const SEND_HAGUE_EMAIL = "SEND_HAGUE_EMAIL"
export const SEND_HAGUE_EMAIL_SUCCESS = "SEND_HAGUE_EMAIL_SUCCESS"

export const PREVIEW_HAGUE_EMAIL = "PREVIEW_HAGUE_EMAIL"
export const PREVIEW_HAGUE_EMAIL_SUCCESS = "PREVIEW_HAGUE_EMAIL_SUCCESS"
export const SET_PREVIEW_HAGUE_EMAIL = "SET_PREVIEW_HAGUE_EMAIL"

export const UNCLOCK_HAGUE = "UNCLOCK_HAGUE"
export const UNCLOCK_HAGUE_SUCCESS = "UNCLOCK_HAGUE_SUCCESS"

export const SET_UPGRADE_ALERT_MESSAGE = "SET_UPGRADE_ALERT_MESSAGE"

export const CHANGE_SENT_HAGUE_RESULT = "CHANGE_SENT_HAGUE_RESULT"
export const SET_HAGUE_SENT_SUCCESS = "SET_HAGUE_SENT_SUCCESS"

export const SET_HAGUE_SETTINGS = "SET_HAGUE_SETTINGS"


