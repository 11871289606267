import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getCountryList as onGetCountryList,
    changeCountryList as onChangeCountryList,
    addUserJurisdictionEmailSetting as onAddUserJurisdictionEmailSetting,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SelectCountrySettings = props => {
    const dispatch = useDispatch();

    const { countryList } = useSelector(state => ({
      countryList: state.GeneralStatistics.countryList,
    }));

    const [viewCountryList, setViewCountryList] = useState([]);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    /**
     * selectedJurList[1] : {'jurType' : str}
     * selectedJurList[2] : {'jurList' : [{countryVO},{countryVO},{}...]}
     */
    // const [selectedCountryList, setSelectedCountryList] = useState([]);
    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        props.setCountryOpen(false);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        const _countryList = [];
        viewCountryList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || (jur.crName && jur.crName.toLowerCase().includes(keyword) == true)){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _countryList.push(_jur);
        }); 

        // dispatch(onChangeCountryList(_countryList));
        setViewCountryList(_countryList);
    }

    const jurCheck = (str, checked) => {
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        const _countryList = [];
        viewCountryList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str == chkAll 
                || (str == nonEU && checked == false))
                && jur.show){
                _jur.checked = checked;
            }else if(str == nonEU && checked == true && jur.show){
                if(jur.euYn == 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode == str){
                    _jur.checked = checked;
                }

                if (jur.crCode !== str) {
                    _jur.checked = false;
                }
            }

            if(_jur.checked == true){
                chkCnt++;
                if(jur.euYn == 'N'){
                    chkNonEuCnt++;
                }
            }

            if(jur.euYn == 'N'){
                nonEuCnt++;
            }

            _countryList.push(_jur);
        }); 

        if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(viewCountryList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        // dispatch(onChangeCountryList(_countryList));
        setViewCountryList(_countryList);
    }

    const jurConfirm = () => {
        let _addCrList = [];

        viewCountryList.map((jur, index) =>{
            if(jur.checked == true){
                _addCrList.push({'serviceName': props.serviceName, 'inOutType': 'OUT', 'crCode': jur.crCode});
            }
        });
    
        dispatch(onAddUserJurisdictionEmailSetting(_addCrList));

        // openCountryModal();
        closeCountryModal(_addCrList);
    }

    const closeCountryModal = (_addCrList) => {
        // props.crCdList가 변경되면 htmlSetting 자동 호출 됨 
        // htmlSetting(_addCrList)
        props.setCountryOpen(false);
        removeBodyCss();
    }

    const htmlSetting = (crCdList) => {
    
        if(crCdList && crCdList.length){
            let _countryList = [];
           
            countryList.map((jur, index) =>{
                if(!crCdList.includes(jur.crCode)){
                    // let _jur = cloneDeep(jur);

                    // console.log('2-3. add crCode : ' + jur.crCode);

                    _countryList.push(jur);

                }
            });

            // dispatch(onChangeCountryList(_countryList));
            setViewCountryList(_countryList);

            // console.log('2-4. _countryList.length : ' + _countryList.length);

            if (_countryList.length == 0) {
                props.setCrAddButtonFlag(false);
            } else {
                props.setCrAddButtonFlag(true);
            }
        } else {
            let _countryList = cloneDeep(countryList);
            setViewCountryList(_countryList);
            props.setCrAddButtonFlag(true);
        }
    }

    useEffect(()=> {
        // search settings 이후에 onGetCountryList 불러오도록 처리 
        setTimeout(() => dispatch(onGetCountryList({})), 500);
    },[]);

    useEffect(()=> {
        if(countryList.length > 0){
            htmlSetting(props.crCdList);
        }
    },[props.crCdList]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 crCdList가 동시에 변경됨
        //onGetCountryList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if(countryList.length > 0){
            // console.log('2-2. crCdList changed : ' + JSON.stringify(props.crCdList) + ', countryList.length : ' + countryList.length);
            htmlSetting(props.crCdList);
        }
    },[JSON.stringify(countryList)]);

    return (
        <Modal
            isOpen={props.countryOpen}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select countries
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyDown={(e) => {
                            if(e.code === 'Enter'){
                                search(e.target.value);
                            }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row>
                {
                    viewCountryList && viewCountryList.length ?
                    viewCountryList.map((jur, index) => {
                        return (
                            jur.show? 
                            <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
    )
};

export default SelectCountrySettings;