import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getAllJurisdictionsList as onGetAllJurisdictionsList,
    changeJurisdictionsList as onChangeJurisdictionsList,
    addUserJurisdictionEmailSetting as onAddUserJurisdictionEmailSetting,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SelectJurisdictionsSettings = props => {
    const dispatch = useDispatch();

    const { jurisdictionList } = useSelector(state => ({
      jurisdictionList: state.GeneralStatistics.jurisdictionList,
    }));

    const [viewJurisdictionList, setViewJurisdictionList] = useState([]);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    /**
     * selectedJurList[1] : {'jurType' : str}
     * selectedJurList[2] : {'jurList' : [{countryVO},{countryVO},{}...]}
     */
    // const [selectedJurList, setSelectedJurList] = useState([]);
    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        props.setJurOpen(false);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        const _jurisdictionList = [];
        viewJurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || jur.crName.toLowerCase().includes(keyword) == true){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _jurisdictionList.push(_jur);
        }); 

        setViewJurisdictionList(_jurisdictionList);
    }

    const jurCheck = (str, checked) => {

        // console.log('1. str : ' + str + ', checked : ' + checked)
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        const _jurisdictionList = [];
        viewJurisdictionList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str == chkAll 
                || (str == nonEU && checked == false))
                && jur.show){
                _jur.checked = checked;
            }else if(str == nonEU && checked == true && jur.show){
                if(jur.euYn == 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode == str){
                    // console.log('2. str : ' + str + ', checked : ' + checked)
                    _jur.checked = checked;
                }
            }

            if(_jur.checked == true){
                // console.log('3. str : ' + jur.crCode + ', checked : ' + _jur.checked)
                chkCnt++;
                if(jur.euYn == 'N'){
                    chkNonEuCnt++;
                }
            }

            if(jur.euYn == 'N'){
                nonEuCnt++;
            }

            _jurisdictionList.push(_jur);
        }); 

        if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(viewJurisdictionList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        // dispatch(onChangeJurisdictionsList(_jurisdictionList));
        setViewJurisdictionList(_jurisdictionList);
    }

    const jurConfirm = () => {
        let _addJrList = [];

        viewJurisdictionList.map((jur, index) =>{
            if(jur.checked == true){
                _addJrList.push({'serviceName': props.serviceName, 'inOutType': 'IN', 'crCode': jur.crCode});
            }
        });
    
        dispatch(onAddUserJurisdictionEmailSetting(_addJrList));

        // openCountryModal();
        closeCountryModal(_addJrList);
    }

    const closeCountryModal = (_addJrList) => {
        // props.crCdList가 변경되면 htmlSetting 자동 호출 됨 
        // htmlSetting(_addJrList)
        props.setJurOpen(false);
        removeBodyCss();
    }

    const htmlSetting = (jrCdList) => {
        if(jrCdList && jrCdList.length){
            let _jurisdictionList = [];
           
            jurisdictionList.map((jur, index) =>{
                if(!jrCdList.includes(jur.crCode)){

                    // console.log('1-3. add crCode : ' + jur.crCode);

                    // let _jur = cloneDeep(jur);
                    _jurisdictionList.push(jur);

                }
            });

            setViewJurisdictionList(_jurisdictionList);
            // dispatch(onChangeJurisdictionsList(_jurisdictionList));

            // console.log('1-4. _jurisdictionList.length : ' + _jurisdictionList.length);

            if (_jurisdictionList.length == 0) {
                props.setJrAddButtonFlag(false);
            } else {
                props.setJrAddButtonFlag(true);
            }
        } else {
            // console.log('전체 등록');
            let _jurisdictionList = cloneDeep(jurisdictionList);
            setViewJurisdictionList(_jurisdictionList);
            props.setJrAddButtonFlag(true);
        }
    }

    useEffect(()=> {
        //search settings 이후에 onGetAllJurisdictionsList 불러오도록 처리 
        setTimeout(() => dispatch(onGetAllJurisdictionsList({inOutType : 'IN'})), 500);
    },[]);
    
    useEffect(()=> {
        if(jurisdictionList.length > 0){
            // console.log('props.jrCdList.length : ' + props.jrCdList.length);
            htmlSetting(props.jrCdList);
        }
    },[props.jrCdList]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 jrCdList가 동시에 변경됨
        //onGetJurisdictionsList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if(jurisdictionList.length > 0){
            // console.log('1-2. jrCdList changed : ' + JSON.stringify(props.jrCdList) + ', jurisdictionList.length : ' + jurisdictionList.length);
            htmlSetting(props.jrCdList);
        }
    },[JSON.stringify(jurisdictionList)]);

    return (
        <Modal
            isOpen={props.jurOpen}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select jurisdictions
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyDown={(e) => {
                            if(e.code === 'Enter'){
                                search(e.target.value);
                            }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row>
                {
                    viewJurisdictionList && viewJurisdictionList.length ?
                    viewJurisdictionList.map((jur, index) => {
                        return (
                            jur.show? 
                            <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
    )
};

export default SelectJurisdictionsSettings;