import {
  GET_ALL_JURISDICTIONS_LIST,
  GET_ALL_JURISDICTIONS_LIST_SUCCESS,
  GET_JURISDICTIONS_LIST,
  GET_JURISDICTIONS_LIST_SUCCESS,
  CHANGE_JURISDICTIONS_LIST,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_ALL_COUNTRY_LIST,
  GET_ALL_COUNTRY_LIST_SUCCESS,
  CHANGE_COUNTRY_LIST,
  GET_SEARCH_NAME_LIST,
  GET_SEARCH_NAME_LIST_SUCCESS,
  GET_SEARCH_TARGET_GROUP_LIST,
  GET_SEARCH_TARGET_GROUP_LIST_SUCCESS,
  CHANGE_SEARCH_NAME_LIST,
  CHANGE_SEARCH_NAME_SELECTED_LIST,
  CLEAR_SEARCH_NAME_LIST,
  GET_GENERALSTATISTICS_LIST,
  GET_GENERALSTATISTICS_LIST_SUCCESS,
  DOWN_STATISTICS_EXCEL,
  DOWN_STATISTICS_EXCEL_SUCCESS,
  DOWN_STATISTICS_PDF,
  DOWN_STATISTICS_PDF_SUCCESS,
  CLEAR_GENERALSTATISTICS_LIST,
  SET_SEARCHNAME_LIST,
  SET_IS_WAR_SEARCH,
  GET_HAGUE_SEARCH_TARGET_GROUP_LIST,
  GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS,
} from "./actionTypes"

const initialState = {
  jurisdictionList: [],
  countryList: [],
  searchNameList: [],
  madridSearchNameList: [],
  hagueSearchNameList: [],
  filerSALSearchNameList: [],
  filerSASSearchNameList: [],
  searchNameSelectedList: [],
  madridSearchNameSelectedList: [],
  hagueSearchNameSelectedList: [],
  filerSALSearchNameSelectedList: [],
  filerSASSearchNameSelectedList: [],
  searchRepNameList: [],
  searchRepNameSelectedList: [],
  searchHolderNameList: [],
  searchHolderNameSelectedList: [],
  searchHolderNameList_MCC: [], 
  searchHolderNameSelectedList_MCC: [],
  error: "",
  loading: false,
  generalStatisticsList: [],
  noticeStr: "",
  isWarGsSearch: false,
}

const generalStatistics = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_JURISDICTIONS_LIST:
      return {
        ...state,
        jurisdictionList: [],
      }
    case GET_ALL_JURISDICTIONS_LIST_SUCCESS:
      return {
        ...state,
        jurisdictionList: action.payload
      }
    case GET_JURISDICTIONS_LIST:
      return {
        ...state,
      }
    case GET_JURISDICTIONS_LIST_SUCCESS:
      return {
        ...state,
        jurisdictionList: action.payload
      }
    case CHANGE_JURISDICTIONS_LIST:
      return {
        ...state,
        jurisdictionList: action.payload
      }
    case GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: [],
      }
    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload
      }
    case GET_ALL_COUNTRY_LIST:
      return {
        ...state,
        countryList: [],
      }
    case GET_ALL_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload
      }
    case CHANGE_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload
      }
    case GET_SEARCH_NAME_LIST:
      return {
        ...state,
      }
    case GET_SEARCH_NAME_LIST_SUCCESS:
      if (action.payload.screenName === 'MadridNotifier') {
        return {
          ...state,
          madridSearchNameList: action.payload.data.result
        }
      } else if (action.payload.screenName === 'HagueNotifier') {
        return {
          ...state,
          hagueSearchNameList: action.payload.data.result
        }
      } else if (action.payload.screenName === 'FilerSendingAnalyticsStatistics') {
        return {
          ...state,
          filerSASSearchNameList: action.payload.data.result
        }
      } else if (action.payload.screenName === 'FilerSendingAnalyticsLog') {
        return {
          ...state,
          filerSALSearchNameList: action.payload.data.result
        }
      } else if (action.payload.screenName === 'MyCaseManagement') {
        if (action.payload.filterName === 'myConnectedCase') {
          return {
            ...state,
            searchHolderNameList_MCC: action.payload.data.result
          }
        } else {
          if (action.payload.labelName === 'Name of the representative') {
            return {
              ...state,
              searchRepNameList: action.payload.data.result
            }
          } else {
            return {
              ...state,
              searchHolderNameList: action.payload.data.result
            }
          }
        }
      } else {
        return {
          ...state,
          searchNameList: action.payload.data.result
        }
      }
    case GET_SEARCH_TARGET_GROUP_LIST:
      return {
        ...state,
      }
    case GET_SEARCH_TARGET_GROUP_LIST_SUCCESS:
       console.log('GET_SEARCH_TARGET_GROUP_LIST_SUCCESS : ' + JSON.stringify(action.payload));     
      return {
        ...state,
        madridSearchNameList: action.payload,
        isDruSearch: true,
      }
    case GET_HAGUE_SEARCH_TARGET_GROUP_LIST:
      return {
        ...state,
      }
    case GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS:
       //console.log('GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS : ' + JSON.stringify(action.payload));     
      return {
        ...state,
        hagueSearchNameList: action.payload,
        isDruSearch: true,
      }
    case CHANGE_SEARCH_NAME_LIST:
      // console.log('CHANGE_SEARCH_NAME_LIST : ' + action.payload);
      if (action.payload.screenName === 'MadridNotifier') {
        return {
          ...state,
          madridSearchNameList: action.payload.searchNameList
        }
      } else if (action.payload.screenName === 'HagueNotifier') {
        return {
          ...state,
          hagueSearchNameList: action.payload.searchNameList
        }
      }else if (action.payload.screenName === 'FilerSendingAnalyticsStatistics') {
        return {
          ...state,
          filerSASSearchNameList: action.payload.searchNameList
        }
      } else if (action.payload.screenName === 'FilerSendingAnalyticsLog') {
        return {
          ...state,
          filerSALSearchNameList: action.payload.searchNameList
        }
      } else if (action.payload.screenName === 'MyCaseManagement') {
        if (action.payload.filterName === 'myConnectedCase') {
          return {
            ...state,
            searchHolderNameList_MCC: action.payload.searchNameList
          }
        } else {
          if (action.payload.labelName === 'Name of the representative') {
            return {
              ...state,
              searchRepNameList: action.payload.searchNameList
            }
          } else {
            return {
              ...state,
              searchHolderNameList: action.payload.searchNameList
            }
          }
        }
      } else {
        return {
          ...state,
          searchNameList: action.payload.searchNameList
        }
      }
    case CHANGE_SEARCH_NAME_SELECTED_LIST:
      // console.log('CHANGE_SEARCH_NAME_SELECTED_LIST : ' + action.payload.screenName + " / " + JSON.stringify(action.payload.selectedList));
      if (action.payload.screenName === 'MadridNotifier') {
        return {
          ...state,
          madridSearchNameSelectedList: action.payload.selectedList
        }
      } if (action.payload.screenName === 'HagueNotifier') {
        return {
          ...state,
          hagueSearchNameSelectedList: action.payload.selectedList
        }
      }else if (action.payload.screenName === 'FilerSendingAnalyticsStatistics') {
        return {
          ...state,
          filerSASSearchNameSelectedList: action.payload.selectedList
        }
      } else if (action.payload.screenName === 'FilerSendingAnalyticsLog') {
        return {
          ...state,
          filerSALSearchNameSelectedList: action.payload.selectedList
        }
      } else if (action.payload.screenName === 'MyCaseManagement') {
        if (action.payload.filterName === 'myConnectedCase') {
          return {
            ...state,
            searchHolderNameSelectedList_MCC: action.payload.selectedList
          }
        } else {
          if (action.payload.labelName === 'Name of the representative') {
            return {
              ...state,
              searchRepNameSelectedList: action.payload.selectedList
            }
          } else {
            return {
              ...state,
              searchHolderNameSelectedList: action.payload.selectedList
            }
          }
        }
      } else {
        return {
          ...state,
          searchNameSelectedList: action.payload.selectedList
        }
      }
    case CLEAR_SEARCH_NAME_LIST:
      if (action.payload.screenName === 'MadridNotifier') {
        return {
          ...state,
          madridSearchNameList: []
        }
      }else if (action.payload.screenName === 'HagueNotifier') {
        return {
          ...state,
          hagueSearchNameList: []
        }
      }else if (action.payload.screenName === 'FilerSendingAnalyticsStatistics') {
        return {
          ...state,
          filerSASSearchNameList: []
        }
      } else if (action.payload.screenName === 'FilerSendingAnalyticsLog') {
        return {
          ...state,
          filerSALSearchNameList: []
        }
      } else if (action.payload.screenName === 'MyCaseManagement') {
        if (action.payload.filterName === 'myConnectedCase') {
          return {
            ...state,
            searchHolderNameList_MCC: []
          }
        } else {
          if (action.payload.labelName === 'Name of the representative') {
            return {
              ...state,
              searchRepNameList: []
            }
          } else {
            return {
              ...state,
              searchHolderNameList: []
            }
          }
        }
      } else {
        return {
          ...state,
          searchNameList: []
        }
      }
    case GET_GENERALSTATISTICS_LIST:
      return {
        ...state,
        loading: true,
        generalStatisticsList: [],
      }
    case GET_GENERALSTATISTICS_LIST_SUCCESS:
      return {
        ...state,
        generalStatisticsList: action.payload.statisticsList,
        noticeStr: action.payload.noticeStr,
        loading: false
      }
    case DOWN_STATISTICS_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_STATISTICS_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_STATISTICS_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_STATISTICS_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case CLEAR_GENERALSTATISTICS_LIST:
      return {
        ...state,
        generalStatisticsList: []
      }
    case SET_SEARCHNAME_LIST:
      return {
        ...state,
        searchNameList: action.payload
      }
    case SET_IS_WAR_SEARCH:
      return {
        ...state,
        isWarGsSearch: action.payload
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default generalStatistics
