import {
  GET_ALL_JURISDICTIONS_LIST,
  GET_ALL_JURISDICTIONS_LIST_SUCCESS,
  GET_JURISDICTIONS_LIST,
  GET_JURISDICTIONS_LIST_SUCCESS,
  CHANGE_JURISDICTIONS_LIST,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_ALL_COUNTRY_LIST,
  GET_ALL_COUNTRY_LIST_SUCCESS,
  CHANGE_COUNTRY_LIST,
  GET_SEARCH_NAME_LIST,
  GET_SEARCH_NAME_LIST_SUCCESS,
  GET_SEARCH_NAME_LIST2,
  GET_SEARCH_TARGET_GROUP_LIST,
  GET_SEARCH_TARGET_GROUP_LIST_SUCCESS,
  CHANGE_SEARCH_NAME_LIST,
  CHANGE_SEARCH_NAME_SELECTED_LIST,
  CLEAR_SEARCH_NAME_LIST,
  GET_GENERALSTATISTICS_LIST,
  GET_GENERALSTATISTICS_LIST_SUCCESS,
  DOWN_STATISTICS_EXCEL,
  DOWN_STATISTICS_EXCEL_SUCCESS,
  DOWN_STATISTICS_PDF,
  DOWN_STATISTICS_PDF_SUCCESS,
  CLEAR_GENERALSTATISTICS_LIST,
  SET_SEARCHNAME_LIST,
  SET_IS_WAR_SEARCH,
  GET_HAGUE_SEARCH_TARGET_GROUP_LIST,
  GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS,
} from "./actionTypes"

export const getAllJurisdictionsList = inOutType => ({
  type: GET_ALL_JURISDICTIONS_LIST,
  payload: inOutType,
})

export const getAllJurisdictionsListSuccess = data => {
  return {
    type: GET_ALL_JURISDICTIONS_LIST_SUCCESS,
    payload: data.jurisdictionList,
  }
}

export const getJurisdictionsList = (inOutType, screenGrade) => ({
    type: GET_JURISDICTIONS_LIST,
    payload: {inOutType, screenGrade},
})

export const getJurisdictionsListSuccess = data => {
  return {
    type: GET_JURISDICTIONS_LIST_SUCCESS,
    payload: data.jurisdictionList,
  }
}

export const changeJurisdictionsList = jurisdictionList => {
  return {
    type: CHANGE_JURISDICTIONS_LIST,
    payload: jurisdictionList,
  }
}

export const getCountryList = inOutType => ({
    type: GET_COUNTRY_LIST,
    payload: inOutType,
})

export const getCountryListSuccess = data => {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: data.countryList,
  }
}

export const getAllCountryList = inOutType => ({
  type: GET_ALL_COUNTRY_LIST,
  payload: inOutType,
})

export const getAllCountryListSuccess = data => {
return {
  type: GET_ALL_COUNTRY_LIST_SUCCESS,
  payload: data.countryList,
}
}

export const changeCountryList = countryList => {
  return {
    type: CHANGE_COUNTRY_LIST,
    payload: countryList,
  }
}

export const getSearchNameList = (data) => ({
  type: GET_SEARCH_NAME_LIST,
  payload: data,
})

export const getSearchNameList2 = (data) => ({
  type: GET_SEARCH_NAME_LIST2,
  payload: data,
})

export const getSearchNameListSuccess = (data, screenName, labelName, filterName) => {
  return {
    type: GET_SEARCH_NAME_LIST_SUCCESS,
    payload: {data, screenName, labelName, filterName},
  }
}

export const getSearchTargetGroupList = targetGroupNo => ({
  type: GET_SEARCH_TARGET_GROUP_LIST,
  payload: targetGroupNo,
})

export const getSearchTargetGroupListSuccess = data => {
  return {
    type: GET_SEARCH_TARGET_GROUP_LIST_SUCCESS,
    payload: data.result,
  }
}

export const getHagueSearchTargetGroupList = targetGroupNo => ({
  type: GET_HAGUE_SEARCH_TARGET_GROUP_LIST,
  payload: targetGroupNo,
})

export const getHagueSearchTargetGroupListSuccess = data => {
  return {
    type: GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS,
    payload: data.result,
  }
}

export const changeSearchNameList = (searchNameList, screenName, labelName, filterName) => {
  return {
    type: CHANGE_SEARCH_NAME_LIST,
    payload: {searchNameList, screenName, labelName, filterName},
  }
}

export const changeSearchNameSelectedList = (selectedList, screenName, labelName, filterName) => {
  return {
    type: CHANGE_SEARCH_NAME_SELECTED_LIST,
    payload: {selectedList, screenName, labelName, filterName},
  }
}

export const clearSearchNameList = (screenName, labelName, filterName) => {
  return {
    type: CLEAR_SEARCH_NAME_LIST,
    payload: {screenName, labelName, filterName},
  }
}

export const getGeneralStatisticsList = data => {
  return {
    type: GET_GENERALSTATISTICS_LIST,
    payload: data,
  }
}

export const getGeneralStatisticsListSuccess = data => {
  return {
    type: GET_GENERALSTATISTICS_LIST_SUCCESS,
    payload: data,
  }
}

export const downStatisticsExcel = (data, fileName) => {
  return {
    type: DOWN_STATISTICS_EXCEL,
    payload: {data, fileName},
  }
}

export const downStatisticsExcelSuccess = () => {
  return {
    type: DOWN_STATISTICS_EXCEL_SUCCESS,
  }
}

export const downStatisticsPdf = (data, fileName) => {
  return {
    type: DOWN_STATISTICS_PDF,
    payload: {data, fileName},
  }
}

export const downStatisticsPdfSuccess = () => {
  return {
    type: DOWN_STATISTICS_PDF_SUCCESS,
  }
}

export const clearGeneralStatisticsList = () => {
  return {
    type: CLEAR_GENERALSTATISTICS_LIST,
  }
}

export const setSearchNameList = data => {
  return {
    type: SET_SEARCHNAME_LIST,
    payload: data,
  }
}

export const setIsWarSearch = data => {
  return {
    type: SET_IS_WAR_SEARCH,
    payload: data,
  }
}
