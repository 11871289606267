import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Media,
    Collapse,
    Table,
    UncontrolledTooltip,
    Button,
    Input,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import SelectMultipleJurisdictions from '../../components/Filter/SelectMultipleJurisdictions';
import SelectMultipleCountry from '../../components/Filter/SelectMultipleCountry';
import PeriodBtn from '../../components/Filter/PeriodBtn';
import SearchName from '../../components/Filter/SearchName';
import WeCrestPagination from '../../components/WeCrestPagination'
import * as Yup from "yup";

import {
  getMyConnectedCaseSettings as onGetMyConnectedCaseSettings,
  saveMyConnectedCaseSetting as onSaveMyConnectedCaseSetting,
  getMyConnectedCaseList as onGetMyConnectedCaseList,
  getMyCaseManagementSettings as onGetMyCaseManagementSettings,
  saveMyCaseManagementSetting as onSaveMyCaseManagementSetting,
  changeMyConnectedCaseList as onChangeMyConnectedCaseList,
  getMyCaseManagementList as onGetMyCaseManagementList,
  changeMyCaseManagementList as onChangeMyCaseManagementList,
  downMyCaseManagementExcel as onDownMyCaseManagementExcel,
  downMultipleMyCaseManagementExcel as onDownMultipleMyCaseManagementExcel,
  downMultipleMyCaseManagementPdf as onDownMultipleMyCaseManagementPdf,
  addUserIrInfoClaim as onAddUserIrInfoClaim,
  setMyCaseManagementSuccess as onSetSuccess,
  setMyCaseManagementResult as onSetResult,
} from "../../store/actions";

import '../../components/Common/Common.css';
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

const SearchMyCase = props => {
  const dispatch = useDispatch();
  const searchResultsRef = useRef(null);
  const searchMCCResultsRef = useRef(null);

  const { myEntityList
        , searchSettingsMyConnectedCase, myConnectedCaseListTotalPage, myConnectedCaseListNoticeStr, myConnectedCaseList, isSearchMCC
        , searchSettings, myCaseManagementListTotalPage, myCaseManagementListNoticeStr, myCaseManagementList, isSearch} = useSelector(state => ({
    
    myEntityList: state.EntityConnectManagement.myEntityList,

    searchSettingsMyConnectedCase: state.MyCaseManagement.searchSettingsMyConnectedCase,
    myConnectedCaseListTotalPage: state.MyCaseManagement.myConnectedCaseListTotalPage,
    myConnectedCaseListNoticeStr: state.MyCaseManagement.myConnectedCaseListNoticeStr,
    myConnectedCaseList: state.MyCaseManagement.myConnectedCaseList,
    isSearchMCC: state.MyCaseManagement.isSearchMCC,

    searchSettings: state.MyCaseManagement.searchSettings,
    myCaseManagementListTotalPage: state.MyCaseManagement.myCaseManagementListTotalPage,
    myCaseManagementListNoticeStr: state.MyCaseManagement.myCaseManagementListNoticeStr,
    myCaseManagementList: state.MyCaseManagement.myCaseManagementList,
    isSearch: state.MyCaseManagement.isSearch,
  }));

  const [multiClaimList, setMultiClaimList] = useState([]);
  const [claimIrNumber, setClaimIrNumber] = useState(0);
  const [claimFtpDate, setClaimFtpDate] = useState('');
  const [claimCrCode, setClaimCrCode] = useState('');
  const [claimType, setClaimType] = useState("");
  const [claimDomain, setClaimDomain] = useState("");
  const [claimMemo, setClaimMemo] = useState("");
  const [claimTargetGroupNo, setClaimTargetGroupNo] = useState(0);
  const [claimRepRankCode, setClaimRepRankCode] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  const [myConnectedCaseListColumns, setMyConnectedCaseListColumns] = useState([
    {label: "Representative", asc:"RIA", desc:"RID", ascActive:"", descActive:""},
    {label: "Holder", asc:"HIA", desc:"HID", ascActive:"", descActive:""},
    {label: "IR no", asc:"IRA", desc:"IRD", ascActive:"", descActive:""},
    {label: "Jur", asc:"JRA", desc:"JRD", ascActive:"", descActive:""},
    {label: "Inscription\nUploaded", asc:"RDA", desc:"RDD", ascActive:"", descActive:"text-danger"},
    {label: "Trademark", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Action", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const [myConnectedCaseListCurPageInput, setMyConnectedCaseListCurPageInput] = useState(1);
  const [chkMyConnectedCaseListAllFlag, setChkMyConnectedCaseListAllFlag] = useState(false);

  const myConnectedCaseListCaseChk = () => {
    const _myConnectedCaseList = [];
    myConnectedCaseList.map((row, index) =>{
      if(row.checked === true){
        _myConnectedCaseList.push(row);
      }
    }); 

    if(_myConnectedCaseList.length === 0){
      dispatch(onSetResult("Please select a case."));
      return false;
    }

    return true;
  }

  const myConnectedCaseListRowCheck = (str, checked) => {
    let chkCnt = 0;

    const _myConnectedCaseList = [];
    myConnectedCaseList.map((row, index) =>{
        let _row = cloneDeep(row);

        if(str === "chkAll" || str === index){
            _row.checked = checked;
        }

        if(_row.checked === true){
          chkCnt++;
        }

        _myConnectedCaseList.push(_row);
    }); 


    if(myConnectedCaseList.length === chkCnt){
      setChkMyConnectedCaseListAllFlag(true);
    }else{
      setChkMyConnectedCaseListAllFlag(false);
    }

    dispatch(onChangeMyConnectedCaseList(_myConnectedCaseList));
  }

  const viewMyConnectedCaseList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > myConnectedCaseListTotalPage) {
        curPage = myConnectedCaseListTotalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      myConnectedCaseListColumns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setMyConnectedCaseListColumns(_columns);
    }

    filterValidation.setFieldValue("submitType", "viewMyConnectedCaseList");
    if(sortType){
      filterValidation.setFieldValue("myConnectedCaseListSortType", sortType);
    }
    if(curPage){
      filterValidation.setFieldValue("myConnectedCaseListCurPage", curPage);
      setMyConnectedCaseListCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("myConnectedCaseListCurPage", 1);
      setMyConnectedCaseListCurPageInput(1);
    }
    if(pageSize){
      filterValidation.setFieldValue("myConnectedCaseListPageSize", pageSize);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const [myCaseManagementListColumns, setMyCaseManagementListColumns] = useState([
    {label: "Representative", asc:"RIA", desc:"RID", ascActive:"", descActive:""},
    {label: "Holder", asc:"HIA", desc:"HID", ascActive:"", descActive:""},
    {label: "IR no", asc:"IRA", desc:"IRD", ascActive:"", descActive:""},
    {label: "Jur", asc:"JRA", desc:"JRD", ascActive:"", descActive:""},
    {label: "Inscription\nUploaded", asc:"RDA", desc:"RDD", ascActive:"", descActive:"text-danger"},
    {label: "Trademark", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Status", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Action", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const [myCaseManagementListCurPageInput, setMyCaseManagementListCurPageInput] = useState(1);
  const [chkMyCaseManagementListAllFlag, setChkMyCaseManagementListAllFlag] = useState(false);

  const myCaseManagementListChk = (claimType) => {
    const _myCaseManagementList = [];
    let notMatchClaimType = false;
    myCaseManagementList.map((row, index) =>{
      if(row.checked === true){
        if (claimType === 'multiConnect' && row.repConnectedUserNo !== '0' && notMatchClaimType === false) {
          notMatchClaimType = true;
        } else if (claimType === 'multiClaim' && row.repConnectedUserNo == '0' && notMatchClaimType === false) {
          notMatchClaimType = true;
        } else {
          _myCaseManagementList.push(row);
        }
      }
    }); 

    if(_myCaseManagementList.length === 0){
      dispatch(onSetResult("Please select a case."));
      return false;
    }

    if (notMatchClaimType) {
      dispatch(onSetResult("There are case(s) state that cannot be " +(claimType === 'multiConnect' ? "'Connect'" : "'Claim'")));
      return false;
    }

    setClaimType(claimType);
    setMultiClaimList(_myCaseManagementList);

    return true;
  }

  const myCaseManagementListRowCheck = (str, checked) => {
    let chkCnt = 0;

    const _myCaseManagementList = [];
    myCaseManagementList.map((row, index) =>{
        let _row = cloneDeep(row);

        if((str === "chkAll" || str === index) && row.myClaimType == ''){
            _row.checked = checked;
        }

        if(_row.checked === true){
          chkCnt++;
        }

        _myCaseManagementList.push(_row);
    }); 


    if(myCaseManagementList.length === chkCnt){
      setChkMyCaseManagementListAllFlag(true);
    }else{
      setChkMyCaseManagementListAllFlag(false);
    }

    dispatch(onChangeMyCaseManagementList(_myCaseManagementList));
  }

  const viewMyCaseManagementList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > myCaseManagementListTotalPage) {
        curPage = myCaseManagementListTotalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      myCaseManagementListColumns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setMyCaseManagementListColumns(_columns);
    }

    filterValidation.setFieldValue("submitType", "viewMyCaseManagementList");
    if(sortType){
      filterValidation.setFieldValue("myCaseManagementListSortType", sortType);
    }
    if(curPage){
      filterValidation.setFieldValue("myCaseManagementListCurPage", curPage);
      setMyCaseManagementListCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("myCaseManagementListCurPage", 1);
      setMyCaseManagementListCurPageInput(1);
    }
    if(pageSize){
      filterValidation.setFieldValue("myCaseManagementListPageSize", pageSize);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  function showSuccessCopyToast() {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    let message = "Copy clipboard!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title)
  }

  const unsecuredCopyToClipboard = (text, elmentId) => {
    const textArea = document.createElement("textarea");
    textArea.setAttribute("type", "hidden");
    textArea.value = text;

    // const node = document.getElementById(elmentId).lastElementChild;
    document.getElementById(elmentId).appendChild(textArea);
    // document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.getElementById(elmentId).removeChild(textArea);
  }

  const onImgLoad = ({ target: img }) => {
    const { offsetHeight, offsetWidth } = img;
    if (offsetWidth > 100) {
      img.width = 100;
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [isOpenMyConnectedCase, setIsOpenMyConnectedCase] = useState(true);
  const toggleMyConnectedCase = () => {
    setIsOpenMyConnectedCase(!isOpenMyConnectedCase);
  }

  const [isOpenMyConnectedCaseResults, setIsOpenMyConnectedCaseResults] = useState(true);
  const toggleMyConnectedCaseResults = () => {
    setIsOpenMyConnectedCaseResults(!isOpenMyConnectedCaseResults);
  }
  
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [isOpenResults, setIsOpenResults] = useState(true);
  const toggleResults = () => {
    setIsOpenResults(!isOpenResults);
  }

  const [idnmStatus, setIdnmStatus] = useState('Y');
  const [clickSearchBtn, setClickSearchBtn] = useState(false);
  const [clickSearchBtnMCC, setClickSearchBtnMCC] = useState(false);
  
  const validateDate = (values, props /* only available when using withFormik */) => {
    const errors = {};
  
    if(values["submitType"] === "viewMyConnectedCaseList"){
      if (!values.fromDateMyConnectedCase) {
        errors.fromDateMyConnectedCase = 'Please Select from date';
      } 

      if (!values.toDateMyConnectedCase) {
        errors.toDateMyConnectedCase = 'Please Select to date';
      } 

      if (values.fromDateMyConnectedCase && values.toDateMyConnectedCase) {
        let _fromDate = new Date(Number(values.fromDateMyConnectedCase.split('-')[0]), Number(values.fromDateMyConnectedCase.split('-')[1]), Number(values.fromDateMyConnectedCase.split('-')[2]));
        let _toDate = new Date(Number(values.toDateMyConnectedCase.split('-')[0]), Number(values.toDateMyConnectedCase.split('-')[1]), Number(values.toDateMyConnectedCase.split('-')[2]));

        // console.log('_fromDate : ' + _fromDate + ', _toDate : ' + _toDate);
        if (_fromDate > _toDate) {
          errors.fromDateMyConnectedCase = 'From date must be earlier than to date';
        }
      }
    } else if(values["submitType"] === "viewMyCaseManagementList"){
      if (!values.fromDate) {
        errors.fromDate = 'Please Select from date';
      } 

      if (!values.toDate) {
        errors.toDate = 'Please Select to date';
      } 

      if (values.fromDate && values.toDate) {
        let _fromDate = new Date(Number(values.fromDate.split('-')[0]), Number(values.fromDate.split('-')[1]), Number(values.fromDate.split('-')[2]));
        let _toDate = new Date(Number(values.toDate.split('-')[0]), Number(values.toDate.split('-')[1]), Number(values.toDate.split('-')[2]));

        // console.log('_fromDate : ' + _fromDate + ', _toDate : ' + _toDate);
        if (_fromDate > _toDate) {
          errors.fromDate = 'From date must be earlier than to date';
        }
      }
    }
  
    return errors;
  };

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      screenName: "MyCaseManagement",
      submitType: "view",
      jurTypeMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.jurType? searchSettingsMyConnectedCase.jurType : "",
      jrCdListMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.jurList? searchSettingsMyConnectedCase.jurList : [],
      searchHolderNoListMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.searchHolderNoList? searchSettingsMyConnectedCase.searchHolderNoList : [],
      searchHolderKeywordMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.searchHolderKeyword? searchSettingsMyConnectedCase.searchHolderKeyword : "",
      searchHolderNameListMyConnectedCase: [],
      holderTypeMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.holderType? searchSettingsMyConnectedCase.holderType : "",
      holderCdListMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.holderCdList? searchSettingsMyConnectedCase.holderCdList : [],
      irNumberMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.irNumber? searchSettingsMyConnectedCase.irNumber : "",
      dateTypeMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.dateType? searchSettingsMyConnectedCase.dateType : "Inscribed",
      datePeriodMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.datePeriod? searchSettingsMyConnectedCase.datePeriod : "",
      fromDateMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.startDate? searchSettingsMyConnectedCase.startDate : "",
      toDateMyConnectedCase: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.endDate? searchSettingsMyConnectedCase.endDate : "",
      changeDatePeriodIsFirst: searchSettingsMyConnectedCase && searchSettingsMyConnectedCase.datePeriod? true : false,

      jurType: searchSettings && searchSettings.jurType? searchSettings.jurType : "",
      jrCdList: searchSettings && searchSettings.jurList? searchSettings.jurList : [],
      searchRepNoList: searchSettings && searchSettings.searchRepNoList? searchSettings.searchRepNoList : [],
      searchRepKeyword: searchSettings && searchSettings.searchRepKeyword? searchSettings.searchRepKeyword : "",
      searchRepNameList: [],
      searchHolderNoList: searchSettings && searchSettings.searchHolderNoList? searchSettings.searchHolderNoList : [],
      searchHolderKeyword: searchSettings && searchSettings.searchHolderKeyword? searchSettings.searchHolderKeyword : "",
      searchHolderNameList: [],
      holderType: searchSettings && searchSettings.holderType? searchSettings.holderType : "",
      holderCdList: searchSettings && searchSettings.holderCdList? searchSettings.holderCdList : [],
      irNumber: searchSettings && searchSettings.irNumber? searchSettings.irNumber : "",
      dateType: searchSettings && searchSettings.dateType? searchSettings.dateType : "Inscribed",
      datePeriod: searchSettings && searchSettings.datePeriod? searchSettings.datePeriod : "",
      fromDate: searchSettings && searchSettings.startDate? searchSettings.startDate : "",
      toDate: searchSettings && searchSettings.endDate? searchSettings.endDate : "",

      searchEntity: "",
      searchDomain: "",
      searchStatus: "",

      myConnectedCaseListSortType: "",
      myConnectedCaseListCurPage: 1,
      myConnectedCaseListPageSize: 20,

      myCaseManagementListSortType: "",
      myCaseManagementListCurPage: 1,
      myCaseManagementListPageSize: 20,
    },
    // validationSchema: Yup.object().shape({
    //   fromDateMyConnectedCase: Yup.date()
    //     .max(Yup.ref('toDateMyConnectedCase'), "From date must be earlier than to date")
    //     .required("Please Select From date"),
    //   toDateMyConnectedCase: Yup.date()
    //     .required("Please Select to date"),

    //   fromDate: Yup.date()
    //     .max(Yup.ref('toDate'), "From date must be earlier than to date")
    //     .required("Please Select From date"),
    //   toDate: Yup.date()
    //     .required("Please Select to date"),
    // }),
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    onSubmit: (values, {setErrors}) => {

      // console.log('values : ' + JSON.stringify(values));

      let sigError = validateDate(values);
      if (JSON.stringify(sigError) != '{}') {
        setErrors(sigError);

        return;
      }

      // MyConnectedCase
      let _fromDateStrMyConnectedCase = values["fromDateMyConnectedCase"].replace(/-/gi,"/");
      let _toDateStrMyConnectedCase = values["toDateMyConnectedCase"].replace(/-/gi,"/");
      let newEndDateMyConnectedCase = new Date(values["toDateMyConnectedCase"]);
      newEndDateMyConnectedCase.setDate(newEndDateMyConnectedCase.getDate() + 1);
      
      let periodMyConnectedCase = Math.abs(newEndDateMyConnectedCase.getTime() - new Date(values["fromDateMyConnectedCase"]).getTime());
      periodMyConnectedCase = Math.ceil(periodMyConnectedCase / (1000 * 3600 * 24));
      
      let _periodstrMyConnectedCase = periodMyConnectedCase +" day(s)";

      let _noticeStrMyConnectedCase = "My Connected cases for period: "+_periodstrMyConnectedCase+" ["+_fromDateStrMyConnectedCase+" to "+_toDateStrMyConnectedCase+"]";
      let _timePeriodMyConnectedCase = _fromDateStrMyConnectedCase + " to " +_toDateStrMyConnectedCase + " [" + _periodstrMyConnectedCase + "]";

      const filterParamMyConnectedCase = {
        jurType: values["jurTypeMyConnectedCase"],
        jurList: values["jurTypeMyConnectedCase"] === "All" || values["jurTypeMyConnectedCase"] === "All-subs"? [] : values["jrCdListMyConnectedCase"],
        searchHolderNoList: values["searchHolderNoListMyConnectedCase"],
        searchHolderNameList: values["searchHolderNameListMyConnectedCase"],
        holderType: values["holderTypeMyConnectedCase"],
        holderCdList: values["holderTypeMyConnectedCase"] === "All" || values["holderTypeMyConnectedCase"] === "All-subs"? [] : values["holderCdListMyConnectedCase"],
        irNumber: values["irNumberMyConnectedCase"].trim(),
        dateType: values["dateTypeMyConnectedCase"],
        startDate: values["fromDateMyConnectedCase"],
        endDate: values["toDateMyConnectedCase"],
        sortType: values["myConnectedCaseListSortType"],
        curPage: values["myConnectedCaseListCurPage"],
        pageSize: values["myConnectedCaseListPageSize"],
        noticeStr: _noticeStrMyConnectedCase,
        timePeriod: _timePeriodMyConnectedCase,
        searchConnectedCaseYn: 'Y',
      };

      let fileNameMyConnectedCase = dateFormat(new Date(), "yymmdd") + " WeCrest My Connected Case (" + values["fromDateMyConnectedCase"].replace(/-/gi,"") + "-" + values["toDateMyConnectedCase"].replace(/-/gi,"") + ")"; 



      // MyCaseManagement
      let _fromDateStr = values["fromDate"].replace(/-/gi,"/");
      let _toDateStr = values["toDate"].replace(/-/gi,"/");
      let newEndDate = new Date(values["toDate"]);
      newEndDate.setDate(newEndDate.getDate() + 1);
      
      let period = Math.abs(newEndDate.getTime() - new Date(values["fromDate"]).getTime());
      period = Math.ceil(period / (1000 * 3600 * 24));
      
      let _periodstr = period +" day(s)";

      let _noticeStr = "My Case Management for period: "+_periodstr+" ["+_fromDateStr+" to "+_toDateStr+"]";
      let _timePeriod = _fromDateStr + " to " +_toDateStr + " [" + _periodstr + "]";
      const filterParam = {
        jurType: values["jurType"],
        jurList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
        searchRepNoList: values["searchRepNoList"],
        searchRepNameList: values["searchRepNameList"],
        searchHolderNoList: values["searchHolderNoList"],
        searchHolderNameList: values["searchHolderNameList"],
        holderType: values["holderType"],
        holderCdList: values["holderType"] === "All" || values["holderType"] === "All-subs"? [] : values["holderCdList"],
        irNumber: values["irNumber"].trim(),
        dateType: values["dateType"],
        startDate: values["fromDate"],
        endDate: values["toDate"],
        sortType: values["myCaseManagementListSortType"],
        curPage: values["myCaseManagementListCurPage"],
        pageSize: values["myCaseManagementListPageSize"],
        noticeStr: _noticeStr,
        timePeriod: _timePeriod,
      };

      let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest My Case Management (" + values["fromDate"].replace(/-/gi,"") + "-" + values["toDate"].replace(/-/gi,"") + ")"; 
      
      // console.log(JSON.stringify(values));
      let submitType = values["submitType"];
      
      if(submitType === "viewMyConnectedCaseList"){
        dispatch(onGetMyConnectedCaseList(filterParamMyConnectedCase));
      } else if(submitType === "saveSettingsMyConnectedCase"){
        const settingsParam = {
          jurType: values["jurTypeMyConnectedCase"],
          jurListToStr: values["jurTypeMyConnectedCase"] === "Individual" ? values["jrCdListMyConnectedCase"].join(", ") : "",
          searchHolderNoListToStr : (values["searchHolderNoListMyConnectedCase"] === '' ? "" : values["searchHolderNoListMyConnectedCase"].join(", ")),
          searchHolderKeyword: values["searchHolderKeywordMyConnectedCase"],
          holderType: values["holderTypeMyConnectedCase"],
          holderCdListToStr: values["holderTypeMyConnectedCase"] === "Individual" ? values["holderCdListMyConnectedCase"].join(", ") : "",
          irNumber: values["irNumberMyConnectedCase"],
          dateType: values["dateTypeMyConnectedCase"],
          datePeriod: values["datePeriodMyConnectedCase"],
        };
        dispatch(onSaveMyConnectedCaseSetting(settingsParam));
      } else if(submitType === "excelMyConnectedCase") {
        dispatch(onDownMyCaseManagementExcel(filterParamMyConnectedCase, fileNameMyConnectedCase));
      } else if(submitType === "viewMyCaseManagementList"){
        dispatch(onGetMyCaseManagementList(filterParam));
      } else if(submitType === "saveSettings"){
        const settingsParam = {
          jurType: values["jurType"],
          jurListToStr: values["jurType"] === "Individual" ? values["jrCdList"].join(", ") : "",
          searchRepNoListToStr : (values["searchRepNoList"] === '' ? "" : values["searchRepNoList"].join(", ")),
          searchRepKeyword: values["searchRepKeyword"],
          searchHolderNoListToStr : (values["searchHolderNoList"] === '' ? "" : values["searchHolderNoList"].join(", ")),
          searchHolderKeyword: values["searchHolderKeyword"],
          holderType: values["holderType"],
          holderCdListToStr: values["holderType"] === "Individual" ? values["holderCdList"].join(", ") : "",
          irNumber: values["irNumber"],
          dateType: values["dateType"],
          datePeriod: values["datePeriod"],
        };
        dispatch(onSaveMyCaseManagementSetting(settingsParam));
      } else if(submitType === "excel"){
        dispatch(onDownMyCaseManagementExcel(filterParam, fileName));
      } else if(submitType === "multipleExcel"){
        const _madridNotifier = [];
        myConnectedCaseList.map((row, index) =>{
          if(row.checked === true){
            _madridNotifier.push(row);
          }
        });
        
        dispatch(onDownMultipleMyCaseManagementExcel(_madridNotifier, myConnectedCaseListNoticeStr, fileNameMyConnectedCase));
      } else if(submitType === "multiplePdf"){
        const _madridNotifier = [];
        myConnectedCaseList.map((row, index) =>{
          if(row.checked === true){
            _madridNotifier.push(row);
          }
        });

        if (_madridNotifier.length > 50) {
          // Select up to 50 cases to download a report in PDF format
          dispatch(onSetResult("Select up to 50 cases to download a report in PDF format"));
        } else {
          const param = {
            madridNotifierList: _madridNotifier,
            jurType: values["jurType"],
            jurList: values["jurType"] === "All" || values["jurType"] === "All-subs"? [] : values["jrCdList"],
            searchRepNoList: values["searchRepNoList"],
            searchRepNameList: values["searchRepNameList"],
            searchHolderNoList: values["searchHolderNoList"],
            searchHolderNameList: values["searchHolderNameList"],
            holderType: values["holderType"],
            holderCdList: values["holderType"] === "All" || values["holderType"] === "All-subs"? [] : values["holderCdList"],
            irNumber: values["irNumber"],
            dateType: values["dateType"],
            timePeriod: _timePeriod,
          }
          
          dispatch(onDownMultipleMyCaseManagementPdf(param, fileNameMyConnectedCase));
        }
      } else if (submitType === "multiConnect" || submitType === "multiClaim") {
        const _claimList = [];
        myCaseManagementList.map((row, index) =>{
          if(row.checked === true){
            _claimList.push({irNumber: row.irNumber
              , ftpDate: row.ftpDate
              , crCode: row.crCode
              , type: submitType === "multiConnect" ? "Connect" : "Claim"
              , requestedDomain: ""
              , userMemo: claimMemo
              , requestedTargetGroupNo: row.representativeGroupNo
              , repRankCode: row.repRankCode});
          }
        }); 

        dispatch(onAddUserIrInfoClaim(_claimList, submitType, filterParam, filterParamMyConnectedCase));

        setClaimIrNumber(0);
        setClaimFtpDate('');
        setClaimCrCode('');
        setClaimDomain('');
        setClaimMemo('');
        setClaimTargetGroupNo(0);
        setClaimType('');
        setChkMyCaseManagementListAllFlag(false);
      } else if (submitType === "NotMycase" || submitType === "Connect" || submitType === "Claim") {
        dispatch(onAddUserIrInfoClaim([{irNumber: claimIrNumber, ftpDate: claimFtpDate, crCode: claimCrCode, type: claimType, requestedDomain: claimDomain, userMemo: claimMemo, requestedTargetGroupNo: claimTargetGroupNo, repRankCode: claimRepRankCode}], claimType, filterParam, filterParamMyConnectedCase));

        setClaimIrNumber(0);
        setClaimFtpDate('');
        setClaimCrCode('');
        setClaimDomain('');
        setClaimMemo('');
        setClaimTargetGroupNo(0);
        setClaimType('');
      }
    }
  })

  useEffect(() => { 
    if (clickSearchBtn && isSearch && myCaseManagementList && myCaseManagementList.length !== 0 && searchResultsRef && searchResultsRef.current) {
      searchResultsRef.current.scrollIntoView()    
      setClickSearchBtn(false);
    }
  }, [isSearch]);

  useEffect(() => { 
    if (clickSearchBtnMCC && isSearchMCC && myConnectedCaseList && myConnectedCaseList.length !== 0 && searchMCCResultsRef && searchMCCResultsRef.current) {
      searchMCCResultsRef.current.scrollIntoView()    
      setClickSearchBtnMCC(false);
    }
  }, [isSearchMCC]);

  useEffect(() => { 
    dispatch(onGetMyConnectedCaseSettings());
    dispatch(onGetMyCaseManagementSettings());
    // dispatch(onGetMyConnectedCaseList({searchConnectedCaseYn: 'Y'}));
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggleMyConnectedCase} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">My cases</h5>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpenMyConnectedCase}>
              <div className="p-4 border-top">
                <SelectMultipleJurisdictions
                  formik={filterValidation}
                  filterName='myConnectedCase'
                  subscriptionType="JN"
                />

                <SearchName
                  formik={filterValidation}
                  labelName='Name of the holder'
                  filterName='myConnectedCase'
                />
                
                <SelectMultipleCountry
                  formik={filterValidation}
                  componentName='Holder'
                  filterName='myConnectedCase'
                  subscriptionType="JN"
                />

                <Row className="mb-3">
                  <label
                  htmlFor="irNumberMyConnectedCase"
                  className="col-md-3 col-form-label"
                  >
                  IR number
                  </label>
                  <div className="col-md-3">
                  <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter IR number"
                      id="irNumberMyConnectedCase"
                      name="irNumberMyConnectedCase"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.irNumberMyConnectedCase}
                      onKeyPress={(e)=>{
                        if (e.key === 'Enter') {
                          setClickSearchBtnMCC(true);
                          setMyConnectedCaseListCurPageInput(1);
                          filterValidation.setFieldValue("myConnectedCaseListCurPage", 1);
                          filterValidation.setFieldValue("submitType", "viewMyConnectedCaseList");
                          filterValidation.handleSubmit(filterValidation.values);
                        }
                      }}
                  />
                  </div>
                </Row>

                <Row>
                  <label htmlFor="inscribedDate" className="col-md-3 col-form-label" style={{paddingTop : '0px'}}>
                    <select 
                      className="form-select" 
                      id="dateTypeMyConnectedCase"
                      name="dateTypeMyConnectedCase"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.dateTypeMyConnectedCase}
                      style={{width : '50%'}}
                    >
                      <option value="Inscribed">Inscription date</option>
                      <option value="Uploaded">Uploaded date</option>
                    </select>
                  </label>
                  <div className="col-md-9">
                    <PeriodBtn
                      formik={filterValidation}
                      filterName='myConnectedCase'
                    />
                  </div>
                </Row>
                
                <Row className="mb-3">
                  <span className="col-md-6 button-items">
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("submitType", "saveSettingsMyConnectedCase");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                    >
                      Save settings
                    </Button>
                    
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("submitType", "excelMyConnectedCase");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                      id="btn_down_excel_1_1"
                    >
                      Download Excel
                    </Button>
                    <UncontrolledTooltip placement="top" target="btn_down_excel_1_1">
                    Download details of all cases based on the filter as an Excel file (limit 1000 cases)
                    </UncontrolledTooltip>
                  </span>
                  <Col md={3} style={{paddingLeft : '1.3em'}}>
                    <button
                      type="button"
                      className="waves-effect waves-light btn btn-primary"
                      style={{width : '148.58px'}}
                      onClick={()=>{
                        setClickSearchBtnMCC(true);
                        setMyConnectedCaseListCurPageInput(1);
                        filterValidation.setFieldValue("myConnectedCaseListCurPage", 1);
                        filterValidation.setFieldValue("submitType", "viewMyConnectedCaseList");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                    >
                      View cases
                    </button>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>

      <div ref={searchMCCResultsRef}></div> 
      {myConnectedCaseListNoticeStr && myConnectedCaseListNoticeStr !== '' ?
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="text-muted mb-0">
                <p style={{whiteSpace : "pre"}}>
                  {myConnectedCaseListNoticeStr}
                </p>
                <Row className="mb-3">
                  <span className="col-md-8 button-items">
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={() => {
                        if(myConnectedCaseListCaseChk()){
                          filterValidation.setFieldValue("submitType", "multipleExcel");
                          filterValidation.handleSubmit(filterValidation.values);
                        }
                      }}
                      id="btn_down_excel_2_1"
                    >
                      Download Excel
                    </Button>
                    <UncontrolledTooltip placement="top" target="btn_down_excel_2_1">
                    Download details of selected cases as an Excel file
                    </UncontrolledTooltip>
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={() => {
                        if(myConnectedCaseListCaseChk()){
                          filterValidation.setFieldValue("submitType", "multiplePdf");
                          filterValidation.handleSubmit(filterValidation.values);
                        }
                      }}
                      id="btn_down_pdf_2_1"
                    >
                      Download PDF
                    </Button>
                    <UncontrolledTooltip placement="top" target="btn_down_pdf_2_1">
                    Download details of selected cases as a PDF file
                    </UncontrolledTooltip>
                  </span>
                </Row>
              </CardTitle>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                    <th style={{verticalAlign: "middle"}}><input type="checkbox" checked={chkMyConnectedCaseListAllFlag} onClick={(e)=>myConnectedCaseListRowCheck("chkAll", e.target.checked)} /></th>
                    {
                      myConnectedCaseListColumns && myConnectedCaseListColumns.length ?
                      myConnectedCaseListColumns.map((col, index) => {
                          return (
                            <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                              {col.label}
                              {col.asc && col.desc? 
                              <>
                              <Link to='#' onClick={()=> {viewMyConnectedCaseList(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                              <Link to='#' onClick={()=> {viewMyConnectedCaseList(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                              </>
                              :null}
                            </th>
                          )
                      })
                      :null
                    }
                    </tr>
                  </thead>
                  <tbody>
                  {
                    myConnectedCaseList && myConnectedCaseList.length ?
                    myConnectedCaseList.map((madrid, index) => {
                      return (
                          <tr key={index}>
                            <td style={{verticalAlign: "middle"}}>
                              <input type="checkbox" 
                                checked={madrid.checked}
                                onClick={(e)=>myConnectedCaseListRowCheck(index, e.target.checked)}
                                />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                              {
                              madrid.representativeInfo && madrid.representativeInfo !== '' ?
                              <>
                              <Row>
                              <div className="d-flex vertical-align-center gap-2">
                              <p className='multiline' id={"rep_info_"+index}>
                                {madrid.representativeInfo}
                              </p>
                              <UncontrolledTooltip placement="top" target={"rep_info_"+index}>
                                {madrid.representativeInfo}
                              </UncontrolledTooltip>
                              <Link to="#" id={"rep_copy_"+index}
                                onClick={()=>{
                                  if (window.isSecureContext && navigator.clipboard) {
                                    navigator.clipboard.writeText(madrid.representativeInfo);
                                  } else {
                                    unsecuredCopyToClipboard(madrid.representativeInfo, "rep_copy_"+index);
                                  }
                                  showSuccessCopyToast();
                                }}>
                                <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                              </Link>
                              <UncontrolledTooltip placement="top" target={"rep_copy_"+index}>
                                Copy Representative's information to clipboard
                              </UncontrolledTooltip>
                              </div>
                              </Row>
                              </>
                              :
                              <p className='multiline'></p>
                              }
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                              {
                              madrid.holderInfo && madrid.holderInfo !== '' ?
                              <>
                              <Row>
                              <div className="d-flex vertical-align-center gap-2">
                                <p className='multiline' id={"hol_info_"+index}>{madrid.holderInfo}</p>
                                <UncontrolledTooltip placement="top" target={"hol_info_"+index}>
                                  {madrid.holderInfo}
                                </UncontrolledTooltip>
                                <Link to="#" id={"hol_copy_"+index}
                                  onClick={()=>{
                                    if (window.isSecureContext && navigator.clipboard) {
                                      navigator.clipboard.writeText(madrid.holderInfo);
                                    } else {
                                      // console.log('old copy clipboard!');
                                      unsecuredCopyToClipboard(madrid.holderInfo, "hol_copy_"+index);
                                    }
                                    showSuccessCopyToast();
                                  }}>
                                  <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                </Link>
                                <UncontrolledTooltip placement="top" target={"hol_copy_"+index}>
                                  Copy Holder's information to clipboard
                                </UncontrolledTooltip>
                              </div>
                              </Row>
                              </>
                              :
                              <p className='multiline'></p>
                              }
                            </td>
                            <td style={{verticalAlign: "middle"}} align="center">
                              <Row className="d-flex horizon-align-center">
                                {madrid.irNumber}
                              </Row>
                            </td>
                            <td style={{verticalAlign: "middle"}} align="center">{madrid.crCode}</td>
                            <td style={{verticalAlign: "middle"}} align="center">{madrid.recordedDate}<br/>{madrid.druSendDate}</td>
                            <td style={{verticalAlign: "middle"}} align="center" width='10%'>
                              {madrid.tmStorageUrl && madrid.tmStorageUrl.startsWith('https:') ? 
                                <img
                                  id={"tm_img_"+index}
                                  src={madrid.tmStorageUrl}
                                  alt=""
                                  className="img-fluid mx-auto d-block tab-img rounded"
                                  referrerpolicy="no-referrer"
                                  onLoad={onImgLoad}
                                />
                                : madrid.tmTxt.replace('&#36;', '$')}
                            </td>
                            <td style={{verticalAlign: "middle"}} align="center">
                              {
                                madrid.myClaimType == 'NotMycase' ?
                                  <p className="text-danger">Pending: You raised an issue with this case, it’s not mine</p>
                                :
                                  <button type="button" class="waves-effect waves-light btn btn-outline-warning"
                                    onClick={() => {
                                      setClaimType("NotMycase");
                                      setClaimIrNumber(madrid.irNumber);
                                      setClaimFtpDate(madrid.ftpDate);
                                      setClaimCrCode(madrid.crCode);
                                      setClaimRepRankCode(madrid.repRankCode);
                                    }}>
                                    <i className="uil uil-times font-size-14"></i> Not My case
                                  </button>
                              }
                            </td>
                          </tr>
                      )
                    })
                    : null
                    }
                  </tbody>
                </Table>
              </div>

              <WeCrestPagination 
                viewList={viewMyConnectedCaseList} 
                pageSize={filterValidation.values.myConnectedCaseListPageSize} 
                curPage={filterValidation.values.myConnectedCaseListCurPage}
                totalPage={myConnectedCaseListTotalPage}
                curPageInput={myConnectedCaseListCurPageInput}
                setCurPageInput={setMyConnectedCaseListCurPageInput}
                pageSizeList={[10, 20, 30, 40, 50]}
              />

            </CardBody>
          </Card>
        </Col>
      </Row>
      : null}

      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Search</h5>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                <SelectMultipleJurisdictions
                  formik={filterValidation}
                  filterName='myCaseManagement'
                  subscriptionType="JN"
                />

                <SearchName
                  formik={filterValidation}
                  labelName='Name of the representative'
                  filterName='myCaseManagement'
                />

                <SearchName
                  formik={filterValidation}
                  labelName='Name of the holder'
                  filterName='myCaseManagement'
                />
                
                <SelectMultipleCountry
                  formik={filterValidation}
                  componentName='Holder'
                  filterName='myCaseManagement'
                  subscriptionType="JN"
                />

                <Row className="mb-3">
                  <label
                  htmlFor="irNumber"
                  className="col-md-3 col-form-label"
                  >
                  IR number
                  </label>
                  <div className="col-md-3">
                  <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter IR number"
                      id="irNumber"
                      name="irNumber"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.irNumber}
                      onKeyPress={(e)=>{
                        if (e.key === 'Enter') {
                          setClickSearchBtn(true);
                          setMyCaseManagementListCurPageInput(1);
                          filterValidation.setFieldValue("curPage", 1);
                          filterValidation.setFieldValue("submitType", "viewMyCaseManagementList");
                          filterValidation.handleSubmit(filterValidation.values);
                        }
                      }}
                  />
                  </div>
                </Row>

                <Row>
                  <label htmlFor="inscribedDate" className="col-md-3 col-form-label" style={{paddingTop : '0px'}}>
                    <select 
                      className="form-select" 
                      id="dateType"
                      name="dateType"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.dateType}
                      style={{width : '50%'}}
                    >
                      <option value="Inscribed">Inscription date</option>
                      <option value="Uploaded">Uploaded date</option>
                    </select>
                  </label>
                  <div className="col-md-9">
                    <PeriodBtn
                      formik={filterValidation}
                    />
                  </div>
                </Row>
                
                <Row className="mb-3">
                  <span className="col-md-6 button-items">
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("submitType", "saveSettings");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                    >
                      Save settings
                    </Button>
                    
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="waves-effect waves-light"
                      onClick={()=>{
                        filterValidation.setFieldValue("submitType", "excel");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                      id="btn_down_excel_1_1"
                    >
                      Download Excel
                    </Button>
                    <UncontrolledTooltip placement="top" target="btn_down_excel_1_1">
                    Download details of all cases based on the filter as an Excel file (limit 1000 cases)
                    </UncontrolledTooltip>
                  </span>
                  <Col md={3} style={{paddingLeft : '1.3em'}}>
                    <button
                      type="button"
                      className="waves-effect waves-light btn btn-primary"
                      style={{width : '148.58px'}}
                      onClick={()=>{
                        setClickSearchBtn(true);
                        setMyCaseManagementListCurPageInput(1);
                        filterValidation.setFieldValue("curPage", 1);
                        filterValidation.setFieldValue("submitType", "viewMyCaseManagementList");
                        filterValidation.handleSubmit(filterValidation.values);
                      }}
                    >
                      View cases
                    </button>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>

      <div ref={searchResultsRef}></div> 
      {myCaseManagementList && myCaseManagementList.length !== 0 ? 
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggleResults} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Search results</h5>
                  </div>
                  <i className={isOpenResults ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpenResults}>
              <div className="p-4 border-top">
                <Row>
                  <Col lg={12}>
                    <Card className="mb-2">
                      <CardBody>
                      <CardTitle className="text-muted mb-0">
                        <p style={{whiteSpace : "pre"}}>
                          {myCaseManagementListNoticeStr}
                        </p>
                        <Row className="mb-3">
                          <span className="col-md-8 button-items">
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                myCaseManagementListChk('multiConnect')
                              }}
                              id="btn_down_excel_2_1"
                            >
                              Connect
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_excel_2_1">
                            Connect of selected cases
                            </UncontrolledTooltip>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light"
                              onClick={() => {
                                myCaseManagementListChk('multiClaim')
                              }}
                              id="btn_down_pdf_2_1"
                            >
                              Claim
                            </Button>
                            <UncontrolledTooltip placement="top" target="btn_down_pdf_2_1">
                            Claim of selected cases
                            </UncontrolledTooltip>
                          </span>
                        </Row>
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            <th style={{verticalAlign: "middle"}}><input type="checkbox" checked={chkMyCaseManagementListAllFlag} onClick={(e)=>myCaseManagementListRowCheck("chkAll", e.target.checked)} /></th>
                            {
                              myCaseManagementListColumns && myCaseManagementListColumns.length ?
                              myCaseManagementListColumns.map((col, index) => {
                                  return (
                                    <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                                      {col.label}
                                      {col.asc && col.desc? 
                                      <>
                                      <Link to='#' onClick={()=> {viewMyCaseManagementList(col.asc)}} className={col.ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                                      <Link to='#' onClick={()=> {viewMyCaseManagementList(col.desc)}} className={col.descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                                      </>
                                      :null}
                                    </th>
                                  )
                              })
                              :null
                            }
                            </tr>
                          </thead>
                          <tbody>
                          {
                            myCaseManagementList && myCaseManagementList.length ?
                            myCaseManagementList.map((madrid, index) => {
                              return (
                                  <tr key={index}>
                                    <td style={{verticalAlign: "middle"}}>
                                      <input type="checkbox" 
                                        checked={madrid.checked}
                                        disabled={madrid.myClaimType != ''}
                                        onClick={(e)=>myCaseManagementListRowCheck(index, e.target.checked)}
                                        />
                                    </td>
                                    <td style={{verticalAlign: "middle"}}>
                                      {
                                      madrid.representativeInfo && madrid.representativeInfo !== '' ?
                                      <>
                                      <Row>
                                      <div className="d-flex vertical-align-center gap-2">
                                      <p className='multiline' id={"rep_info_"+index}>
                                        {madrid.representativeInfo}
                                      </p>
                                      <UncontrolledTooltip placement="top" target={"rep_info_"+index}>
                                        {madrid.representativeInfo}
                                      </UncontrolledTooltip>
                                      <Link to="#" id={"rep_copy_"+index}
                                        onClick={()=>{
                                          if (window.isSecureContext && navigator.clipboard) {
                                            navigator.clipboard.writeText(madrid.representativeInfo);
                                          } else {
                                            unsecuredCopyToClipboard(madrid.representativeInfo, "rep_copy_"+index);
                                          }
                                          showSuccessCopyToast();
                                        }}>
                                        <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                      </Link>
                                      <UncontrolledTooltip placement="top" target={"rep_copy_"+index}>
                                        Copy Representative's information to clipboard
                                      </UncontrolledTooltip>
                                      </div>
                                      </Row>
                                      </>
                                      :
                                      <p className='multiline'></p>
                                      }
                                    </td>
                                    <td style={{verticalAlign: "middle"}}>
                                      {
                                      madrid.holderInfo && madrid.holderInfo !== '' ?
                                      <>
                                      <Row>
                                      <div className="d-flex vertical-align-center gap-2">
                                        <p className='multiline' id={"hol_info_"+index}>{madrid.holderInfo}</p>
                                        <UncontrolledTooltip placement="top" target={"hol_info_"+index}>
                                          {madrid.holderInfo}
                                        </UncontrolledTooltip>
                                        <Link to="#" id={"hol_copy_"+index}
                                          onClick={()=>{
                                            if (window.isSecureContext && navigator.clipboard) {
                                              navigator.clipboard.writeText(madrid.holderInfo);
                                            } else {
                                              unsecuredCopyToClipboard(madrid.holderInfo, "hol_copy_"+index);
                                            }
                                            showSuccessCopyToast();
                                          }}>
                                          <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                        </Link>
                                        <UncontrolledTooltip placement="top" target={"hol_copy_"+index}>
                                          Copy Holder's information to clipboard
                                        </UncontrolledTooltip>
                                      </div>
                                      </Row>
                                      </>
                                      :
                                      <p className='multiline'></p>
                                      }
                                    </td>
                                    <td style={{verticalAlign: "middle"}} align="center">
                                      <Row className="d-flex horizon-align-center">
                                        {madrid.irNumber}
                                      </Row>
                                    </td>
                                    <td style={{verticalAlign: "middle"}} align="center">{madrid.crCode}</td>
                                    <td style={{verticalAlign: "middle"}} align="center">{madrid.recordedDate}<br/>{madrid.druSendDate}</td>
                                    <td style={{verticalAlign: "middle"}} align="center" width='10%'>
                                      {madrid.tmStorageUrl && madrid.tmStorageUrl.startsWith('https:') ? 
                                        <img
                                          id={"tm_img_"+index}
                                          src={madrid.tmStorageUrl}
                                          alt=""
                                          className="img-fluid mx-auto d-block tab-img rounded"
                                          referrerpolicy="no-referrer"
                                          onLoad={onImgLoad}
                                        />
                                        : madrid.tmTxt.replace('&#36;', '$')}
                                    </td>
                                    <td style={{verticalAlign: "middle"}} align="center">
                                      {
                                        madrid.myClaimType == '' ?
                                          madrid.loginUserNo == madrid.repConnectedUserNo ?
                                            "My case"
                                          :
                                            madrid.repConnectedUserNo == '0' ?
                                            "Connectable"
                                            :
                                            "Connected with another account"
                                        :
                                          <p className="text-danger">Pending: {madrid.myClaimType == 'NotMycase' ? 'You raised an issue with this case, it’s not mine.' : madrid.myClaimType == 'Connect' ? 'You requested to connect with this case: it’s my case.' : 'You raised an issue with this case: It’s mine.'}</p>
                                      }
                                    </td>
                                    <td style={{verticalAlign: "middle"}} align="center">
                                      {
                                        madrid.myClaimType == '' ?
                                          madrid.loginUserNo == madrid.repConnectedUserNo ?
                                            <button type="button" class="waves-effect waves-light btn btn-outline-warning"
                                              onClick={() => {
                                                setClaimType("NotMycase");
                                                setClaimIrNumber(madrid.irNumber);
                                                setClaimFtpDate(madrid.ftpDate);
                                                setClaimCrCode(madrid.crCode);
                                                setClaimRepRankCode(madrid.repRankCode);
                                              }}>
                                              <i className="uil uil-times font-size-14"></i> Not My case
                                            </button>
                                          : 
                                            madrid.repConnectedUserNo == '0' ?
                                              <button type="button" class="waves-effect waves-light btn btn-outline-success"
                                                onClick={() => {
                                                  setClaimType("Connect");
                                                  setClaimIrNumber(madrid.irNumber);
                                                  setClaimFtpDate(madrid.ftpDate);
                                                  setClaimCrCode(madrid.crCode);
                                                  // setClaimTargetGroupNo(madrid.representativeGroupNo);
                                                  setClaimRepRankCode(madrid.repRankCode);
                                                }}>
                                                <i className="uil uil-times font-size-14"></i> Connect
                                              </button>
                                            :
                                              <button type="button" class="waves-effect waves-light btn btn-outline-danger"
                                                onClick={() => {
                                                  setClaimType("Claim");
                                                  setClaimIrNumber(madrid.irNumber);
                                                  setClaimFtpDate(madrid.ftpDate);
                                                  setClaimCrCode(madrid.crCode);
                                                  // setClaimTargetGroupNo(madrid.representativeGroupNo);
                                                  setClaimRepRankCode(madrid.repRankCode);
                                                }}>
                                                <i className="uil uil-times font-size-14"></i> Claim
                                              </button>
                                        :
                                          null
                                      }
                                    </td>
                                  </tr>
                              )
                            })
                            : null
                            }
                          </tbody>
                        </Table>
                      </div>

                      <WeCrestPagination 
                        viewList={viewMyCaseManagementList} 
                        pageSize={filterValidation.values.myCaseManagementListPageSize} 
                        curPage={filterValidation.values.myCaseManagementListCurPage}
                        totalPage={myCaseManagementListTotalPage}
                        curPageInput={myCaseManagementListCurPageInput}
                        setCurPageInput={setMyCaseManagementListCurPageInput}
                        pageSizeList={[10, 20, 30, 40, 50]}
                      />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>
      : null}


      {alertMsg !== '' ? (
        <SweetAlert
          warning
          onConfirm={() => {
            setAlertMsg('');
          }}
          onCancel={() => {
            setAlertMsg('');
          }}
        >{alertMsg}
        </SweetAlert>
      ) : null}

      <Modal
          isOpen={claimType != ''}
          toggle={() => {
            setClaimIrNumber(0);
            setClaimFtpDate('');
            setClaimCrCode('');
            setClaimDomain('');
            setClaimMemo('');
            setClaimTargetGroupNo(0);
            setClaimType('');
          }}
          scrollable={true}
          style={{top: "40%"}}
      >
        <h2 style={{textAlign: "center", marginTop: "10px"}}>
          {
          claimType === "NotMycase" ? 
            <>Not my entity</> 
          :
            <>Is this Case yours?</> 
          }
        </h2>
        <div style={{marginLeft: "10px", marginRight: "10px"}}>
        {
          claimType === 'NotMycase' ?
            <>
            <div style={{textAlign: "center"}} className="mb-3">
              <label>Do you know correct domain?</label>
            </div>
            <div className="d-flex vertical-align-center mb-3">
              <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="idnmStatus"
                  id="idnmY"
                  onClick={() => {setIdnmStatus('Y')}}
                  value="Y"
                  checked={idnmStatus === 'Y'}
                />
                <label
                  className="form-check-label"
                  htmlFor="idnmY"
                >
                  Yes
                </label>
              </div>
              <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="idnmStatus"
                  id="idnmN"
                  onClick={() => {setIdnmStatus('N')}}
                  value="N"
                  checked={idnmStatus === 'N'}
                />
                <label
                  className="form-check-label"
                  htmlFor="idnmN"
                >
                  No
                </label>
              </div>
              {idnmStatus === 'Y'?
              <div className="col-md-8">
                <input
                  id="claimDomain"
                  key="claimDomainKey"
                  className="form-control"
                  type="text"
                  placeholder="Enter domain"
                  onChange={(e) => {
                    setClaimDomain(e.target.value);
                  }}
                  value={claimDomain}
                />
              </div>
              : null}
            </div>
            </>
          : 
            <>
            <div style={{textAlign: "center"}} className="mb-3">
              <label>Select correct entity</label>
            </div>
            {
              myEntityList && myEntityList.length > 0 ?
              myEntityList.map((myEntity, index)=>{
                return (
                  <div className="col-md-12 mb-3" style={{textAlign: "left"}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="rdoEntity"
                      id={"entity_" + index}
                      onClick={() => {setClaimTargetGroupNo(myEntity.targetGroupNo)}}
                      value={myEntity.targetGroupNo}
                      checked={claimTargetGroupNo === myEntity.targetGroupNo}
                      style={{marginLeft: "0.5em"}}
                    />
                    &nbsp;
                    <label
                      className="form-check-label"
                      htmlFor={"entity_" + index}
                    >
                      {myEntity.targetGroupName}
                    </label>
                  </div>
                )
              })
              :
              null
            }
            </>
        }
        </div>
        <div className="d-flex vertical-align-center mb-3" style={{marginLeft: "10px", marginRight: "10px"}}>
          <div className="col-md-4">
            <label style={{paddingLeft : '0.5em'}}>
              Memo/reason
            </label>
          </div>
          <div className="col-md-8">
            <input
              id="claimMemo"
              className="form-control"
              type="text"
              placeholder="Enter memo"
              onChange={(e) => {
                setClaimMemo(e.target.value);
              }}
              value={claimMemo}
            />
          </div>
        </div>
        <div className="d-flex horizon-align-center gap-4 mb-3">
          <button
              type="button"
              onClick={() => {
                setClaimIrNumber(0);
                setClaimFtpDate('');
                setClaimCrCode('');
                setClaimDomain('');
                setClaimMemo('');
                setClaimTargetGroupNo(0);
                setClaimType('');
              }}
              className="btn btn-lg btn-danger waves-effect"
              data-dismiss="modal"
          >
              Cancel
          </button>
          <button
              type="button"
              className="btn btn-lg btn-success waves-effect waves-light"
              onClick={()=> {
                if (claimType === "NotMycase") {
                  if (claimDomain.indexOf("/") > -1) {
                    setAlertMsg("Domain cannot include slash('/').");
                    return;
                  } else if (claimDomain.indexOf(",") > -1) {
                    setAlertMsg("Domain cannot include comma(',').");
                    return;
                  }
                } else {
                  if (claimTargetGroupNo === 0) {
                    setAlertMsg("Select correct entity.");
                    return;
                  }
                }

                filterValidation.setFieldValue("submitType", claimType);
                filterValidation.handleSubmit(filterValidation.values);
              }}
          >
              Confirm
          </button>
        </div>
      </Modal>

    </React.Fragment>
  )
}

export default SearchMyCase;