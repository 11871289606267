import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Input,
    FormGroup,
    Tooltip,
    Button,
    Modal,
    UncontrolledTooltip,
    FormFeedback,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import EditorModal from "../../components/Common/EditorModal"
import SelectHomeCountry from '../../components/Filter/SelectHomeCountry';
import * as Yup from "yup";
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import {
  getSettingsInfo,
  updateAccountInfo,
  updateAccountPassword,
  setSettingsReducerValue,
  getContactsDetails as onGetContactsDetails,
  changeContactsDetails as onChangeContactsDetails,
  saveContacts as onSaveContacts,
  deleteContacts as onDeleteContacts,
  setContacsDetailResult as onSetContacsDetailResult,
  getMyEntityList as onGetMyEntityList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

const AccountSettings = props => {
  const dispatch = useDispatch();

  const { accountInfo, loading, success, errorMsg, contactsDetails, detailResult, myEntityList } = useSelector(state => ({
    accountInfo: state.Settings.accountInfo,
    loading: state.Settings.loading,
    success: state.Settings.success,
    errorMsg: state.Settings.errorMsg,
    contactsDetails: state.Contacts.contactsDetails,
    detailResult: state.Contacts.detailResult,
    myEntityList: state.EntityConnectManagement.myEntityList,
  }));

  const profile = useRef();

  const closeLoding = () => {
    dispatch(setSettingsReducerValue('LOADING_FLAG', false));
  }

  const targetGroupNo = -1;

  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [dupeEmailAddress, setDupeEmailAddress] = useState([]);
  const [deleteContactIdx, setDeleteContactIdx] = useState(0);
  const errMsg = [
    "not csv file"
  , "Please select a entity."
  , "Please select a contact."
  , "There are unsaved rows."
  , "Email address is required."
  , <>Invalid email format: Please check if you accidentally included any special characters or blank spaces.
    <br/><br/>Please refer to the following website to check for any hidden special characters or blank spaces: <a href='https://www.regextester.com/99810' target='_blank'>https://www.regextester.com/99810</a>
    <br/>*Please note that we do not have any affiliation with this website, but we found it helpful.</>
  , "First name is required."
  , "Please select email address!"
  , "This domain is verified!"
  , "This email address already exists for this entity!"
  , "The following email addresses have already been unsubscribed:"
];

  const contactCheck = (contactChkIdx, value) => {
    let _contactsDetails = cloneDeep(contactsDetails);
    let contactChkCnt = 0;

    _contactsDetails.contactsList = _contactsDetails.contactsList.map((contact, contactIdx) =>{
      if(contactChkIdx == "chkAll"){
        contact.checked = value;
      }else if(contactChkIdx == contactIdx){
        contact.checked = value;
      }
      if(contact.checked == true){
        contactChkCnt++;
      }
      return contact;
    })
    if(contactChkCnt === contactsDetails.contactsList.length){
      _contactsDetails.checked = true;
    }else{
      _contactsDetails.checked = false;
    }

    dispatch(onChangeContactsDetails(_contactsDetails));
  }

  const contactChk = (type) => {
    let chkCnt = 0;
    let notSaveCnt = 0;

    // let notDelEmailAdressList = [];
    let _newContactsList = [];
    contactsDetails.contactsList.map((contact, contactIdx) => {
      if(contact.checked === true) {
        chkCnt++;
      }

      if (type == 'Unsubscribed') {
        if(contact.checked === true) {
          // chkCnt++;
          if (contact.modFlag == "I") {
            notSaveCnt++;
          }
        }
      } else {
        // console.log('checked : ' + contact.checked + ', modFlag : ' + contact.modFlag);
        if(!(contact.checked && contact.modFlag == "I")) {
          _newContactsList.push(contact);
        }
      }
    })

    if(chkCnt === 0){
      dispatch(onSetContacsDetailResult(2));
      return false;
    } 

    if (type == 'Unsubscribed') {
      if (notSaveCnt > 0){
        dispatch(onSetContacsDetailResult(3));
        return false;
      }
    } else {
      if (_newContactsList.length == 0) {
        let _contactsDetails = cloneDeep(contactsDetails);
        let newRow = [{"targetGroupNo":targetGroupNo,"emailSeq":null,"emailAddress":null,"modFlag":"I","type":"To","firstName":null,"lastName":null,"title":null,"position":null,"unsubscribe":"N","checked":true,"sendYn":"Y"}];
        _contactsDetails.contactsList = newRow;

        dispatch(onChangeContactsDetails(_contactsDetails));

        return false;
      } else {
        chkCnt = 0;
        notSaveCnt = 0;

        _newContactsList.map((contact, contactIdx) => {
          if(contact.checked === true) {
            chkCnt++;
          } 
          
          if (contact.modFlag == "I") {
            notSaveCnt++;
          }
        })

        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.contactsList = _newContactsList;
        dispatch(onChangeContactsDetails(_contactsDetails));

        if(chkCnt === 0){
          return false;
        } else if(notSaveCnt > 0){
          dispatch(onSetContacsDetailResult(3));
          return false;
        }
      }
    }

    return true;
  }

  const handleInput = (attr, contactIdx, value) => {
    let _contactsDetails = cloneDeep(contactsDetails);
    _contactsDetails.contactsList[contactIdx][attr] = value;
    
    dispatch(onChangeContactsDetails(_contactsDetails));
  }

  const addContacts = () => {
    let _contactsDetails = cloneDeep(contactsDetails);
    let newRow = {"targetGroupNo":targetGroupNo,"emailSeq":null,"emailAddress":null,"modFlag":"I","type":"To","firstName":null,"lastName":null,"title":null,"position":null,"unsubscribe":"N","checked":true,"sendYn":"Y"};
    _contactsDetails.contactsList.push(newRow);

    dispatch(onChangeContactsDetails(_contactsDetails));
  }

  const saveValidation = () => {
    let chkCnt = 0;
    let noEmail = false;
    // let noFirstName = false;
    let invalidEmail = false;
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    let checkUnsubsEmail = [];
    let checkDupeEmailAddress = [];
    
    contactsDetails.contactsList.map((contact, contactIdx) => {
      if(contact.checked === true) {
        chkCnt++;
        if(!contact.emailAddress){
          noEmail = true;
        } else {
          invalidEmail = !regex.test(contact.emailAddress);
        }

        if (contact.modFlag === "I") {
          checkUnsubsEmail.push(contact);
        }

        contactsDetails.contactsList.map((contact2, contactIdx2) => {
          if(contactIdx != contactIdx2 && contact.emailAddress && contact.emailAddress != '' && contact.emailAddress.toLowerCase() == contact2.emailAddress.toLowerCase() && !checkDupeEmailAddress.includes(contact.emailAddress)) {
            checkDupeEmailAddress.push(contact.emailAddress)
          }
        })
      }
    })

    if (checkDupeEmailAddress.length > 0) {
      setDupeEmailAddress(checkDupeEmailAddress);
      dispatch(onSetContacsDetailResult(9));
      return false;
    }

    if(chkCnt === 0){
      dispatch(onSetContacsDetailResult(2));
      return false;
    }else if(noEmail){
      dispatch(onSetContacsDetailResult(4));
      return false;
    }else if(invalidEmail){
      dispatch(onSetContacsDetailResult(5));
      return false;
    }else{
      return true;
    }
  }

  useEffect(()=> {
    dispatch(getSettingsInfo());
    dispatch(onGetContactsDetails({targetGroupNo : targetGroupNo}));
    dispatch(onGetMyEntityList({}));
  },[]);

  const validateSignature = (values, props /* only available when using withFormik */) => {
    const errors = {};
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;
  
    if (!values.firstName) {
      errors.firstName = 'Please enter your first name';
    } 
    
    if (!values.lastName) {
      errors.lastName = 'Please enter your last name';
    }

    if (!values.organization) {
      errors.organization = 'Please enter your firm\'s name';
    }

    if (!values.email) {
      errors.email = 'Please enter your work email address';
    } else if (!regex.test(values.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!values.crCdList || values.crCdList.length == 0 || values.crCdList[0] == '') {
      errors.crCdList = 'Please enter your home country';

      dispatch(setSettingsReducerValue('ERROR_MSG', "Please enter your home country"));

      // setErrorMsg("Please enter your home country");
    }
  
    return errors;
  };

  const validatePassword = (values, props /* only available when using withFormik */) => {
    const errors = {};
  
    if (!values.chkCurrentPassword) {
      errors.chkCurrentPassword = 'Please enter your current password';
    } 
    
    if (!values.chkNewPassword) {
      errors.chkNewPassword = 'Please enter your new password';
    }

    if (!values.chkConfirmNewPassword) {
      errors.chkConfirmNewPassword = 'Please enter your confirm new password';
      return errors;
    }

    if (values.chkNewPassword !== values.chkConfirmNewPassword) {
      errors.chkConfirmNewPassword = 'New password and confirm new password are not the same';
    }
  
    return errors;
  };

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      submitType: "",
      userName: accountInfo.userName,
      firstName: accountInfo.firstName,
      lastName: accountInfo.lastName,
      organization: accountInfo.organization,
      position: accountInfo.position,
      mainAddress: accountInfo.mainAddress,
      email: accountInfo.email,
      website: accountInfo.website,
      crCdList: [accountInfo.country],
      orgCountry: accountInfo.country,
      country: accountInfo.country,
      officeNumber: accountInfo.officeNumber,
      mobileNumber: accountInfo.mobileNumber,
      photoName: accountInfo.photoName,
      photoUrl: accountInfo.photoUrl,
      photoFile: undefined,
      bio: accountInfo.bio,

      chkCurrentPassword: accountInfo.chkCurrentPassword,
      chkCurrentPasswordType: "password",
      chkCurrentPasswordIcon: "uil-eye",
      chkNewPassword: accountInfo.chkNewPassword,
      chkNewPasswordType: "password",
      chkNewPasswordIcon: "uil-eye",
      chkConfirmNewPassword: accountInfo.chkConfirmNewPassword,
      chkConfirmPasswordType: "password",
      chkConfirmPasswordIcon: "uil-eye",
    },
    onSubmit: (values, {setErrors}) => {
      let submitType = values["submitType"];

      if(submitType === "updateAccountInfo"){
        let sigError = validateSignature(values);
        if (JSON.stringify(sigError) != '{}') {
          setErrors(sigError);
        } else {
          const formData = new FormData();
          formData.append('userName', values["userName"]);
          formData.append('firstName', values["firstName"]);
          formData.append('lastName', values["lastName"]);
          formData.append('organization', values["organization"]);
          formData.append('position', values["position"]);
          formData.append('mainAddress', values["mainAddress"]);
          formData.append('email', values["email"]);
          formData.append('website', values["website"]);
          formData.append('country', values["crCdList"]);
          formData.append('orgCountry', values["orgCountry"]);
          formData.append('officeNumber', values["officeNumber"]);
          formData.append('mobileNumber', values["mobileNumber"]);
          formData.append('photoName', values["photoName"]);
          formData.append('photoUrl', values["photoUrl"]);
          formData.append('bio', values["bio"]);
          formData.append('photoFile', values["photoFile"]);

          dispatch(updateAccountInfo(formData));
        }
      } else if(submitType === "updateAccountPassword"){
        let sigError = validatePassword(values);
        if (JSON.stringify(sigError) != '{}') {
          setErrors(sigError);
        } else {
          const formData = new FormData();
          formData.append('chkCurrentPassword', values["chkCurrentPassword"]);
          formData.append('chkNewPassword', values["chkNewPassword"]);
          formData.append('chkConfirmNewPassword', values["chkConfirmNewPassword"]);

          filterValidation.setFieldValue("chkCurrentPassword", '');
          filterValidation.setFieldValue("chkNewPassword", '');
          filterValidation.setFieldValue("chkConfirmNewPassword", '');

          dispatch(updateAccountPassword(formData));
        }
      } else if(submitType == "SaveContacts" || submitType == "DeleteContacts"){
        const _contactsList = [];
        contactsDetails.contactsList.map((contact, contactIdx) => {
          if(contact.checked) _contactsList.push(contact);
        });

        if(submitType == "SaveContacts") dispatch(onSaveContacts(_contactsList, null));
        else if(submitType == "DeleteContacts") dispatch(onDeleteContacts(_contactsList, null));
      } else if(submitType == "DeleteContact"){
        const _contactsList = [contactsDetails.contactsList[deleteContactIdx]];
        dispatch(onDeleteContacts(_contactsList, null));
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Form 
            onSubmit={(e) => {
              e.preventDefault();
              filterValidation.handleSubmit();
              return false;
            }}
          >
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Account settings</CardTitle>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Username *
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Username"
                      name="userName"
                      value={filterValidation.values.userName}
                      disabled={true}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    First name *
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.firstName}
                      invalid={
                        filterValidation.touched.firstName && filterValidation.errors.firstName ? true : false
                      }
                      disabled={JSON.parse(localStorage.getItem("authUser")).virtualLogIn == 'Y' ? false : true}
                    />
                    {filterValidation.touched.firstName && filterValidation.errors.firstName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.firstName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Last name *
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.lastName}
                      invalid={
                        filterValidation.touched.lastName && filterValidation.errors.lastName ? true : false
                      }
                      disabled={JSON.parse(localStorage.getItem("authUser")).virtualLogIn == 'Y' ? false : true}
                    />
                    {filterValidation.touched.lastName && filterValidation.errors.lastName ? (
                      <FormFeedback type="invalid">{filterValidation.errors.lastName}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Organization *
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Organization"
                      name="organization"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.organization}
                      invalid={
                        filterValidation.touched.organization && filterValidation.errors.organization ? true : false
                      }
                    />
                    {filterValidation.touched.organization && filterValidation.errors.organization ? (
                      <FormFeedback type="invalid">{filterValidation.errors.organization}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Position
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Position"
                      name="position"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.position}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Main address
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Main address..."
                      name="mainAddress"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.mainAddress}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Email *
                  </label>
                  <div className="col-md-4">
                    <Input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.email}
                      invalid={
                        filterValidation.touched.email && filterValidation.errors.email ? true : false
                      }
                      disabled={JSON.parse(localStorage.getItem("authUser")).virtualLogIn == 'Y' ? false : true}
                    />
                    {filterValidation.touched.email && filterValidation.errors.email ? (
                      <FormFeedback type="invalid">{filterValidation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Website
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="url"
                      name="website"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.website}
                      disabled={accountInfo.entityConnectYn === 'Y'}
                      invalid={
                        accountInfo.entityConnectYn === 'Y' && accountInfo.requestChangeUserWebsiteYn === 'Y' ? true : false
                      }
                    />
                    {accountInfo.entityConnectYn === 'Y' && accountInfo.requestChangeUserWebsiteYn === 'Y' ? (
                      <FormFeedback type="invalid">User website changes pending</FormFeedback>
                    ) : null}
                  </div>
                  
                </Row>
                <SelectHomeCountry
                  formik={filterValidation}
                  componentName='RadioCountry'
                />
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Office number
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Office number"
                      name="officeNumber"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.officeNumber}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Mobile number
                  </label>
                  <div className="col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Mobile number"
                      name="mobileNumber"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.mobileNumber}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Profile photo
                  </label>
                  <div className="col-md-4">
                    <input type="file" 
                      className="form-control"
                      accept="image/jpeg, image/jpg, image/png, image/gif, image/webp"
                      ref={profile} 
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                
                        if(e.target.files[0] !== undefined){
                          let _values = cloneDeep(filterValidation.values);
                          _values.photoFile = e.target.files[0];
                          filterValidation.setValues(_values);
                        }
                      }}
                      name="profile"
                      placeholder={filterValidation.values.photoName}
                      />
                    {
                      accountInfo.photoUrl ?
                      <>
                        <br/>
                        <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={accountInfo.photoName}
                        src={accountInfo.photoUrl}
                        />
                      </>
                      : null
                    }
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Bio
                  </label>
                  <div className="col-md-4">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Biography..."
                      name="bio"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.bio}
                    />
                  </div>
                </Row>
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("submitType", "updateAccountInfo");
                      filterValidation.handleSubmit(filterValidation.values);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    outline
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("userName", accountInfo.userName);
                      filterValidation.setFieldValue("firstName", accountInfo.firstName);
                      filterValidation.setFieldValue("lastName", accountInfo.lastName);
                      filterValidation.setFieldValue("organization", accountInfo.organization);
                      filterValidation.setFieldValue("position", accountInfo.position);
                      filterValidation.setFieldValue("mainAddress", accountInfo.mainAddress);
                      filterValidation.setFieldValue("email", accountInfo.email);
                      filterValidation.setFieldValue("website", accountInfo.website);
                      filterValidation.setFieldValue("country", accountInfo.country);
                      filterValidation.setFieldValue("officeNumber", accountInfo.officeNumber);
                      filterValidation.setFieldValue("mobileNumber", accountInfo.mobileNumber);
                      filterValidation.setFieldValue("photoName", accountInfo.photoName);
                      filterValidation.setFieldValue("bio", accountInfo.bio);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Row>

          {detailResult !== -1 ? (
            <SweetAlert
              onConfirm={() => {
                dispatch(onSetContacsDetailResult(-1));
                setDupeEmailAddress([]);
              }}
            >{detailResult < 9 ? errMsg[detailResult] : <>{errMsg[detailResult]}<br/>{detailResult == 9 ? "["+ dupeEmailAddress + "]" : ""}</>}
            </SweetAlert>
          ) : null}

          {confirmAlert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText="Confirm"
              cancelBtnText="No, go back"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                filterValidation.handleSubmit(filterValidation.values);
                setConfirmAlert(false);
              }}
              onCancel={() => {
                setConfirmAlert(false);
                setDeleteContactIdx(0);
              }}
            >
              {confirmMsg}
            </SweetAlert>
          ) : null}

          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4" id="contactsOfMyCases">Contacts of My cases</CardTitle>
                {
                  myEntityList && myEntityList.length !== 0 ? 
                  <>
                    <p className="card-title-desc">You can add contacts of your colleagues who act ad the representatives of your firm's case</p>
                    <Row className="mb-3">
                      <span className="button-items">
                        <Button type="button" color="primary" outline className="waves-effect waves-light"
                          onClick={() => {
                            if(saveValidation()){
                              filterValidation.setFieldValue("submitType", "SaveContacts");
                              setConfirmMsg("Are you sure you want to save the selected email(s)?");
                              setConfirmAlert(true);
                            }
                          }}
                        >Save contact
                        </Button>
                        <Button type="button" color="primary" outline className="waves-effect waves-light"
                          onClick={() => {
                            if(contactChk("Delete")){
                              filterValidation.setFieldValue("submitType", "DeleteContacts");
                              setConfirmMsg("Are you sure you want to delete the selected email(s)?");
                              setConfirmAlert(true);
                            }
                          }}
                        >Delete contact
                        </Button>
                      </span>
                    </Row>
                    <div className="table-responsive">
                    { contactsDetails && Object.keys(contactsDetails).length !== 0 ?
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={contactsDetails.checked} onClick={(e)=>contactCheck("chkAll", e.target.checked)} /></th>
                          <th>Email address*</th>
                          <th>First name</th>
                          <th>Last name</th>
                          <th>Title</th>
                          <th>Position</th>
                          <th>Delete?</th>
                          <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactsDetails.contactsList.map((contact, contactIdx) => {
                            return (
                                    <tr className={contact.modFlag === "I" ? "bg-soft-danger" : null}>
                                      <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                        <input type="checkbox" 
                                          checked={contact.checked}
                                          onClick={(e)=> contactCheck(contactIdx, e.target.checked)}
                                          />
                                      </td>
                                      <td><input type="text" className="form-control" onChange={(e) => { handleInput("emailAddress", contactIdx, e.target.value)}} value={contact.emailAddress ? contact.emailAddress : ""} /></td>
                                      <td><input type="text" className="form-control" onChange={(e) => { handleInput("firstName", contactIdx, e.target.value)}} value={contact.firstName ? contact.firstName : ""} /></td>
                                      <td><input type="text" className="form-control" onChange={(e) => { handleInput("lastName", contactIdx, e.target.value)}} value={contact.lastName ? contact.lastName : ""} /></td>
                                      <td><input type="text" className="form-control" onChange={(e) => { handleInput("title", contactIdx, e.target.value)}} value={contact.title ? contact.title : "" } /></td>
                                      <td><input type="text" className="form-control" onChange={(e) => { handleInput("position", contactIdx, e.target.value)}} value={contact.position ? contact.position : ""} /></td>
                                      <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                        <Link to="#" 
                                          onClick={() => {
                                            setDeleteContactIdx(contactIdx);
                                            filterValidation.setFieldValue("submitType", "DeleteContact");
                                            setConfirmMsg("Are you sure you want to delete this email?");
                                            setConfirmAlert(true);
                                          }}
                                        >
                                          <i className="uil-trash-alt" id={"deleteEmlIco"+contactIdx} style={{color:"red", fontSize:"18px"}}></i>
                                          <UncontrolledTooltip placement="top" target={"deleteEmlIco"+contactIdx}>
                                          Delete email address
                                          </UncontrolledTooltip>
                                        </Link>
                                      </td>
                                      <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                        {contactIdx+1 === contactsDetails.contactsList.length && contactsDetails.contactsList.length < 10 ?
                                          <Link to="#" onClick={(e) => addContacts()}>
                                            <i className="uil uil-plus-square font-size-22" id="addContacts"></i>
                                            <UncontrolledTooltip placement="top" target="addContacts" className="domain-correct-tooltip">
                                              Add more contacts
                                            </UncontrolledTooltip>
                                          </Link>
                                        :null}
                                      </td>
                                    </tr>
                                  )
                              })
                            }
                        </tbody>
                      </Table>
                      :null}
                    </div>
                  </>
                  :
                  <p className="card-title-desc">You can add your entities for your firm <a href='/entityConnect'>here</a></p>
                }
              </CardBody>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Password settings</CardTitle>
                <p className="card-title-desc">Leave blank if you don’t want to change password</p>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Current Password
                  </label>
                  <div className="col-md-4 d-flex horizon-align-center vertical-align-center gap-2">
                    <div class="col-md-10">
                      <Input
                        className="form-control"
                        type={filterValidation.values.chkCurrentPasswordType}
                        name="chkCurrentPassword"
                        onChange={filterValidation.handleChange}
                        value={filterValidation.values.chkCurrentPassword}
                        invalid={
                          filterValidation.touched.chkCurrentPassword && filterValidation.errors.chkCurrentPassword ? true : false
                        }
                      />
                      {filterValidation.touched.chkCurrentPassword && filterValidation.errors.chkCurrentPassword ? (
                        <FormFeedback type="invalid">{filterValidation.errors.chkCurrentPassword}</FormFeedback>
                      ) : null}
                    </div>

                    <i className={filterValidation.values.chkCurrentPasswordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                      onClick={()=>{
                        if (filterValidation.values.chkCurrentPasswordType === "password") {
                          filterValidation.setFieldValue("chkCurrentPasswordType", "text");
                          filterValidation.setFieldValue("chkCurrentPasswordIcon", "uil-eye-slash");
                        } else {
                          filterValidation.setFieldValue("chkCurrentPasswordType", "password");
                          filterValidation.setFieldValue("chkCurrentPasswordIcon", "uil-eye");
                        }
                      }}></i>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    New Password
                  </label>
                  <div className="col-md-4 d-flex horizon-align-center vertical-align-center gap-2">
                    <div class="col-md-10">
                    <Input
                      className="form-control"
                      type={filterValidation.values.chkNewPasswordType}
                      name="chkNewPassword"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.chkNewPassword}
                      invalid={
                        filterValidation.touched.chkNewPassword && filterValidation.errors.chkNewPassword ? true : false
                      }
                    />
                    {filterValidation.touched.chkNewPassword && filterValidation.errors.chkNewPassword ? (
                      <FormFeedback type="invalid">{filterValidation.errors.chkNewPassword}</FormFeedback>
                    ) : null}
                    </div>

                    <i className={filterValidation.values.chkNewPasswordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                      onClick={()=>{
                        if (filterValidation.values.chkNewPasswordType === "password") {
                          filterValidation.setFieldValue("chkNewPasswordType", "text");
                          filterValidation.setFieldValue("chkNewPasswordIcon", "uil-eye-slash");
                        } else {
                          filterValidation.setFieldValue("chkNewPasswordType", "password");
                          filterValidation.setFieldValue("chkNewPasswordIcon", "uil-eye");
                        }
                      }}></i>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Confirm new Password
                  </label>
                  <div className="col-md-4 d-flex horizon-align-center vertical-align-center gap-2">
                    <div class="col-md-10">
                    <Input
                      className="form-control"
                      type={filterValidation.values.chkConfirmPasswordType}
                      name="chkConfirmNewPassword"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.chkConfirmNewPassword}
                      invalid={
                        filterValidation.touched.chkConfirmNewPassword && filterValidation.errors.chkConfirmNewPassword ? true : false
                      }
                    />
                    {filterValidation.touched.chkConfirmNewPassword && filterValidation.errors.chkConfirmNewPassword ? (
                      <FormFeedback type="invalid">{filterValidation.errors.chkConfirmNewPassword}</FormFeedback>
                    ) : null}
                    </div>

                    <i className={filterValidation.values.chkConfirmPasswordIcon} style={{color:"#5c8dc6", fontSize:"18px", cursor:"pointer"}} 
                      onClick={()=>{
                        if (filterValidation.values.chkConfirmPasswordType === "password") {
                          filterValidation.setFieldValue("chkConfirmPasswordType", "text");
                          filterValidation.setFieldValue("chkConfirmPasswordIcon", "uil-eye-slash");
                        } else {
                          filterValidation.setFieldValue("chkConfirmPasswordType", "password");
                          filterValidation.setFieldValue("chkConfirmPasswordIcon", "uil-eye");
                        }
                      }}></i>
                  </div>
                </Row>
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("submitType", "updateAccountPassword");
                      filterValidation.handleSubmit(filterValidation.values);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    outline
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("chkCurrentPassword", '');
                      filterValidation.setFieldValue("chkNewPassword", '');
                      filterValidation.setFieldValue("chkConfirmNewPassword", '');
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Row>

          </Form>

          {
            success ? 
            success === 'Success' ?
            <SweetAlert
              title={success}
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(setSettingsReducerValue('SUCCESS', ""));
              }}
            ></SweetAlert>
            :
            <SweetAlert
              warning
              title={success}
              onConfirm={() => {
                dispatch(setSettingsReducerValue('SUCCESS', ""));
              }}
              onCancel={() => {
                dispatch(setSettingsReducerValue('SUCCESS', ""));
              }}
            >
            </SweetAlert>
            : null
          }

          {errorMsg !== '' ? (
            <SweetAlert
              warning
              title={errorMsg}
              onConfirm={() => {
                dispatch(setSettingsReducerValue('ERROR_MSG', ""));
              }}
              onCancel={() => {
                dispatch(setSettingsReducerValue('ERROR_MSG', ""));
              }}
            >
            </SweetAlert>
            ) 
          : null}

          <LoadingModal loading={loading} setLoading={closeLoding}></LoadingModal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AccountSettings;