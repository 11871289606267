import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Label,
    Button,
    CardHeader,
    Collapse,
    FormFeedback,
    Input,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SimpleBar from "simplebar-react"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal";
import {
  getEntityDetail as onGetEntityDetail,
  saveEntityEmails as onSaveEntityEmails,
  deleteEntityEmails as onDeleteEntityEmails,
  changeEntityDetails as onChangeEntityDetail,
  setEntityDetailResult as onSetEntityDetailResult,
  setEntityDetailSuccess as onSetEntityDetailSuccess,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const EntityDetail = props => {
  // console.log("props : "+JSON.stringify(props));
  const dispatch = useDispatch();
  
  const { loading, success, result, entityDetail, targetGroupAddressList, dupeEmailAddresses } = useSelector(state => ({
    loading: state.AdmDomainManagement.loading,
    success: state.AdmDomainManagement.success,
    result: state.AdmDomainManagement.result,
    entityDetail: state.AdmDomainManagement.entityDetail,
    targetGroupAddressList: state.AdmDomainManagement.targetGroupAddressList,
    dupeEmailAddresses: state.AdmDomainManagement.dupeEmailAddresses,
  }));

  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  
  const errMsg = [
      "Please select a entity."
    , "There are unsaved rows."
    , "Email address is required."
    , "Invalid email format: Please check if you accidentally included any special characters or blank spaces."
    , "First name is required."
    , "Please select email address"
    , "Email address already exists."
    , "This email address already exists for another entity!"
  ];

  const [errMsg6, setErrMsg6] = useState('');

  useEffect(() => { 
    if (props.targetGroupNo != '') {
      dispatch(onGetEntityDetail({targetGroupNo: props.targetGroupNo}));
    }
    
  }, [props.targetGroupNo]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "",
    },
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      // console.log("targetGroupNo : "+targetGroupNo);
      let submitType = values["submitType"];

      if(submitType == "Save" || submitType == "Delete"){
        const _targetGroupEmailList = [];
        entityDetail.targetGroupEmailList.map((contact, contactIdx) => {
          if(contact.checked) _targetGroupEmailList.push(contact);
        });

        if(submitType == "Save") dispatch(onSaveEntityEmails(_targetGroupEmailList));
        else if(submitType == "Delete") dispatch(onDeleteEntityEmails(_targetGroupEmailList));
      } else if (submitType.indexOf("Delete_") === 0){
        let idx = submitType.replace("Delete_", "");
        const _targetGroupEmailList = [];
        _targetGroupEmailList.push(entityDetail.targetGroupEmailList[idx]);

        dispatch(onDeleteEntityEmails(_targetGroupEmailList));
      }
    },
  });

  const emailCheck = (emailCheckIdx, value) => {
    let _entityDetail = cloneDeep(entityDetail);
    let emailChkCnt = 0;

    _entityDetail.targetGroupEmailList = _entityDetail.targetGroupEmailList.map((email, emailIdx) =>{
      if(emailCheckIdx == "chkAll"){
        email.checked = value;
      }else if(emailCheckIdx == emailIdx){
        email.checked = value;
      }
      if(email.checked == true){
        emailChkCnt++;
      }
      return email;
    })
    if(emailChkCnt === entityDetail.targetGroupEmailList.length){
      _entityDetail.checked = true;
    }else{
      _entityDetail.checked = false;
    }

    dispatch(onChangeEntityDetail(_entityDetail));
  }
  
  const handleTypeSelect = (contactIdx, value) => {
    let _entityDetail = cloneDeep(entityDetail);
    _entityDetail.targetGroupEmailList[contactIdx].type = value;
    
    dispatch(onChangeEntityDetail(_entityDetail));
  }

  const handleInput = (attr, contactIdx, value) => {
    let _entityDetail = cloneDeep(entityDetail);
    _entityDetail.targetGroupEmailList[contactIdx][attr] = value;
    
    dispatch(onChangeEntityDetail(_entityDetail));
  }

  const addEmail = () => {
    let _entityDetail = cloneDeep(entityDetail);
    let newRow = {"targetGroupNo":props.targetGroupNo,"emailAddress":null,"orgEmailAddress":null,"modFlag":"I","firstName":null,"lastName":null,"title":null,"position":null,"generalEmailYn":"N","scrapeType":"Manual","checked":true};
    _entityDetail.targetGroupEmailList.push(newRow);

    dispatch(onChangeEntityDetail(_entityDetail));
  }

  const contactChk = () => {
    let chkCnt = 0;
    let notSaveCnt = 0;

    entityDetail.targetGroupEmailList.map((contact, contactIdx) => {
      if(contact.checked === true) {
        chkCnt++;
        if(contact.modFlag == "I") notSaveCnt++;
      }
    })
    
    if(chkCnt === 0){
      dispatch(onSetEntityDetailResult(0));
      return false;
    }
    if(notSaveCnt > 0){
      dispatch(onSetEntityDetailResult(1));
      return false;
    }

    return true;
  }

  const saveValidation = () => {
    let chkCnt = 0;
    let noEmailCnt = 0;
    let invalidCnt = 0;
    let dupeEmail = false;
    let dupeEmailAddress = '';
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    entityDetail.targetGroupEmailList.map((contact, contactIdx) => {
      if(contact.checked === true) {
        chkCnt++;
        
        if(!contact.emailAddress){
          noEmailCnt++;
        }else if(!regex.test(contact.emailAddress)){
          invalidCnt++;
        }

        entityDetail.targetGroupEmailList.map((contact2, contactIdx2) => {
          if (contact.emailAddress == contact2.emailAddress && contactIdx != contactIdx2) {
            dupeEmailAddress = contact.emailAddress;
            dupeEmail = true;
            return;
          }
        })
      }
    })

    if(chkCnt === 0){
      dispatch(onSetEntityDetailResult(0));
      return false;
    }else if(noEmailCnt > 0){
      dispatch(onSetEntityDetailResult(2));
      return false;
    }else if(invalidCnt > 0){
      dispatch(onSetEntityDetailResult(3));
      return false;
    }else if(dupeEmail){
      setErrMsg6("Email address(" + dupeEmailAddress + ") already exists.");
      dispatch(onSetEntityDetailResult(6));
      return false;
    }else{
      return true;
    }
  }

  const showSuccessCopyToast = () => {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    let message = "Copy clipboard!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title)
  }

  return (
    <Modal
      size="xl"
      style={{maxWidth : "1700px"}}
      isOpen={props.isOpenEntityDetail}
      toggle={() => {
        props.togEntityDetail()
      }}
    >
      <div className="modal-body">
        <button
          onClick={() => {
            props.togEntityDetail()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      <div className={props.targetGroupNo != undefined? null : "page-content" }>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Entity details</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col lg="5" xl="4">
                    Entity name: <h5>{entityDetail.targetGroupName}</h5>
                    Entity domain: <h5>{entityDetail.domainCorrectYn && entityDetail.domainCorrectYn === "Y" ? <><i className="bx bx-badge-check font-size-18 text-success"></i> </>: null} 
                    {entityDetail.domain && entityDetail.domain !== "NOT_SAME" && entityDetail.domain !== "NOT_MAX" ? 
                      <a href='#' onClick={() => {
                        window.open('http://' + entityDetail.domain, "_blank");
                      }}>{entityDetail.domain}</a> 
                      : "N/A"}</h5>
                    Last updated: <h5>{entityDetail.lastTargetEmailModDate ? entityDetail.lastTargetEmailModDate : "N/A"}</h5>
                  </Col>
                  <Col lg="7" xl="8">
                  {targetGroupAddressList && Object.keys(targetGroupAddressList).length !== 0 ?
                    <Table className="table mb-0">
                    <thead>
                      <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Country</th>
                      <th></th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    targetGroupAddressList.map((targetGroupAddress, index) => {
                      return (
                              <tr>
                                <td>{targetGroupAddress.entityType}</td>
                                <td>{targetGroupAddress.name}</td>
                                <td>{targetGroupAddress.address}</td>
                                <td>[{targetGroupAddress.country}] {targetGroupAddress.countryName}</td>
                                <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                  <Link to="#" id={"target_copy_"+index}
                                    onClick={()=>{
                                      if (window.isSecureContext && navigator.clipboard) {
                                        navigator.clipboard.writeText(targetGroupAddress.name + ' ' + targetGroupAddress.address + ' ' + '[' + targetGroupAddress.country + '] ' + targetGroupAddress.countryName);
                                      } else {
                                        console.log('old copy clipboard!');
                                        const textArea = document.createElement("textarea");
                                        textArea.setAttribute("type", "hidden");
                                        textArea.value = targetGroupAddress.name + ' ' + targetGroupAddress.address + ' ' + '[' + targetGroupAddress.country + '] ' + targetGroupAddress.countryName;

                                        document.getElementById("target_copy_"+index).appendChild(textArea);
                                        textArea.focus();
                                        textArea.select();
                                        try {
                                          document.execCommand('copy');
                                        } catch (err) {
                                          console.error('Unable to copy to clipboard', err);
                                        }
                                        document.getElementById("target_copy_"+index).removeChild(textArea);
                                      }
                                      showSuccessCopyToast();
                                    }}>
                                    <i className="uil uil-copy-alt font-size-22 text-secondary"></i>
                                  </Link>
                                  <UncontrolledTooltip placement="top" target={"target_copy_"+index}>
                                    Copy {targetGroupAddress.entityType}'s name to clipboard
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                            )
                    })
                    }
                    </tbody>
                    </Table>
                    : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="text-muted mb-0">
                  <Row className="mb-3">
                    <span className="button-items">
                      <Button type="button" color="primary" outline className="waves-effect waves-light"
                        onClick={() => {
                          if(saveValidation()){
                            validation.setFieldValue("submitType", "Save");
                            setConfirmMsg("Are you sure you want to save the selected email(s)?");
                            setConfirmAlert(true);
                          }
                        }}
                      >Save contact
                      </Button>
                      <Button type="button" color="primary" outline className="waves-effect waves-light"
                        onClick={() => {
                          if(contactChk()){
                            validation.setFieldValue("submitType", "Delete");
                            setConfirmMsg("Are you sure you want to delete the selected email(s)?");
                            setConfirmAlert(true);
                          }
                        }}
                      >Delete contact
                      </Button>
                    </span>
                  </Row>
                </CardTitle>
                <div className="table-responsive">
                { entityDetail && entityDetail.targetGroupEmailList && Object.keys(entityDetail.targetGroupEmailList).length !== 0 ?
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                      <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={entityDetail.checked} onClick={(e)=>emailCheck("chkAll", e.target.checked)} /></th>
                      <th>Email address*</th>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>Title</th>
                      <th>Position</th>
                      <th>Scrape type</th>
                      <th>General</th>
                      <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {entityDetail.targetGroupEmailList.map((email, emailIdx) => {
                        return (
                                <tr className={email.modFlag === "I" ? "bg-soft-danger" : null}>
                                  <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                    <input type="checkbox" 
                                      checked={email.checked}
                                      onClick={(e)=> emailCheck(emailIdx, e.target.checked)}
                                      />
                                  </td>
                                  <td><input type="text" className="form-control" onChange={(e) => { handleInput("emailAddress", emailIdx, e.target.value)}} value={email.emailAddress ? email.emailAddress : ""}/></td>
                                  <td><input type="text" className="form-control" onChange={(e) => { handleInput("firstName", emailIdx, e.target.value)}} value={email.firstName ? email.firstName : ""}/></td>
                                  <td><input type="text" className="form-control" onChange={(e) => { handleInput("lastName", emailIdx, e.target.value)}} value={email.lastName ? email.lastName : ""}/></td>
                                  <td><input type="text" className="form-control" onChange={(e) => { handleInput("title", emailIdx, e.target.value)}} value={email.title ? email.title : "" }/></td>
                                  <td><input type="text" className="form-control" onChange={(e) => { handleInput("position", emailIdx, e.target.value)}} value={email.position ? email.position : ""}/></td>
                                  <td>
                                       
                                    <select className="form-select"
                                      value={email.scrapeType}
                                      onChange={(e) => handleInput("scrapeType", emailIdx, e.target.value)}
                                    >
                                      <option value="Auto">Auto</option>
                                      <option value="Manual">Manual</option>
                                    </select>
                                  </td>
                                  <td>
                                       
                                    <select className="form-select"
                                      value={email.generalEmailYn}
                                      onChange={(e) => handleInput("generalEmailYn", emailIdx, e.target.value)}
                                    >
                                      <option value="Y">Y</option>
                                      <option value="N">N</option>
                                    </select>
                                  </td>
                                  <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                    <Row>
                                      <div className="d-flex vertical-align-center horizon-align-center gap-2">
                                        <i className="uil-trash-alt" id={"emlDeleteIco"+emailIdx} style={{color:"red", fontSize:"18px"}}
                                          onClick={() => {
                                            if (email.modFlag === "I") {

                                            } else {
                                              validation.setFieldValue("submitType", "Delete_"+emailIdx);
                                              setConfirmMsg("Are you sure you want to delete this email(s)?");
                                              setConfirmAlert(true);
                                            }
                                          }}></i>
                                        <UncontrolledTooltip placement="top" target={"emlDeleteIco"+emailIdx}>
                                        Delete signature
                                        </UncontrolledTooltip>
                                        {emailIdx+1 === entityDetail.targetGroupEmailList.length ?
                                          <Link to="#" onClick={(e) => addEmail()}>
                                            <i className="uil uil-plus-square font-size-22" id="addEmail"></i>
                                            <UncontrolledTooltip placement="top" target="addEmail">
                                              Add more email
                                            </UncontrolledTooltip>
                                          </Link>
                                        :null}
                                      </div>
                                    </Row>
                                  </td>
                                </tr>
                              )
                          })
                        }
                    </tbody>
                  </Table>
                    :null}
                </div>
              </CardBody>
            </Card>
          </Row>

          {
            loading ?
            <LoadingModal loading={loading}></LoadingModal>
            : null
          }
          {success? 
            <SweetAlert
              title={success}
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(onSetEntityDetailSuccess(""));
              }}
            ></SweetAlert>
          : null}
          {result !== -1 ? (
            <SweetAlert
              onConfirm={() => {
                dispatch(onSetEntityDetailResult(-1));
              }}
            >{result == 6 ? errMsg6 : (result == 7 ? <>{errMsg[result]}<br/>{JSON.stringify(dupeEmailAddresses)}</> : errMsg[result])}
            </SweetAlert>
            ) : null}
          {confirmAlert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText="Confirm"
              cancelBtnText="No, go back"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                validation.handleSubmit(validation.values);
                setConfirmAlert(false);
              }}
              onCancel={() => setConfirmAlert(false)}
            >
              {confirmMsg}
            </SweetAlert>
            ) : null}
        </div>
      </div>
    
    </div>
    </Modal>
  )
}

export default EntityDetail;