import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { 
  GET_FILER_SETTINGS,
  SAVE_FILER_SETTINGS,
  GET_FILER_RANKINGS,
  GET_FILER_RANKING_INFO,
  MULTIPLE_HIDE_FILER,
  MULTIPLE_UNHIDE_FILER,
  MULTIPLE_STAR_FILER,
  MULTIPLE_UNSTAR_FILER,
  DOWN_FILER_EXCEL,
  DOWN_MULTIPLE_FILER_EXCEL,
  DOWN_FILER_PDF,
  DOWN_FILER_PDF_DATA_ONLY,
  DOWN_MULTIPLE_FILER_PDF,
  FILER_SEND_EMAIL,
  FILER_PREVIEW_EMAIL,
  UNLOCK_FILER,
  SET_FILER_LOADING,
  STAR_FILER_TARGET,
  UN_STAR_FILER_TARGET,
  HIDE_FILER_TARGET,
  UN_HIDE_FILER_TARGET,
} from "./actionTypes"
import { 
  getFilerRankingInfo as getFilerRankingInfoAction,
  getFilerSettingsSuccess,
  getFilerRankingInfoSuccess,
  saveFilerSettingsSuccess,
  getFilerRankingsSuccess,
  downFilerExcelSuccess,
  downMultipleFilerExcelSuccess,
  downFilerPdfSuccess,
  downFilerPdfDataOnlySuccess,
  downMultipleFilerPdfSuccess,
  setResult,
  setSuccess,
  filerPreviewEmailSuccess,
  changeUnsubsInfo,
} from "./actions"

import { 
  getUserUnlockInfo,
} from "../unlock/actions"

//Include Both Helper File with needed methods
import {
  getFilerSettings,
  saveFilerSettings,
  getFilerRankings,
  getFilerRankingInfo,
  downFilerExcel,
  downMultipleFilerExcel,
  downFilerPdf,
  downFilerPdfDataOnly,
  downMultipleFilerPdf,
  filerSendEmail,
  filerPreviewEmail,
  unlockFiler,
  multipleStarContacts,
  multipleUnstarContacts,
  multipleHideContacts,
  multipleUnhideContacts,
} from "../../helpers/backend_helper"

function* onGetFilerSettings() {
  try {
    const response = yield call(getFilerSettings);
    yield put(getFilerSettingsSuccess(response));
    
  } catch (error) {
    console.log('onGetFilerSettings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onSaveFilerSettings({ payload : data }) {
  try {
    const response = yield call(saveFilerSettings, data);
    yield put(saveFilerSettingsSuccess(response));
    
  } catch (error) {
    console.log('onSaveFilerSettings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetFilerRankings({ payload : data }) {
  try {
    const response = yield call(getFilerRankings, data);
    yield put(getFilerRankingsSuccess(response));
    
  } catch (error) {
    console.log('onGetFilerRankings error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onGetFilerRankingInfo({ payload : data }) {
  try {
    const response = yield call(getFilerRankingInfo, data);
    yield put(getFilerRankingInfoSuccess(response));
    
  } catch (error) {
    console.log('onGetFilerRankingInfo error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleHideFiler({ payload : data }) {
  try {
    const response = yield call(multipleHideContacts, data.chkList);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList: data.chkList,
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate,
        frType: data.filter.frType, 
      }));
    }
  } catch (error) {
    console.log('onMultipleHideFiler error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnhideFiler({ payload : data }) {
  try {
    const response = yield call(multipleUnhideContacts, data.chkList);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList: data.chkList,
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate, 
        frType: data.filter.frType,
      }));
    }
  } catch (error) {
    console.log('onMultipleUnhideFiler error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleStarFiler({ payload : data }) {
  try {
    const response = yield call(multipleStarContacts, data.chkList);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList: data.chkList,
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate, 
        frType: data.filter.frType,
      }));
    }

  } catch (error) {
    console.log('onMultipleStarFiler error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onMultipleUnstarFiler({ payload : data }) {
  try {
    const response = yield call(multipleUnstarContacts, data.chkList);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList: data.chkList,
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate,
        frType: data.filter.frType, 
      }));
    }

  } catch (error) {
    console.log('onMultipleUnstarFiler error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownFilerExcel({ payload : data}) {
  try {
    yield call(downFilerExcel, data);
    yield put(downFilerExcelSuccess());

  } catch (error) {
    console.log('onDownFilerExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownMultipleFilerExcel({ payload : data }) {
  try {
    yield call(downMultipleFilerExcel, data);
    yield put(downMultipleFilerExcelSuccess());

  } catch (error) {
    console.log('onDownMultipleFilerExcel error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownFilerPdf({ payload : data}) {
  try {
    yield call(downFilerPdf, data);
    yield put(downFilerPdfSuccess());

  } catch (error) {
    console.log('onDownFilerPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownFilerPdfDataOnly({ payload : data}) {
  try {
    yield call(downFilerPdfDataOnly, data);
    yield put(downFilerPdfDataOnlySuccess());

  } catch (error) {
    console.log('onDownFilerPdfDataOnly error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onDownMultipleFilerPdf({ payload : data }) {
  try {
    yield call(downMultipleFilerPdf, data);
    yield put(downMultipleFilerPdfSuccess());

  } catch (error) {
    console.log('onDownMultipleFilerPdf error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onFilerSendEmail({ payload : data }) {
  try {
    const response = yield call(filerSendEmail, data.chkList);
    if(response.error != undefined && response.error >-1){
      yield put(setResult(response.error));
      if (response.error == 5) {
        yield put(changeUnsubsInfo(response.result));
      }
    }else{
      yield put(setSuccess("Success"));

      yield put(getFilerRankingInfoAction({
        rankingList:data.chkList.rankingList,
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate, 
        frType: data.filter.frType,
      }));
    }

  } catch (error) {
    console.log('onFilerSendEmail error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onFilerPreviewEmail({ payload : data }) {
  try {
    const response = yield call(filerPreviewEmail, data);
    if(response.error != undefined && response.error >-1){
      yield put(setResult(response.error));
    }else{
      yield put(filerPreviewEmailSuccess(response));
    }

  } catch (error) {
    console.log('onFilerPreviewEmail error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onUnlockFiler({ payload : data }) {
  try {
    const response = yield call(unlockFiler, data.rankingList);
    if(response.error !== undefined && response.error >-1){
      yield put(getUserUnlockInfo());
      yield put(setResult(response.error));
      // yield put(getFilerRankingsAction(data.filter));
    }else{
      if (response.result === 'Success') {
        yield put(getUserUnlockInfo());
        // yield put(setMadridSuccess("Success"));
        // yield put(getFilerRankingsAction(data.filter));
      } else {
        yield put(getUserUnlockInfo());
        yield put(setResult(response.result));
        // yield put(getFilerRankingsAction(data.filter));
      }
    }

    yield put(getFilerRankingInfoAction({
      rankingList:data.chkList.rankingList,
      crType: data.filter.crType, 
      crCdList: data.filter.crCdList, 
      jurType: data.filter.jurType, 
      jrCdList: data.filter.jrCdList, 
      inOutType: data.filter.inOutType, 
      rdoRiHi:data.filter.rdoRiHi, 
      entryCnt:data.filter.entryCnt, 
      startDate:data.filter.startDate, 
      endDate:data.filter.endDate, 
      frType: data.filter.frType,
    }));

  } catch (error) {
    console.log('onUnlockFiler error >> ' + error);
    //yield put(apiError(error))
  }
}

function* onStarFilerTarget({ payload : data }) {
  try {
    const response = yield call(multipleStarContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList:[{targetGroupNo:data.targetGroupNo}],
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate, 
        frType: data.filter.frType,
      }));
    }

  } catch (error) {
    console.log('onStarTarget error >> ' + error);
  }
}

function* onUnStarFilerTarget({ payload : data }) {
  try {
    const response = yield call(multipleUnstarContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList:[{targetGroupNo:data.targetGroupNo}],
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate, 
        frType: data.filter.frType,
      }));
    }

  } catch (error) {
    console.log('onUnstarTarget error >> ' + error);
  }
}

function* onHideFilerTarget({ payload : data }) {
  try {
    const response = yield call(multipleHideContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList:[{targetGroupNo:data.targetGroupNo}],
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate,
        frType: data.filter.frType, 
      }));
    }

  } catch (error) {
    console.log('onHideTarget error >> ' + error);
  }
}

function* onUnHideFilerTarget({ payload : data }) {
  try {
    const response = yield call(multipleUnhideContacts, [{targetGroupNo : data.targetGroupNo}]);
    //새로고침
    if (response.result === 'Success') {
      // yield put(getFilerRankingsAction(data.filter));
      yield put(getFilerRankingInfoAction({
        rankingList:[{targetGroupNo:data.targetGroupNo}],
        crType: data.filter.crType, 
        crCdList: data.filter.crCdList, 
        jurType: data.filter.jurType, 
        jrCdList: data.filter.jrCdList, 
        inOutType: data.filter.inOutType, 
        rdoRiHi:data.filter.rdoRiHi, 
        entryCnt:data.filter.entryCnt, 
        startDate:data.filter.startDate, 
        endDate:data.filter.endDate, 
        frType: data.filter.frType,
      }));
    }

  } catch (error) {
    console.log('onUnhideTarget error >> ' + error);
  }
}

function* FilerRankingsSaga() {
  yield takeLatest(GET_FILER_SETTINGS, onGetFilerSettings);
  yield takeLatest(SAVE_FILER_SETTINGS, onSaveFilerSettings);
  yield takeLatest(GET_FILER_RANKINGS, onGetFilerRankings);
  yield takeLatest(GET_FILER_RANKING_INFO, onGetFilerRankingInfo);
  yield takeLatest(MULTIPLE_HIDE_FILER, onMultipleHideFiler);
  yield takeLatest(MULTIPLE_UNHIDE_FILER, onMultipleUnhideFiler);
  yield takeLatest(MULTIPLE_STAR_FILER, onMultipleStarFiler);
  yield takeLatest(MULTIPLE_UNSTAR_FILER, onMultipleUnstarFiler);
  yield takeLatest(DOWN_FILER_EXCEL, onDownFilerExcel);
  yield takeLatest(DOWN_MULTIPLE_FILER_EXCEL, onDownMultipleFilerExcel);
  yield takeLatest(DOWN_FILER_PDF, onDownFilerPdf);
  yield takeLatest(DOWN_FILER_PDF_DATA_ONLY, onDownFilerPdfDataOnly);
  yield takeLatest(DOWN_MULTIPLE_FILER_PDF, onDownMultipleFilerPdf);
  yield takeLatest(FILER_SEND_EMAIL, onFilerSendEmail);
  yield takeLatest(FILER_PREVIEW_EMAIL, onFilerPreviewEmail);
  yield takeLatest(UNLOCK_FILER, onUnlockFiler);
  yield takeLatest(STAR_FILER_TARGET, onStarFilerTarget);
  yield takeLatest(UN_STAR_FILER_TARGET, onUnStarFilerTarget);
  yield takeLatest(HIDE_FILER_TARGET, onHideFilerTarget);
  yield takeLatest(UN_HIDE_FILER_TARGET, onUnHideFilerTarget);
}

export default FilerRankingsSaga

