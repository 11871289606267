export const GET_ALL_JURISDICTIONS_LIST = "GET_ALL_JURISDICTIONS_LIST"
export const GET_ALL_JURISDICTIONS_LIST_SUCCESS = "GET_ALL_JURISDICTIONS_LIST_SUCCESS"
export const GET_JURISDICTIONS_LIST = "GET_JURISDICTIONS_LIST"
export const GET_JURISDICTIONS_LIST_SUCCESS = "GET_JURISDICTIONS_LIST_SUCCESS"
export const CHANGE_JURISDICTIONS_LIST = "CHANGE_JURISDICTIONS_LIST"
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST"
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS"
export const GET_ALL_COUNTRY_LIST = "GET_ALL_COUNTRY_LIST"
export const GET_ALL_COUNTRY_LIST_SUCCESS = "GET_ALL_COUNTRY_LIST_SUCCESS"
export const CHANGE_COUNTRY_LIST = "CHANGE_COUNTRY_LIST"
export const GET_SEARCH_NAME_LIST = "GET_SEARCH_NAME_LIST"
export const GET_SEARCH_NAME_LIST_SUCCESS = "GET_SEARCH_NAME_LIST_SUCCESS"
export const GET_SEARCH_NAME_LIST2 = "GET_SEARCH_NAME_LIST2"
export const GET_SEARCH_TARGET_GROUP_LIST = "GET_SEARCH_TARGET_GROUP_LIST"
export const GET_SEARCH_TARGET_GROUP_LIST_SUCCESS = "GET_SEARCH_TARGET_GROUP_LIST_SUCCESS"
export const GET_HAGUE_SEARCH_TARGET_GROUP_LIST = "GET_HAGUE_SEARCH_TARGET_GROUP_LIST"
export const GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS = "GET_HAGUE_SEARCH_TARGET_GROUP_LIST_SUCCESS"
export const CHANGE_SEARCH_NAME_LIST = "CHANGE_SEARCH_NAME_LIST"
export const CHANGE_SEARCH_NAME_SELECTED_LIST = "CHANGE_SEARCH_NAME_SELECTED_LIST"
export const CLEAR_SEARCH_NAME_LIST = "CLEAR_SEARCH_NAME_LIST"
export const GET_GENERALSTATISTICS_LIST = "GET_GENERALSTATISTICS_LIST"
export const GET_GENERALSTATISTICS_LIST_SUCCESS = "GET_GENERALSTATISTICS_LIST_SUCCESS"
export const DOWN_STATISTICS_EXCEL = "DOWN_STATISTICS_EXCEL"
export const DOWN_STATISTICS_EXCEL_SUCCESS = "DOWN_STATISTICS_EXCEL_SUCCESS"
export const DOWN_STATISTICS_PDF = "DOWN_STATISTICS_PDF"
export const DOWN_STATISTICS_PDF_SUCCESS = "DOWN_STATISTICS_PDF_SUCCESS"
export const CLEAR_GENERALSTATISTICS_LIST = "CLEAR_GENERALSTATISTICS_LIST"
export const SET_SEARCHNAME_LIST = "SET_SEARCHNAME_LIST"
export const SET_IS_WAR_SEARCH = "SET_IS_WAR_SEARCH"